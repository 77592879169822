import React, { Component } from 'react';
import DataTable from '../TableTemplates/DataTable';
import { getDataSequence, getDataSplits } from '../../util/ApiCalls';

/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

class DataSplitTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSplits: [],
    };
  }

  async componentDidMount() {
    await this.updateInfo();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.updateInfo();
    }
  }

  async updateInfo() {
    async function expandDataSequences(dataSplit) {
      const dataSequences = await Promise.all(dataSplit.dataSequences
        .map((seq) => getDataSequence(seq)));
      return { ...dataSplit, dataSequences };
    }
    try {
      let dataSplits = await getDataSplits();
      dataSplits = await Promise.all(dataSplits.map(expandDataSequences));
      dataSplits = dataSplits.map((split) => ({
        key: split._id,
        name: split.name,
        type: split.type,
        ratio: split.dataSequences[0].split_params && split.dataSequences[0].split_params.train_percentage ? split.dataSequences[0].split_params.train_percentage : '',
        leaves: split.dataSequences.map((seq) => seq.leaf_id),
        createdAt: new Date(split.createdAt).toLocaleString('de-DE'),
        date: split.createdAt,
      }));
      this.setState({ dataSplits });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { dataSplits } = this.state;
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        fixed: 'left',
      },
      {
        title: 'Type',
        dataIndex: 'type',
        filters: [
          {
            text: 'Percentage',
            value: 'Percentage',
          },
          {
            text: 'Fixed',
            value: 'Fixed',
          },
        ],
        onFilter: (value, record) => record.type.indexOf(value) === 0,
      },
      {
        title: 'Training Data Ratio',
        dataIndex: 'ratio',
        sorter: (a, b) => a.ratio - b.ratio,
      },
      {
        title: 'Leaves',
        dataIndex: 'leaves',
        render: (entry) => (entry && entry.length > 0
          ? entry.reduce((item, accumulator) => `${accumulator}, ${item}`)
          : '-'),
      },
      {
        title: 'Creation Date',
        dataIndex: 'createdAt',
        sorter: (a, b) => new Date(a.date) - new Date(b.date),
        defaultSortOrder: 'descend',
      },
    ];

    return (
      <div>
        <DataTable
          columns={columns}
          dataSource={dataSplits}
        />
      </div>
    );
  }
}

export default DataSplitTable;

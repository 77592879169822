import React from 'react';
import ReactJson from 'react-json-view';
import { Card } from 'antd';
import PropTypes from 'prop-types';

/* eslint-disable react/forbid-prop-types */

export default function DataSchemaView(props) {
  const { selectedSchema } = props;
  if (selectedSchema) {
    return (
      <Card>
        <ReactJson displayDataTypes={false} src={selectedSchema.definition} />
      </Card>
    );
  }
  return (
    <Card>
      <ReactJson src={{}} />
    </Card>
  );
}

DataSchemaView.defaultProps = {
  selectedSchema: {},
};

DataSchemaView.propTypes = {
  selectedSchema: PropTypes.shape({
    definition: PropTypes.object,
  }),
};

/* eslint-disable no-underscore-dangle */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import TagSection from './TagSection';

const trainTestTags = [
  {
    name: 'Train',
    color: '#69c0ff',
  }, {
    name: 'Test',
    color: '#ff9c6e',
  },
];
const validationTag = [
  {
    name: 'Val',
    color: '#95de64',
  },
];

const getPercentage = (containerWidth, distanceMoved) => (distanceMoved / containerWidth) * 100;

const limitWithinRange = (value, min, max) => Math.min(Math.max(value, min), max);

const nearestN = (N, number) => Math.ceil(number / N) * N;

export default function TagSlider(props) {
  const { validationSplit, onChange } = props;
  const [tags, setTags] = useState(trainTestTags);
  const [widths, setWidths] = useState([70, 30]);
  const TagSliderRef = useRef(null);

  useEffect(() => {
    setTags(!validationSplit
      ? trainTestTags : trainTestTags.concat(validationTag));
    setWidths(!validationSplit ? [70, 30] : [60, 30, 10]);
  }, [validationSplit]);

  useEffect(() => {
    onChange(widths);
  }, [widths, onChange]);

  const handleSliderSelect = (index, e) => {
    document.body.style.cursor = 'ew-resize';

    const startDragX = e.pageX;
    const sliderWidth = TagSliderRef.current.offsetWidth;

    const resize = (r) => {
      r.preventDefault();
      const endDragX = r.touches ? r.touches[0].pageX : r.pageX;
      const distanceMoved = endDragX - startDragX;
      const maxPercent = widths[index] + widths[index + 1];
      const percentageMoved = nearestN(1, getPercentage(sliderWidth, distanceMoved));

      const _widths = widths.slice();

      const prevPercentage = _widths[index];
      const newPercentage = prevPercentage + percentageMoved;
      const currentSectionWidth = limitWithinRange(newPercentage, 0, maxPercent);
      _widths[index] = currentSectionWidth;
      const nextSectionIndex = index + 1;
      const nextSectionNewPercentage = _widths[nextSectionIndex] - percentageMoved;

      const nextSectionWidth = limitWithinRange(nextSectionNewPercentage, 0, maxPercent);
      _widths[nextSectionIndex] = nextSectionWidth;

      setWidths(_widths);
    };
    window.addEventListener('pointermove', resize);
    window.addEventListener('touchmove', resize);

    const handleEventUp = () => {
      document.body.style.cursor = 'initial';
      window.removeEventListener('pointermove', resize);
      window.removeEventListener('touchmove', resize);
    };
    window.addEventListener('touchend', handleEventUp);
    window.addEventListener('pointerup', handleEventUp);
  };

  return (
    <div>
      <div
        ref={TagSliderRef}
        style={{
          width: '96%',
          float: 'right',
          display: 'flex',
          backgroundColor: 'transparent',
        }}
      >
        {tags.map((tag, index) => (
          <TagSection
            width={widths[index]}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            name={tag.name}
            onSliderSelect={(e) => handleSliderSelect(index, e)}
            color={tag.color}
          />
        ))}
      </div>
    </div>
  );
}

TagSlider.propTypes = {
  validationSplit: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
};
TagSlider.defaultProps = {
  onChange: () => {},
};

import React, { Component } from 'react';
import { Card } from 'antd';
import NotificationItem from './NotificationItem/NotificationItem';
import { getStatusMessage } from '../../util/ApiCalls';
import '../../App.css';

class NotificationMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusLogs: [],
    };
  }

  componentDidMount() {
    getStatusMessage()
      .then((logs) => {
        this.setState({ statusLogs: logs });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const { statusLogs } = this.state;
    const style = {
      fontFamily: '"Courier New", Courier, monospace',
      background: 'white',
      color: 'black',
    };
    return (
      <Card title="Notifications" className="scrollable-small">
        <Card style={style} bordered={false}>
          { !Array.isArray(statusLogs)
            ? ''
            : statusLogs
              .map((log) => (
                <NotificationItem
                  key={log._id} // eslint-disable-line no-underscore-dangle
                  leaf={log.id}
                  status={log.status}
                  time={new Date(log.createdAt).toLocaleString('de-DE')}
                />
              ))}
        </Card>
      </Card>
    );
  }
}

export default NotificationMenu;

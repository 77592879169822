/* eslint-disable camelcase */
import cognitoUtils from './UtilsCognito';
/* eslint no-underscore-dangle: ["error", { "allow": ["_id", "_env_"] }] */
const baseURL = window._env_.REACT_APP_CNODE_API_URL;

async function fetchAPI(endpoint, method, data) {
  let body = null;
  const cognitoUser = cognitoUtils.createCognitoUser();
  let session = null;
  if (cognitoUser) {
    session = await cognitoUser.getSession((err, result) => {
      if (err || !result) {
        return null;
      }
      return result;
    });
  }
  const options = {
    method,
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      mode: 'cors',
      'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
      Authorization: `Bearer ${session.accessToken.jwtToken}`,
    },
  };
  body = await fetch(endpoint, options).then((response) => {
    if (response.status === 422) {
      throw new Error(422);
    } else if (!response.ok) {
      throw new Error(response.statusText);
    }
    if (response.status === 200) {
      return response.json();
    }
    return null;
  });
  return body;
}

export function postRegister(id, name, coordinates, address, description) {
  const body = {
    name,
    coordinates,
    address,
    description,
  };
  return fetchAPI(`${baseURL}/leaf/${id}/register`, 'POST', body);
}
export function postDeregister(id) {
  return fetchAPI(`${baseURL}/leaf/${id}/deregister`, 'POST', {});
}
export function getInfo(id) {
  return fetchAPI(`${baseURL}/leaf/${id}/info`);
}
export function getAllInfo() {
  return fetchAPI(`${baseURL}/leaf/info`);
}
export function getStatus(id) {
  return fetchAPI(`${baseURL}/leaf/${id}/status`);
}
export function getAllStatus() {
  return fetchAPI(`${baseURL}/leaf/status`);
}
export function getStatusLog(id) {
  return fetchAPI(`${baseURL}/leaf/${id}/statuslog`);
}
export function getStatusMessage() {
  return fetchAPI(`${baseURL}/leaf/statusmessage`);
}
export function getRuns(types) {
  const query = types
    ? types.map((type) => `type=${type}`)
      .reduce((parameter, accumulator) => `${accumulator}&${parameter}`)
    : '';
  return fetchAPI(`${baseURL}/run?${query}`);
}
export function getRun(id) {
  return fetchAPI(`${baseURL}/run/${id}`);
}
export async function getRunPerformance(runId) {
  return fetchAPI(`${baseURL}/run/${runId}/participant`);
}
export function getIterations(runId, participantId) {
  return fetchAPI(`${baseURL}/run/${runId}/participant/${participantId}/iteration`);
}
export function getDataSchemas() {
  return fetchAPI(`${baseURL}/dataschema/`);
}
export function getDataSchema(schemaId) {
  return fetchAPI(`${baseURL}/dataschema/${schemaId}`);
}
export function deleteDataSchema(schemaId) {
  return fetchAPI(`${baseURL}/dataschema/${schemaId}`, 'DELETE');
}
export function postDataSchema(definition, dataType, name, description) {
  return fetchAPI(`${baseURL}/dataschema/`, 'POST', {
    definition,
    name,
    description,
    data_type: dataType,
  });
}
export function getProcessor(processorId) {
  return fetchAPI(`${baseURL}/processordefinition/${processorId}`);
}
export function postProcessor(description, script) {
  return fetchAPI(`${baseURL}/processordefinition/`, 'POST', {
    description,
    script,
  });
}
export function getTasks() {
  return fetchAPI(`${baseURL}/task`);
}
export function getTask(taskId) {
  return fetchAPI(`${baseURL}/task/${taskId}`);
}
export function delTask(taskId) {
  return fetchAPI(`${baseURL}/task/${taskId}`, 'DELETE');
}
export async function postTask(
  dataschemaId, modelDefinitionId, processordefinitionId, target, metrics, description, name, type,
) {
  const task = {
    model_definition_id: modelDefinitionId,
    processor_definition_id: processordefinitionId,
    data_schema_id: dataschemaId,
    metrics,
    target,
    name,
    description,
    type,
  };
  return fetchAPI(`${baseURL}/task/`, 'POST', task);
}
export function getDataSequences() {
  return fetchAPI(`${baseURL}/datasequence/`);
}
export function getDataSequence(dataSequenceId) {
  return fetchAPI(`${baseURL}/datasequence/${dataSequenceId}`);
}
export async function postDataSequence(leafId, splitParams, validationSplit) {
  let split_params = {
    train_percentage: splitParams[0],
    test_percentage: splitParams[1],
  };
  if (validationSplit) {
    split_params = { holdout_percentage: splitParams[2], ...split_params };
  }
  const dataseq = {
    leaf_id: leafId,
    split_params,
  };
  return fetchAPI(`${baseURL}/datasequence/`, 'POST', dataseq);
}
export async function getDataSplits() {
  return fetchAPI(`${baseURL}/datasplit/`, 'GET');
}
export async function getDataSplit(dataSplitId) {
  return fetchAPI(`${baseURL}/datasplit/${dataSplitId}`, 'GET');
}
export async function postDataSplit(datasplit) {
  return fetchAPI(`${baseURL}/datasplit/`, 'POST', datasplit);
}
export async function postRun(runtype, iterations, epochs, optimizertype, learningrate, l2normclip,
  noisemultiplier, microbatches, batchsize, dataSplitId, leaves, taskId, primarymetric,
  losstype, name, modeltype) {
  let params = {};
  if (modeltype === 'keras') {
    let optimizer = {
      type: optimizertype,
      learning_rate: learningrate,
    };

    if (optimizertype === 'dpadam') {
      optimizer = {
        ...optimizer,
        l2_norm_clip: l2normclip,
        noise_multiplier: noisemultiplier,
        microbatches,
      };
    }
    params = {
      epochs,
      optimizer,
      loss: {
        type: losstype,
      },
      batch_size: batchsize,
    };
  }
  const run = {
    name,
    iterations: runtype === 'train' ? 0 : iterations,
    type: runtype,
    params,
    primary_metric: primarymetric,
    leaves,
    data_split_id: dataSplitId,
    task_id: taskId,
  };
  return fetchAPI(`${baseURL}/run/`, 'POST', run);
}
export function cancelRun(runId) {
  return fetchAPI(`${baseURL}/run/${runId}/stop/`, 'POST');
}
export function resumeRun(runId) {
  return fetchAPI(`${baseURL}/run/${runId}/resume/`, 'POST');
}
export function deleteRun(runId) {
  return fetchAPI(`${baseURL}/run/${runId}`, 'DELETE');
}
export function deleteModel(modelId) {
  return fetchAPI(`${baseURL}/model/${modelId}`, 'DELETE');
}
export function postModelDefinition(body) {
  return fetchAPI(`${baseURL}/modeldefinition/`, 'POST', JSON.parse(body));
}
export function getModel(id) {
  return fetchAPI(`${baseURL}/model/${id}`);
}
export function getModelDefinition(id) {
  return fetchAPI(`${baseURL}/modeldefinition/${id}`);
}
export function getModelsByTaskId(taskId) {
  return fetchAPI(`${baseURL}/model/task/${taskId}`);
}
export async function deployJob(modelId, taskId, leafId) {
  const job = {
    type: 'deploy',
    task_id: taskId,
    model_id: modelId,
  };
  return fetchAPI(`${baseURL}/job/leaf/${leafId}`, 'POST', job);
}
export function getEvaluations(dataSequenceId) {
  return fetchAPI(`${baseURL}/evaluation?data_sequence_id=${dataSequenceId}`);
}

export function getDatasplitsForLeaf(leafId) {
  return fetchAPI(`${baseURL}/leaf/${leafId}/datasplit`);
}

export async function postEvaluationJob(name, leaves, dataSplitId, modelId,
  taskId) {
  const evaluationJob = {
    name,
    type: 'evaluate',
    leaves,
    task_id: taskId,
    data_split_id: dataSplitId,
    model_id: modelId,
  };
  return fetchAPI(`${baseURL}/run/`, 'POST', evaluationJob);
}

export function getComparison(runIds, aggregations, metrics) {
  const runQueryStrings = runIds.map((run) => `run=${run}`);
  const aggregationQueryStrings = aggregations.map((aggregation) => `aggregation=${aggregation}`);
  const metricsQueryStrings = metrics.map((metric) => `metric=${metric}`);
  const query = [...runQueryStrings, ...aggregationQueryStrings, ...metricsQueryStrings]
    .reduce((parameter, accumulator) => `${accumulator}&${parameter}`);
  return fetchAPI(`${baseURL}/run/comparison?${query}`);
}

/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Tooltip,
  message,
} from 'antd';
import LeafTable from '../TableTemplates/LeafTable';
import { getDataSequence } from '../../util/ApiCalls';

export default function SelectLeavesItem(props) {
  const {
    form, title, ruleMessage, newDataSplit,
  } = props;
  const checkMissingSequences = async () => {
    try {
      const leaves = form.getFieldValue('leaves');
      const datasplits = form.getFieldValue('datasplits');
      if (leaves !== undefined && datasplits !== undefined) {
        const expandedSequences = await Promise.all(datasplits[0].sequences
          .map((seq) => getDataSequence(seq)));
        const participants = leaves.map((leaf) => leaf.id);
        try {
          participants.map((participant) => expandedSequences
            .find((e) => e.leaf_id === participant)._id);
        } catch (e) {
          throw new Error('MissingDataSequence');
        }
      }
    } catch (e) {
      if (e.message === 'MissingDataSequence') {
        message.warning('Select a split which is defined for all participating leafs!');
      } else {
        message.warning(e.message);
      }
    }
  };

  return (
    <Tooltip title={title}>
      <Form.Item
        label="Participants"
        name="leaves"
        rules={[
          {
            required: true,
            message: ruleMessage,
          },
        ]}
        onChange={newDataSplit
          ? null
          : () => { checkMissingSequences(); }}
      >
        <LeafTable
          selectedRow={form.getFieldValue('leaves') ? form.getFieldValue('leaves').map((obj) => obj.key) : []}
        />
      </Form.Item>
    </Tooltip>
  );
}

SelectLeavesItem.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  title: PropTypes.string,
  ruleMessage: PropTypes.string.isRequired,
  newDataSplit: PropTypes.bool,
};

SelectLeavesItem.defaultProps = {
  title: null,
  newDataSplit: false,
};

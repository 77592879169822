import React from 'react';
import PropTypes from 'prop-types';
import { LineGraph } from '../LineGraph';

const BiaxialLineGraphRight = ({
  data, yAxisName, height, legendAnchor, legendTranslateX, legendTranslateY,
  colors, axisColor, enableGridY,
}) => {
  const graphColors = [];
  for (let i = 0; i < data.length; i += 1) {
    graphColors.push(colors[i]);
  }

  let dataEmpty = true;
  if (data.length) {
    if (data[0].data.length) dataEmpty = false;
  }

  return (
    <div style={{
      height,
      position: 'absolute',
      width: '100%',
      zIndex: 2,
    }}
    >
      <LineGraph
        data={data}
        yScale={{
          type: 'linear', min: 0, max: dataEmpty ? 1 : 'auto', stacked: false, reverse: false,
        }}
        axisColor={axisColor}
        axisRight={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: yAxisName,
          legendOffset: 53,
          legendPosition: 'middle',
        }}
        colors={graphColors}
        yAxisName={null}
        enableGridY={enableGridY}
        legends={[
          {
            anchor: legendAnchor,
            direction: 'column',
            justify: false,
            translateX: legendTranslateX,
            translateY: legendTranslateY,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 15,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
};

export default BiaxialLineGraphRight;

BiaxialLineGraphRight.propTypes = {
  data: PropTypes.PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.number,
          y: PropTypes.number,
        }),
      ),
    }),
  ).isRequired,
  yAxisName: PropTypes.string.isRequired,
  colors: PropTypes.arrayOf(PropTypes.string),
  height: PropTypes.string,
  legendAnchor: PropTypes.string,
  legendTranslateX: PropTypes.number,
  legendTranslateY: PropTypes.number,
  axisColor: PropTypes.string,
  enableGridY: PropTypes.bool,
};
BiaxialLineGraphRight.defaultProps = {
  colors: { scheme: 'pink_yellowGreen' },
  height: '560px',
  legendAnchor: 'right',
  legendTranslateX: 100,
  legendTranslateY: 0,
  axisColor: 'not specified',
  enableGridY: false,
};

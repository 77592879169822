import React from 'react';
import PropTypes from 'prop-types';
import 'codemirror/mode/python/python';
import { Typography, Descriptions } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { UnControlled as CodeMirror } from 'react-codemirror2';

const { Text } = Typography;

export default function MetricHints({
  link, description, y_pred_sample: yPred, y_true_sample: yTrue, explanation,
}) {
  return (
    <Descriptions
      bordered
      column={1}
    >
      <Descriptions.Item label="Name">
        <a href={link}>
          <QuestionCircleOutlined />
          {` ${description}`}
        </a>
      </Descriptions.Item>
      <Descriptions.Item label="Example">
        <CodeMirror
          value={`y_true = ${yTrue} \ny_pred = ${yPred}`}
          options={{
            mode: 'python',
            theme: 'default',
            lineNumbers: true,
            readOnly: 'nocursor',
          }}
        />
      </Descriptions.Item>
      <Descriptions.Item label="Explanation">
        <Text>
          {explanation}
        </Text>
      </Descriptions.Item>
    </Descriptions>
  );
}

MetricHints.propTypes = {
  link: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  y_true_sample: PropTypes.string.isRequired,
  y_pred_sample: PropTypes.string.isRequired,
  explanation: PropTypes.string.isRequired,
};

/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Steps,
  Button,
  Modal,
  message,
} from 'antd';
import './NewTaskModal.css';
import ProcessorUpload from './ProcessorUpload/ProcessorUpload';
import ModelUpload from './ModelUpload/ModelUpload';
import MetricSelection from './MetricSelection/MetricSelection';
import DataSchemaSelection from './DataSchemaSelection/DataSchemaSelection';
import DataSchemaSelectionContextProvider from './DataSchemaSelection/DataSchemaSelectionContext';
import Review from './Review/Review';
import NewTaskDetails from './NewTaskDetails/NewTaskDetails';
import { postModelDefinition, postProcessor, postTask } from '../../../util/ApiCalls';

const { Step } = Steps;

class NewTaskModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      current: 0,
      formsvalidated: false,
      dataschema: { dataSchema: { definition: {} }, target: '' },
      model: {
        description: '',
        definition: 'from tensorflow.keras.layers import Dense\nfrom tensorflow.keras import Sequential\n# import all needed keras elements\n\ndef get_model():\n\tmodel = Sequential()\n\t# define your model here.\n\n\treturn model',
        type: 'keras',
      },
      processordefinition: {
        description: '',
        script: 'import numpy as np\n# import any of the following packages if needed:\n# numpy, pandas, scikit-learn, scipy, sklearn\n\ndef train_preprocess(df):\n\t# ...\n\treturn X, y\n\ndef test_preprocess(df):\n\t# ...\n\treturn X, y\n\ndef deployment_preprocess(X):\n\t# ...\n\treturn X',
      },
      firstSwitch: true,
      secondSwitch: false,
      thirdSwitch: false,
      customizedProcessing: false,
      validProcessing: false,
      invalidProcessingMessage: '',
      name: '',
      metrics: [],
      description: '',
      type: '',
    };
    this.basestate = this.state;
  }

  disableSaveAll() {
    this.setState({
      loading: true,
    });
  }

  enableSaveAll() {
    this.setState({
      loading: false,
    });
  }

  next() {
    const { current } = this.state;
    const next = current + 1;
    this.setState({ current: next });
  }

  prev() {
    const { current } = this.state;
    const prev = current - 1;
    this.setState({ current: prev });
  }

  validated(v) {
    this.setState({ formsvalidated: v });
  }

  async saveAll() {
    const { refresh, onOk } = this.props;
    this.disableSaveAll();
    try {
      const {
        model,
        processordefinition,
        dataschema: { dataSchema, target },
        metrics,
        name,
        description,
        type,
      } = this.state;
      // save model
      const resmodel = await postModelDefinition(JSON.stringify(model));
      // save processordefinition
      const resprocessordefinition = await postProcessor(
        processordefinition.description,
        processordefinition.script,
      );
      // save task
      await postTask(
        dataSchema._id,
        resmodel,
        resprocessordefinition,
        target,
        metrics.map((metric) => ({ type: metric.type })),
        description,
        name,
        type,
      );
      refresh();
      onOk();
      this.setState(this.basestate);
      message.success('Processing complete! Task created.');
    } catch (e) {
      if (e.message === '422') {
        this.enableSaveAll();
        message.error('Model definition not processable, please adjust.');
      } else {
        onOk();
        this.setState(this.basestate);
        message.error('Failed to create Task.');
      }
    }
  }

  render() {
    const { visible, onCancel } = this.props;
    const {
      loading,
      current,
      model,
      processordefinition,
      firstSwitch,
      secondSwitch,
      thirdSwitch,
      customizedProcessing,
      validProcessing,
      invalidProcessingMessage,
      dataschema,
      dataschema: { dataSchema, target },
      name,
      type,
      description,
      metrics,
      formsvalidated,
    } = this.state;
    const steps = [
      {
        title: 'Task',
        content: <NewTaskDetails
          onChange={(e) => {
            this.setState({ name: e.name, type: e.type, description: e.description });
            if (e.type && e.type !== type) {
              this.setState({
                metrics: [],
              });
            }
          }}
          name={name}
          type={type}
          description={description}
          validated={(v) => this.validated(v)}
        />,
      },
      {
        title: 'Dataschema',
        content: (
          <DataSchemaSelectionContextProvider dataSchema={dataSchema}>
            <DataSchemaSelection
              onChange={(e) => {
                this.setState({ dataschema: e });
              }}
              dataSchema={dataSchema}
              target={target}
              validated={(v) => this.validated(v)}
            />
          </DataSchemaSelectionContextProvider>
        ),
      },
      {
        title: 'Model',
        content: <ModelUpload
          onChange={(e) => {
            this.setState({ model: e.model });
          }}
          model={model}
          type={type}
          validated={(v) => this.validated(v)}
        />,
      },
      {
        title: 'Metrics',
        content: <MetricSelection
          onChange={(e) => {
            this.setState({ metrics: e });
          }}
          taskType={type}
          metrics={metrics}
          validated={(v) => this.validated(v)}
        />,
      },
      {
        title: 'Processing',
        content: <ProcessorUpload
          onChange={(e) => {
            this.setState({
              processordefinition: e.processordefinition,
              firstSwitch: e.firstSwitch,
              secondSwitch: e.secondSwitch,
              thirdSwitch: e.thirdSwitch,
              customizedProcessing: e.customizedProcessing,
              validProcessing: e.validProcessing,
              invalidProcessingMessage: e.invalidProcessingMessage,
            });
          }}
          processordefinition={processordefinition}
          firstSwitch={firstSwitch}
          secondSwitch={secondSwitch}
          thirdSwitch={thirdSwitch}
          customizedProcessing={customizedProcessing}
          validProcessing={validProcessing}
          invalidProcessingMessage={invalidProcessingMessage}
          validated={(v) => this.validated(v)}
        />,
      },
      {
        title: 'Review',
        content: <Review
          processordefinition={processordefinition}
          model={model}
          dataschema={dataschema}
          name={name}
          description={description}
          metrics={metrics}
          type={type}
        />,
      },
    ];
    return (
      <Modal
        visible={visible}
        onCancel={onCancel}
        width={1040}
        footer={[
          current > 0 && (
            <Button key="previous" style={{ margin: 8 }} onClick={() => this.prev()}>
              Previous
            </Button>
          ),
          current < steps.length - 1 && (
            <Button key="next" type="primary" onClick={() => this.next()} disabled={!formsvalidated}>
              Next
            </Button>
          ),
          current === steps.length - 1 && (
            <Button
              key="done"
              type="primary"
              loading={loading}
              onClick={() => {
                if (formsvalidated) {
                  this.saveAll();
                }
              }}
            >
              Done
            </Button>
          ),
        ]}
      >
        <Steps current={current}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="steps-content">
          {steps[current].content}
        </div>
      </Modal>
    );
  }
}
export default NewTaskModal;

NewTaskModal.propTypes = {
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  refresh: PropTypes.func.isRequired,
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Carousel,
} from 'antd';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import MetricHints from './MetricHints';
import {
  metricsDetailsByType,
} from '../../../../util/MetricsDetails';

export default class MetricCarousel extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.setRef = this.setRef.bind(this);
    this.carousel = React.createRef();
  }

  setRef(node) {
    this.carousel = node;
  }

  next() {
    this.carousel.next();
  }

  previous() {
    this.carousel.prev();
  }

  render() {
    const props = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    const { taskType } = this.props;
    return (
      <Row justify="space-between" align="middle">
        <Col span={1} style={{ textAlign: 'center' }}>
          <LeftCircleOutlined onClick={this.previous} />
        </Col>
        <Col span={22}>
          <Carousel
            ref={this.setRef}
            {...props}
          >
            {metricsDetailsByType(taskType).map((m) => <MetricHints key={m.description} {...m} />)}
          </Carousel>
        </Col>
        <Col span={1} style={{ textAlign: 'center' }}>
          <RightCircleOutlined onClick={this.next} />
        </Col>
      </Row>
    );
  }
}

MetricCarousel.propTypes = {
  taskType: PropTypes.string.isRequired,
};

import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import PropTypes from 'prop-types';
import { getStatusLog } from '../../util/ApiCalls';
import LineGraphSimple from './LineGraphSimple';

export default function LeafStatistics({ id }) {
  const [dataCPU, setDataCPU] = useState([]);
  const [dataCalls, setDataCalls] = useState([]);
  useEffect(() => {
    try {
      getStatusLog(id).then((data) => {
        const dataCPUArray = Array.isArray(data)
          ? data.filter((statusLog) => statusLog.cpu)
            .map((statusLog) => ({
              x: statusLog.createdAt,
              y: statusLog.cpu,
            })) : [];
        const dataCallsArray = Array.isArray(data)
          ? data.filter((statusLog) => statusLog.cpu)
            .map((statusLog) => ({
              x: statusLog.createdAt,
              y: statusLog.calls,
            })) : [];
        setDataCPU([{
          id: 'cpu',
          data: dataCPUArray,
        }]);
        setDataCalls([{
          id: 'calls',
          data: dataCallsArray,
        }]);
      });
    } catch (e) {
      console.log(e);
    }
  }, [id]);
  return (
    <Card
      style={{ height: '100%' }}
      title="Leaf Statistics"
    >
      <div>
        <LineGraphSimple
          data={dataCPU}
          xAxisName="Time"
          yAxisName="CPU Load"
          maxYScale={1}
        />
      </div>
      <div>
        <LineGraphSimple
          data={dataCalls}
          xAxisName="Time"
          yAxisName="API Calls"
        />
      </div>
    </Card>
  );
}

LeafStatistics.propTypes = {
  id: PropTypes.node.isRequired,
};

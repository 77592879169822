/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Tooltip,
  message,
} from 'antd';
import RunSplitTable from '../TableTemplates/RunDataSplitTable';
import { getDataSequence } from '../../util/ApiCalls';

export default function SelectDataSplitItem(props) {
  const { form } = props;

  const checkMissingSequences = async () => {
    try {
      const leaves = form.getFieldValue('leaves');
      const datasplits = form.getFieldValue('datasplits');
      if (leaves !== undefined && datasplits !== undefined) {
        const expandedSequences = await Promise.all(datasplits[0].sequences
          .map((seq) => getDataSequence(seq)));
        const participants = leaves.map((leaf) => leaf.id);
        try {
          participants.map((participant) => expandedSequences
            .find((e) => e.leaf_id === participant)._id);
        } catch (e) {
          throw new Error('MissingDataSequence');
        }
      }
    } catch (e) {
      if (e.message === 'MissingDataSequence') {
        message.warning('Select a split which is defined for all participating leaves!');
      } else {
        message.warning(e.message);
      }
    }
  };

  return (
    <Tooltip title="Select a data split.">
      <Form.Item
        label="Data Splits"
        name="datasplits"
        rules={[
          {
            required: true,
            message: 'Please select a data split!',
          },
        ]}
        onChange={() => {
          checkMissingSequences();
        }}
      >
        <RunSplitTable
          selectedRow={form.getFieldValue('datasplits') ? [form.getFieldValue('datasplits')[0].key] : []}
        />
      </Form.Item>
    </Tooltip>
  );
}

SelectDataSplitItem.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

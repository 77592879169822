import React from 'react';
import PropTypes from 'prop-types';
import DataTable from '../../TableTemplates/DataTable';

// Graphs the run of one leaf over the course of the rounds
function LossTable(props) {
  const { performance } = props;
  const noTableData = [
    {
      iteration: 'No fetched table data yet',
      data: {
        0: 'No fetched table data yet',
      },
    },
  ];
  const columns = [
    {
      title: 'Iteration',
      dataIndex: 'iteration',
      fixed: 'left',
      ellipsis: true,
    },
  ];
  const childrenVal = [];

  let loss = noTableData;
  const round = (number) => Math.round((number + Number.EPSILON) * 10000) / 10000;
  if (performance && 'iterations' in performance && performance.iterations.length !== 0) {
    loss = performance.iterations.map((iteration) => ({
      iteration: iteration.iteration,
      data: iteration.train_history
        ? iteration.train_history.loss.map((l) => round(l))
        : [],
    }));
  }
  for (let index = 0; index < loss[0].data.length; index += 1) {
    const newColumn = {
      title: `${index}`,
      dataIndex: ['data', `${index}`],
      render: (entry) => round(entry),
      key: index,
    };
    childrenVal.push(newColumn);
  }
  const tableHeader = {
    title: 'Epochs',
    children: childrenVal,
    dataIndex: 'epochs',
    disableSearch: true,
  };
  columns.push(tableHeader);

  return (
    <DataTable
      columns={columns}
      dataSource={loss}
    />
  );
}

export default LossTable;

LossTable.propTypes = {
  performance: PropTypes.shape(
    {
      iterations: PropTypes.arrayOf(PropTypes.shape),
    },
  ),
};
LossTable.defaultProps = {
  performance: null,
};

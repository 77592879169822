import React from 'react';
import { Row, Col, Card } from 'antd';
import TaskTable from '../../components/TaskTable/TaskTable';
import TaskToolbar from '../../components/TaskToolbar/TaskToolbar';
import OverviewPageHeader from '../../components/PageHeader/OverviewPageHeader';
import MLTasksContextProvider, { MLTasksContext } from './MLTasksContext';

export default function MLTasks() {
  return (
    <MLTasksContextProvider>
      <Row>
        <Col span={24}>
          <OverviewPageHeader title="Machine Learning Tasks" />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <MLTasksContext.Consumer>
            {({ changeTask, state: { selectedTask } }) => (
              <div>
                <Card>
                  <TaskToolbar
                    selectedTask={selectedTask}
                    refresh={() => changeTask(null)}
                  />
                </Card>
                <TaskTable rowSelection={
                  {
                    onChange: (selectedRowKey, selectedRow) => {
                      changeTask(selectedRow[0]);
                    },
                    type: 'radio',
                    selectedRowKeys: selectedTask
                      ? [selectedTask.key]
                      : [],
                  }
                }
                />
              </div>
            )}
          </MLTasksContext.Consumer>
        </Col>
      </Row>
    </MLTasksContextProvider>
  );
}

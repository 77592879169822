import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import StatusTag from '../../StatusTag/StatusTag';

export default function NotificationItem(props) {
  const { leaf, time, status } = props;
  return (
    <>
      <Link to={`/leaf/${leaf}/`}>{leaf}</Link>
      {` | ${time} info: `}
      <StatusTag status={status} />
      <br />
    </>
  );
}

NotificationItem.propTypes = {
  leaf: PropTypes.node.isRequired,
  time: PropTypes.node.isRequired,
  status: PropTypes.node.isRequired,
};

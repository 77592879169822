import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import StatusTag from '../StatusTag/StatusTag';
import { RunContext } from '../../pages/RunOverview/RunContext';

export default function RunStatus({ iterations, status, time }) {
  const [statusValue, setStatusValue] = useState(status);
  const { currentIteration } = useContext(RunContext);
  useEffect(() => {
    if (status === 'running') {
      let value = status;
      if (iterations !== 0 && currentIteration === 0) {
        value = `initializing: 0 / ${iterations}`;
      } else if (iterations !== 0 && currentIteration <= iterations && currentIteration !== null) {
        value = `running: ${currentIteration} / ${iterations}`;
      }
      setStatusValue(value);
    } else {
      setStatusValue(status);
    }
  }, [status, currentIteration, iterations, time]);
  return (
    <StatusTag status={statusValue} />
  );
}

RunStatus.propTypes = {
  iterations: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
};

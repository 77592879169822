import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Tooltip,
  message,
} from 'antd';
import RunTaskTable from '../TableTemplates/RunTaskTable';
import { getTask } from '../../util/ApiCalls';

export default function SelectTaskItem(props) {
  const {
    form, onChange, title, modelType,
  } = props;

  const getTaskDetails = async () => {
    try {
      const task = await getTask(form.getFieldValue('task')[0].task);
      onChange(task);
    } catch (e) {
      message.warning(e.message);
    }
  };

  return (
    <Tooltip title={title}>
      <Form.Item
        label="ML Task"
        name="task"
        rules={[
          {
            required: true,
            message: 'Please select a ML task!',
          },
        ]}
        onChange={() => {
          getTaskDetails();
        }}
      >
        <RunTaskTable
          modelType={modelType}
          selectedRow={form.getFieldValue('task') ? [form.getFieldValue('task')[0].key] : []}
        />
      </Form.Item>
    </Tooltip>
  );
}

SelectTaskItem.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  title: PropTypes.string,
  modelType: PropTypes.string,
  onChange: PropTypes.func,
};

SelectTaskItem.defaultProps = {
  title: null,
  modelType: null,
  onChange: () => {},
};

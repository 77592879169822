import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';
import { metricDetails } from '../../util/MetricsDetails';
import { aggregationDetails } from '../../util/AggregationsDetails';

function ReportingGraph({
  metric, aggregation, aggData, numGraphs,
}) {
  const metricFamily = metricDetails(metric).family;
  const aggregationDescription = aggregationDetails(aggregation).description;
  const round = (number) => Math.round((number + Number.EPSILON) * 10000) / 10000;

  const yAxisName = `${metricFamily} \n (${aggregationDescription})`;
  let graphData = [];
  aggData.forEach((row) => {
    row.comparison.forEach((leaf) => {
      if (row.aggregation === aggregation && leaf.metric === metric && leaf.value !== null) {
        if (!graphData.some((graphElement) => graphElement.leaf_id === leaf.leaf_id)) {
          graphData.push({
            leaf_id: leaf.leaf_id,
            metric: leaf.metric,
            aggregation: row.aggregation,
            [row.run_name]: leaf.value === 0 ? null : round(leaf.value),
          });
        } else {
          graphData = graphData
            .map((graphElement) => (graphElement.leaf_id === leaf.leaf_id
              ? {
                ...graphElement,
                [row.run_name]: round(leaf.value),
              }
              : graphElement));
        }
      }
    });
  });

  let keys = [];
  if (Array.isArray(graphData) && graphData.length) {
    keys = Object.keys(graphData[0]).filter((key) => (key !== 'leaf_id' && key !== 'metric' && key !== 'aggregation'));
  }

  return (
    <div style={{ height: (numGraphs < 2) ? '400px' : '200px', width: '100%' }}>
      <ResponsiveBar
        keys={keys}
        data={graphData}
        groupMode="grouped"
        indexBy="leaf_id"
        margin={{
          top: 10, right: (numGraphs < 3) ? 210 : 30, bottom: (numGraphs < 3) ? 50 : 110, left: 63,
        }}
        colors={{ scheme: 'nivo' }}
        gridYValues={(numGraphs < 2) ? 10 : 5}
        enableLabel
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        labelSkipWidth={37}
        labelSkipHeight={12}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          tickValues: (numGraphs < 2) ? 10 : 5,
          legend: yAxisName,
          legendOffset: -58,
          legendPosition: (numGraphs < 2) ? 'middle' : 'end',
        }}
        axisBottom={{
          legend: 'Participants',
          legendOffset: 30,
          legendPosition: 'middle',
        }}
        legends={
          [{
            dataFrom: 'keys',
            anchor: (numGraphs < 3) ? 'bottom-right' : 'bottom-left',
            direction: 'column',
            justify: false,
            translateX: (numGraphs < 3) ? 200 : 0,
            translateY: (numGraphs < 3) ? 0 : 110,
            itemsSpacing: 2,
            itemWidth: 180,
            itemHeight: 15,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 12,
            symbolShape: 'circle',
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
          ]
        }
      />
    </div>
  );
}

export default ReportingGraph;

ReportingGraph.propTypes = {
  aggData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  aggregation: PropTypes.string.isRequired,
  metric: PropTypes.string.isRequired,
  numGraphs: PropTypes.number.isRequired,
};

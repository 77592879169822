import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

function HiddenOptionsSelector(props) {
  const {
    inputOptions, placeholder, style, onChange,
  } = props;

  const [selectedItems, setSelectedItems] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);

  const onItemSelection = (items) => {
    setSelectedItems(items);
    const values = items.map((t) => t.value);
    onChange(values);
  };

  const onInputOptionsUpdate = () => {
    if (Array.isArray(selectedItems) && !selectedItems.length) {
      setSelectedItems(inputOptions);
    }
  };
  useEffect(onInputOptionsUpdate, [inputOptions]);

  useEffect(() => {
    const filtered = inputOptions
      .filter((option) => !selectedItems
        .map((item) => item.value)
        .includes(option.value));

    setFilteredOptions(filtered);
  }, [inputOptions, selectedItems]);

  return (
    <Select
      mode="multiple"
      placeholder={placeholder}
      value={selectedItems}
      labelInValue
      onChange={onItemSelection}
      style={style}
    >
      {filteredOptions.map((item) => (
        <Select.Option key={item.value} value={item.value} label={item.label}>
          {item.label}
        </Select.Option>
      ))}
    </Select>
  );
}

export default HiddenOptionsSelector;

HiddenOptionsSelector.propTypes = {
  style: PropTypes.shape({}),
  inputOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

HiddenOptionsSelector.defaultProps = {
  style: { width: '200px' },
  placeholder: 'Select an Item',
  onChange: () => { },
};

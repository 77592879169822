import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DataTable from '../../../TableTemplates/DataTable';

/* eslint-disable react/forbid-prop-types */
/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

class TargetSelectionTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataDefinition: [],
    };
  }

  async componentDidMount() {
    const { selectedSchema } = this.props;
    if (selectedSchema) {
      this.updateSelection(selectedSchema);
    }
  }

  async componentDidUpdate(prevProps) {
    const { selectedSchema } = this.props;
    if (prevProps.selectedSchema !== selectedSchema && selectedSchema) {
      this.updateSelection(selectedSchema);
    }
  }

  async updateSelection(selectedSchema) {
    const { definition } = selectedSchema;
    const res = Object.keys(definition).map((key) => ({ key, column: key, type: definition[key] }));
    this.setState({
      dataDefinition: res,
    });
  }

  render() {
    const { dataDefinition } = this.state;
    const { rowSelection } = this.props;
    const columns = [
      {
        title: 'Column',
        dataIndex: 'column',
      },
      {
        title: 'Type',
        dataIndex: 'type',
      },
    ];

    return (
      <div>
        <DataTable
          rowSelection={rowSelection}
          columns={columns}
          dataSource={dataDefinition}
        />
      </div>
    );
  }
}

export default TargetSelectionTable;

TargetSelectionTable.defaultProps = {
  selectedSchema: {},
  rowSelection: [],
};

TargetSelectionTable.propTypes = {
  selectedSchema: PropTypes.shape({
    definition: PropTypes.object,
  }),
  rowSelection: PropTypes.shape({
    onChange: PropTypes.func,
  }),
};

export const aggregationsInformation = [
  {
    type: 'min',
    description: 'Minimum',
  },
  {
    type: 'max',
    description: 'Maximum',
  },
  {
    type: 'avg',
    description: 'Average',
  },
];

export function aggregationsDetails(aggregations) {
  const expandedAggregations = aggregations
    .map((aggregation) => aggregationsInformation
      .find((element) => element.type === aggregation));

  return expandedAggregations;
}

export function aggregationDetails(aggregation) {
  return aggregationsInformation.find((element) => element.type === aggregation);
}

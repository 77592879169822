import React from 'react';
import PropTypes from 'prop-types';
import { LineGraph } from '../LineGraph';

const BiaxialLineGraphBottom = ({
  dataLeft, dataRight, colorsLeft, colorsRight, xAxisName, yAxisName, height,
}) => {
  const dataLeftAndRight = dataLeft.concat(dataRight);
  const colorsLeftAndRight = [];
  for (let i = 0; i < dataLeft.length; i += 1) {
    colorsLeftAndRight.push(colorsLeft[i]);
  }
  for (let i = 0; i < dataRight.length; i += 1) {
    colorsLeftAndRight.push(colorsRight[i]);
  }

  dataLeftAndRight.forEach((element, index) => {
    element.graphColor = colorsLeftAndRight[index];
  });

  return (
    <div
      style={{
        height,
        position: 'absolute',
        width: '100%',
        zIndex: 3,
      }}
    >
      <LineGraph
        useMesh={false}
        data={dataLeftAndRight}
        yScale={{
          type: 'linear', min: '0', max: '1', stacked: false, reverse: false,
        }}
        xAxisName={xAxisName}
        yAxisName={yAxisName}
        enableGridY={!!yAxisName}
        enableGridX={false}
        colors={colorsLeftAndRight}
        layers={['grid', 'axes', 'slices']}
        enableSlices="x"
        sliceTooltip={({ slice }) => (
          <div
            style={{
              background: 'white',
              padding: '9px 12px',
              border: '1px solid #ccc',
            }}
          >
            <div>
              {xAxisName}
              {' '}
              {slice.points[0].data.x}
            </div>
            {slice.points.map((point) => (
              <div
                key={point.id}
                style={{
                  padding: '3px 0',
                }}
              >
                <span style={{ color: point.serieColor }}> &#9632; </span>
                {point.serieId}
                {': '}
                <strong>{point.data.yFormatted}</strong>
              </div>
            ))}
          </div>
        )}
      />
    </div>
  );
};

export default BiaxialLineGraphBottom;

BiaxialLineGraphBottom.propTypes = {
  dataLeft: PropTypes.PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.number,
          y: PropTypes.number,
        }),
      ),
    }),
  ).isRequired,
  dataRight: PropTypes.PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.number,
          y: PropTypes.number,
        }),
      ),
    }),
  ).isRequired,
  colorsLeft: PropTypes.arrayOf(PropTypes.string),
  colorsRight: PropTypes.arrayOf(PropTypes.string),
  xAxisName: PropTypes.string,
  yAxisName: PropTypes.string,
  height: PropTypes.string,
};
BiaxialLineGraphBottom.defaultProps = {
  colorsLeft: { scheme: 'spectral' },
  colorsRight: { scheme: 'pink_yellowGreen' },
  xAxisName: 'x',
  yAxisName: null,
  height: '560px',
};

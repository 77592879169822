import React, { Component } from 'react';
import {
  Card, Badge, Button, Popconfirm,
} from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getStatus, postDeregister } from '../../../util/ApiCalls';

class LeafItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 'Overview',
      status: {},
    };
  }

  async componentDidMount() {
    const { id } = this.props;
    try {
      const data = await getStatus(id);
      if (data) {
        this.setState({
          status: data,
        });
      } else {
        this.setState({
          status: (
            {
              status: 'waiting for leaf response',
            }),
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  onTabChange(key, type) {
    this.setState({ [type]: key });
  }

  async deregister() {
    const { id, onDeregister } = this.props;
    try {
      await postDeregister(id);
      onDeregister();
    } catch (e) {
      console.log(e);
      onDeregister();
    }
  }

  render() {
    const { id, name, description } = this.props;
    const { key } = this.state;
    const {
      status: {
        status, calls, cpu, memory,
      },
    } = this.state;
    const cardTitle = (
      <>
        <Link to={`/leaf/${id}/`}>
          {`${name}: ${id} `}
        </Link>
      </>
    );
    const tabList = [
      {
        key: 'Overview',
        tab: 'Overview',
      },
      {
        key: 'Details',
        tab: 'Details',
      },
    ];

    const contentList = {
      Overview:
  <div>
    <p>
      {' '}
      <Badge status={status === 'online' ? 'success' : 'warning'} text={status} />
      {' '}
    </p>
    {status === 'online' ? (
      <p>
        {' '}
        <CheckCircleOutlined theme="twoTone" twoToneColor="#52c41a" />
        {' '}
        Data validated
        {' '}
      </p>
    ) : (
      <p>
        {' '}
        <CloseCircleOutlined theme="twoTone" twoToneColor="#52c41a" />
        {' '}
        Data not validated
        {' '}
      </p>
    )}
    <p>
      {' '}
      {description}
      {' '}
    </p>
  </div>,
      Details:
  <div>
    <table>
      <tr>
        <td> Requests (last 24h): </td>
        <td>
          {' '}
          {calls}
          {' '}
          calls
        </td>
      </tr>
      <tr>
        <td> CPU Usage:</td>
        <td>
          {' '}
          {Number(cpu).toFixed(2) * 100}
          {' '}
          %
        </td>
      </tr>
      <tr>
        <td> Memory Usage:</td>
        <td>
          {' '}
          {Number(memory).toFixed(2) * 100}
          {' '}
          %
        </td>
      </tr>
    </table>
  </div>,
    };
    return (
      <Card
        style={{ width: '100%' }}
        title={cardTitle}
        extra={(
          <Popconfirm
            title="Are you sure you want to Deregister the Leaf？"
            okText="Yes"
            cancelText="No"
            onConfirm={() => this.deregister()}
          >
            <Button danger>
              <DeleteOutlined />
              Deregister
            </Button>
          </Popconfirm>
        )}
        tabList={tabList}
        activeTabKey={key}
        onTabChange={(newKey) => {
          this.onTabChange(newKey, 'key');
        }}
      >
        {contentList[key]}
      </Card>
    );
  }
}

LeafItem.propTypes = {
  id: PropTypes.node.isRequired,
  name: PropTypes.node.isRequired,
  onDeregister: PropTypes.func.isRequired,
  description: PropTypes.node.isRequired,
};

export default LeafItem;

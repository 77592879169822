/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import cognitoUtils from './util/UtilsCognito';
import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';

const { Content, Footer } = Layout;

function ProtectedRoute({ component: Component, ...rest }) {
  let works = false;
  const cognitoUser = cognitoUtils.createCognitoUser();
  if (cognitoUser) {
    works = cognitoUser.getSession((err, result) => {
      if (err || !result) {
        return false;
      }
      return result;
    });
  }
  if (works) {
    const { username } = cognitoUser;
    return (
      <Layout style={{ height: '100vh' }}>
        <Header username={username}>Header</Header>
        <Layout>
          <Sidebar />
          <Layout>
            <Content>
              <Route
                render={(props) => (<Component {...props} />)}
                {...rest}
              />
            </Content>
            <Footer style={{
              textAlign: 'right',
              color: '#8a8a8a',
              fontSize: '9px',
            }}
            >
              v.0.20
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    );
  }
  return (
    <Route
      path="/"
      component={() => {
        window.location.href = cognitoUtils.getCognitoSignInUri();
        return null;
      }}
    />
  );
}
export default ProtectedRoute;

ProtectedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

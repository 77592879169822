import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DataTable from './DataTable';
import { getDataSequence, getDataSplits } from '../../util/ApiCalls';

class RunDataSplitTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSplits: [],
      selectedRow: props.selectedRow,
    };
  }

  async componentDidMount() {
    async function expandDataSequences(dataSplit) {
      const dataSequences = await Promise.all(dataSplit.dataSequences
        .map((seq) => getDataSequence(seq)));
      return { ...dataSplit, dataSeqObjs: dataSequences };
    }
    try {
      let dataSplits = await getDataSplits();
      dataSplits = await Promise.all(dataSplits.map(expandDataSequences));
      dataSplits = dataSplits.map((split) => ({
        // eslint-disable-next-line
        key: split._id,
        name: split.name,
        type: split.type,
        ratio: split.dataSeqObjs[0].split_params.train_percentage,
        sequences: split.dataSequences,
      }));
      this.setState({ dataSplits });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { onChange } = this.props;
    const { dataSplits, selectedRow } = this.state;
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
      },
      {
        title: 'Type',
        dataIndex: 'type',
      },
      {
        title: 'Training Data Ratio',
        dataIndex: 'ratio',
      },
    ];

    const rowSelection = {
      selectedRowKeys: selectedRow,
      type: 'radio',
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          selectedRow: selectedRowKeys,
        });
        onChange(selectedRows);
      },
    };

    return (
      <>
        <DataTable
          rowSelection={rowSelection}
          columns={columns}
          dataSource={dataSplits}
        />
      </>
    );
  }
}

export default RunDataSplitTable;

RunDataSplitTable.propTypes = {
  onChange: PropTypes.func,
  selectedRow: PropTypes.arrayOf(PropTypes.string),
};

RunDataSplitTable.defaultProps = {
  onChange: () => {},
  selectedRow: [],
};

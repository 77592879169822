import React from 'react';
import {
  Row, Col, Card, Typography,
} from 'antd';
import ReportingTable from '../../components/ReportingTable/ReportingTable';
import ReportingToolbar from '../../components/ReportingToolbar/ReportingToolbar';
import ReportingDetailsToolbar from '../../components/ReportingDetails/ReportingDetailsToolbar';
import ReportingAggregationTable from '../../components/ReportingAggregationTable/ReportingAggregationTable';
import ReportingGraph from '../../components/ReportingGraph/ReportingGraph';
import OverviewPageHeader from '../../components/PageHeader/OverviewPageHeader';
import ReportingContextProvider, { ReportingContext } from './ReportingContext';

const { Paragraph } = Typography;

export default function Reporting() {
  return (
    <ReportingContextProvider>
      <Row>
        <Col span={24}><OverviewPageHeader title="Reporting" /></Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card>
            <ReportingContext.Consumer>
              {({ selectRuns, state: { selectedRuns, runs }, selectDataSchema }) => (
                <ReportingToolbar
                  selectedRuns={selectedRuns}
                  reportData={runs}
                  refresh={() => selectRuns(null)}
                  onChange={(e) => {
                    selectDataSchema(e.selectedDataSchema);
                  }}
                  onFilter={() => {}}
                />
              )}
            </ReportingContext.Consumer>
          </Card>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <ReportingContext.Consumer>
                {({ selectRuns, state: { selectedRuns, runs } }) => (
                  <ReportingTable
                    rowSelection={
                      {
                        onChange: (selectedRowKeys, selectedRows) => {
                          selectRuns(selectedRows);
                        },
                        type: 'checkbox',
                        selectedRowKeys: selectedRuns.map((run) => run.key),
                      }
                    }
                    reportData={runs}
                  />
                )}
              </ReportingContext.Consumer>
            </Col>
          </Row>
        </Col>
      </Row>
      <ReportingContext.Consumer>
        {({
          state: {
            selectedRuns, selectedDataSchema, aggregationData,
          }, selectAggregations, selectMetrics,
        }) => {
          if (selectedDataSchema && Array.isArray(selectedRuns) && selectedRuns.length) {
            return (
              <>
                <Row gutter={[16, 16]} style={{ margin: '-8px -8px ' }}>
                  <Col span={24}>
                    <Card>
                      <ReportingDetailsToolbar
                        selectAggregations={selectAggregations}
                        selectMetrics={selectMetrics}
                        selectedRuns={selectedRuns}
                        selectedDataSchema={selectedDataSchema}
                        aggData={aggregationData}
                      />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Card style={{ height: '500px' }}>
                      <ReportingAggregationTable
                        aggData={aggregationData}
                      />
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card style={{ height: '500px' }}>
                      <Row gutter={[16, 16]}>
                        <ReportingContext.Consumer>
                          {({
                            state: { selectedMetrics, selectedAggregations },
                          }) => {
                            const reportingGraphs = [];
                            selectedMetrics.forEach((metric) => {
                              selectedAggregations.forEach((aggregation) => (
                                reportingGraphs.push({
                                  metric,
                                  aggregation,
                                })
                              ));
                            });
                            const height = (reportingGraphs.length === 1) ? '450px' : '225px';
                            const graphSpan = (reportingGraphs.length < 3) ? 24 : 12;

                            if (reportingGraphs.length > 4) {
                              return (
                                <Typography>
                                  <Paragraph>
                                    Only four graphs can be displayed at the same time.
                                  </Paragraph>
                                  <Paragraph>
                                    Please select in the toolbar either
                                    <ul>
                                      <li>up to two metrics and two aggregations or</li>
                                      <li>one metric and up to four aggregations or</li>
                                      <li>one aggregation and up to four metrics</li>
                                    </ul>
                                  </Paragraph>
                                </Typography>
                              );
                            }
                            return (
                              Array.from(reportingGraphs.map((graph) => (
                                <Col span={graphSpan} key={`${graph.metric.type}-${graph.aggregation.type}`}>
                                  <span style={{ height, whiteSpace: 'pre-line' }}>
                                    <ReportingGraph
                                      metric={graph.metric.type}
                                      aggregation={graph.aggregation.type}
                                      aggData={aggregationData}
                                      numGraphs={reportingGraphs.length}
                                    />
                                  </span>
                                </Col>
                              )))
                            );
                          }}
                        </ReportingContext.Consumer>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </>
            );
          }
          return ('');
        }}
      </ReportingContext.Consumer>
    </ReportingContextProvider>
  );
}

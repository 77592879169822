import React, { Component } from 'react';
import PropTypes from 'prop-types';

/* eslint-disable react/forbid-prop-types */

export const DataSchemaSelectionContext = React.createContext();

class DataSchemaSelectionContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSchema: props.dataSchema,
    };
  }

  render() {
    const { children } = this.props;
    const { selectedSchema } = this.state;
    return (
      <DataSchemaSelectionContext.Provider
        value={
          {
            state: { selectedSchema },
            changeSchema: (schema) => {
              this.setState({ selectedSchema: schema });
            },
          }
        }
      >
        {children}
      </DataSchemaSelectionContext.Provider>
    );
  }
}

export default DataSchemaSelectionContextProvider;

DataSchemaSelectionContextProvider.defaultProps = {
  dataSchema: null,
};

DataSchemaSelectionContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  dataSchema: PropTypes.shape({
    definition: PropTypes.object,
  }),
};

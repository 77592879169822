import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import PropTypes from 'prop-types';
import {
  LineChartOutlined, TableOutlined, FallOutlined, LockOutlined,
} from '@ant-design/icons';
import LeafRunGraph from './RunParticipantDetailsTabs/LeafRunGraph/LeafRunGraph';
import LeafRunTable from './RunParticipantDetailsTabs/LeafRunTable';
import LossGraph from './RunParticipantDetailsTabs/LossGraph';
import LossTable from './RunParticipantDetailsTabs/LossTable';
import DBTable from './RunParticipantDetailsTabs/DPTable';

const { TabPane } = Tabs;

export default function RunParticipantDetails({
  run, participant, performance, differentialPrivacy, runType, isTrainHistoryMissing,
}) {
  const [participantPerformance, setParticipantPerformance] = useState(null);
  useEffect(() => {
    if (performance) {
      const participantPerformances = performance.find((perf) => perf.participant === participant);
      setParticipantPerformance(participantPerformances);
    }
  }, [participant, run, performance]);
  return (
    <Tabs defaultActiveKey="1">
      { runType === 'fml' || runType === 'stl'
        ? (
          <>
            <TabPane
              tab={(
                <span>
                  <LineChartOutlined />
                  Performance Graph
                </span>
        )}
              key="1"
            >
              <LeafRunGraph
                run={run}
                participant={participant}
                performance={participantPerformance}
              />
            </TabPane>
            <TabPane
              tab={(
                <span>
                  <TableOutlined />
                  Performance Table
                </span>
        )}
              key="2"
            >
              <LeafRunTable
                run={run}
                participant={participant}
                performance={participantPerformance}
              />
            </TabPane>
          </>
        )
        : null}
      {
        differentialPrivacy
          ? (
            <TabPane
              tab={(
                <span>
                  <LockOutlined />
                  DP Budget
                </span>
          )}
              key="5"
            >
              <DBTable
                run={run}
                participant={participant}
                performance={participantPerformance}
              />
            </TabPane>
          ) : null
      }
      {
        isTrainHistoryMissing ? null
          : (
            <>
              <TabPane
                tab={(
                  <span>
                    <FallOutlined />
                    Loss Graph
                  </span>
        )}
                key="3"
              >
                <LossGraph
                  run={run}
                  participant={participant}
                  performance={participantPerformance}
                />
              </TabPane>
              <TabPane
                tab={(
                  <span>
                    <TableOutlined />
                    Loss Table
                  </span>
        )}
                key="4"
              >
                <LossTable
                  run={run}
                  participant={participant}
                  performance={participantPerformance}
                />
              </TabPane>
            </>
          )
}
    </Tabs>
  );
}

RunParticipantDetails.propTypes = {
  run: PropTypes.string.isRequired,
  participant: PropTypes.string.isRequired,
  performance: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  differentialPrivacy: PropTypes.bool,
  runType: PropTypes.string,
  isTrainHistoryMissing: PropTypes.bool.isRequired,
};

RunParticipantDetails.defaultProps = {
  differentialPrivacy: false,
  runType: 'fml',
};

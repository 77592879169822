import React from 'react';
import PropTypes from 'prop-types';
import DataTable from '../TableTemplates/DataTable';
import { metricsInformation } from '../../util/MetricsDetails';
import { aggregationDetails } from '../../util/AggregationsDetails';

/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

function ReportingAggregationTable(props) {
  const { aggData } = props;
  const rawData = aggData;
  const allClass = 'reportingAggregationCols';
  const round = (number) => Math.round((number + Number.EPSILON) * 10000) / 10000;
  const columns = [
    {
      title: 'Run',
      dataIndex: 'run_name',
      key: 'run_name',
      fixed: 'left',
      render: (entry) => (entry || '-'),
      sorter: (a, b) => a.run_name.localeCompare(b.run_name),
      className: allClass,
    },
    {
      title: 'Aggregation',
      dataIndex: 'aggregation',
      key: 'aggregation',
      fixed: 'left',
      render: (entry) => (entry !== '-' && (aggregationDetails(entry).description || '-')),
      sorter: (a, b) => a.aggregation.localeCompare(b.aggregation),
      className: allClass,
    },
  ];

  function rawToTableData(fetchedData) {
    if (!fetchedData || fetchedData.length === 0) {
      return [];
    }
    const allLeafIds = [];
    fetchedData.forEach((element) => {
      element.comparison.map((el) => allLeafIds.push(el.leaf_id));
    });

    const colParentHeader = [...new Set(allLeafIds)]; // delete redundant column names
    fetchedData.forEach((dataObj) => {
      const transfComparison = []; // Array that will contain the comparison data
      colParentHeader.forEach((leafId) => { // Iterate through all leafIds to fetch their data
        const allLeafMetrics = {}; // Object that will contain all metrics for one leafId
        dataObj.comparison.forEach((compObj) => {
          if (compObj.leaf_id === leafId) { // Matching comparisonData to its correct leafId
            const keyIndex = compObj.metric;
            const { value } = compObj;
            Object.assign(allLeafMetrics, { [keyIndex]: round(value) });
          }
        });
        // New digestable comparison object for table visualization
        transfComparison.push({
          leaf_id: leafId,
          metrics: allLeafMetrics,
        });
      });
      dataObj.transformed_comparison = transfComparison;
    });
    return fetchedData;
  }
  let tableData = rawToTableData(rawData);
  tableData = tableData.map((data, index) => ({ key: index, ...data }));
  if (tableData.length > 0) {
    try {
      const compareLeafIds = [];
      tableData.forEach((e) => {
        e.transformed_comparison.forEach((compObj, index) => {
          const childCols = [];
          // check if leaf_id is already part of the columns
          if (!compareLeafIds.includes(compObj.leaf_id)) {
            // check if metrics is not empty
            if (Object.keys(compObj.metrics).length !== 0) {
              Object.keys(compObj.metrics).forEach((key) => {
                const childCol = {
                  title: metricsInformation.find((m) => m.type === key).family,
                  dataIndex: ['transformed_comparison', `${index}`, 'metrics', `${key}`],
                  key: (`${compObj.leaf_id}_${key}`),
                  render: (entry) => (entry || (entry === 0 ? 0 : '-')),
                  className: allClass,
                };
                childCols.push(childCol);
              });
              compareLeafIds.push(compObj.leaf_id);
              const newCol = {
                title: compObj.leaf_id,
                children: childCols,
                disableSearch: true,
                key: index,
                dataIndex: '',
              };
              columns.push(newCol);
            }
          }
        });
      });
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <DataTable
      columns={columns}
      dataSource={tableData}
      pagination={{ defaultPageSize: 15 }}
    />
  );
}

export default ReportingAggregationTable;

ReportingAggregationTable.propTypes = {
  aggData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Button,
  Upload,
  Tooltip,
  Typography,
  Switch,
  Popconfirm,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/mode/python/python';
import 'codemirror/lib/codemirror.css';
import './ProcessorUpload.css';

const { TextArea } = Input;
const { Text } = Typography;

const scriptExampleText = [
  {
    firstSwitch: false,
    secondSwitch: false,
    thirdSwitch: false,
    value: 'import numpy as np\n# import any of the following packages if needed:\n# numpy, pandas, scikit-learn, scipy, sklearn\n\ndef train_preprocess(df):\n\t# ...\n\treturn X, y\n\ndef deployment_preprocess(X):\n\t# ...\n\treturn X',
  },
  {
    firstSwitch: true,
    secondSwitch: false,
    thirdSwitch: false,
    value: 'import numpy as np\n# import any of the following packages if needed:\n# numpy, pandas, scikit-learn, scipy, sklearn\n\ndef train_preprocess(df):\n\t# ...\n\treturn X, y\n\ndef test_preprocess(df):\n\t# ...\n\treturn X, y\n\ndef deployment_preprocess(X):\n\t# ...\n\treturn X',
  },
  {
    firstSwitch: false,
    secondSwitch: true,
    thirdSwitch: false,
    value: 'import numpy as np\n# import any of the following packages if needed:\n# numpy, pandas, scikit-learn, scipy, sklearn\n\ndef train_preprocess(df):\n\t# ...\n\treturn X, y\n\ndef test_postprocess(y):\n\t# ...\n\treturn y\n\ndef deployment_preprocess(X):\n\t# ...\n\treturn X',
  },
  {
    firstSwitch: false,
    secondSwitch: false,
    thirdSwitch: true,
    value: 'import numpy as np\n# import any of the following packages if needed:\n# numpy, pandas, scikit-learn, scipy, sklearn\n\ndef train_preprocess(df):\n\t# ...\n\treturn X, y\n\ndef deployment_preprocess(X):\n\t# ...\n\treturn X',
  },
  {
    firstSwitch: true,
    secondSwitch: true,
    thirdSwitch: false,
    value: 'import numpy as np\n# import any of the following packages if needed:\n# numpy, pandas, scikit-learn, scipy, sklearn\n\ndef train_preprocess(df):\n\t# ...\n\treturn X, y\n\ndef test_preprocess(df):\n\t# ...\n\treturn X, y\n\ndef test_postprocess(y):\n\t# ...\n\treturn y\n\ndef deployment_preprocess(X):\n\t# ...\n\treturn X',
  },
  {
    firstSwitch: true,
    secondSwitch: false,
    thirdSwitch: true,
    value: 'import numpy as np\n# import any of the following packages if needed:\n# numpy, pandas, scikit-learn, scipy, sklearn\n\ndef train_preprocess(df):\n\t# ...\n\treturn X, y, processor_serialization\n\ndef test_preprocess(df, processor_serialization):\n\t# ...\n\treturn X, y\n\ndef deployment_preprocess(X, processor_serialization):\n\t# ...\n\treturn X',
  },
  {
    firstSwitch: false,
    secondSwitch: true,
    thirdSwitch: true,
    value: 'import numpy as np\n# import any of the following packages if needed:\n# numpy, pandas, scikit-learn, scipy, sklearn\n\ndef train_preprocess(df):\n\t# ...\n\treturn X, y, processor_serialization\n\ndef test_postprocess(y, processor_serialization):\n\t# ...\n\treturn y\n\ndef deployment_preprocess(X, processor_serialization):\n\t# ...\n\treturn X',
  },
  {
    firstSwitch: true,
    secondSwitch: true,
    thirdSwitch: true,
    value: 'import numpy as np\n# import any of the following packages if needed:\n# numpy, pandas, scikit-learn, scipy, sklearn\n\ndef train_preprocess(df):\n\t# ...\n\treturn X, y, processor_serialization\n\ndef test_preprocess(df, processor_serialization):\n\t# ...\n\treturn X, y\n\ndef test_postprocess(y, processor_serialization):\n\t# ...\n\treturn y\n\ndef deployment_preprocess(X, processor_serialization):\n\t# ...\n\treturn X',
  },
];

class ProcessorUpload extends Component {
  constructor(props) {
    super();
    const {
      processordefinition,
      firstSwitch,
      secondSwitch,
      thirdSwitch,
      customizedProcessing,
      validProcessing,
      invalidProcessingMessage,
    } = props;
    this.state = {
      processordefinition,
      firstSwitch,
      secondSwitch,
      thirdSwitch,
      customizedProcessing,
      validProcessing,
      invalidProcessingMessage,
    };
  }

  async componentDidMount() {
    this.validateForm();
  }

  async onDescriptionChange({ target: { value } }) {
    const { processordefinition } = this.props;
    const { onChange } = this.props;
    await this.setStateAsync({
      processordefinition: { ...processordefinition, description: value },
    });
    onChange(this.state);
    this.validateForm();
  }

  async onScriptChange(value) {
    const { processordefinition } = this.props;
    const { onChange } = this.props;
    await this.setStateAsync({
      processordefinition: { ...processordefinition, script: value },
    });
    this.validateForm();

    const isExampleScript = scriptExampleText.some((exampleText) => exampleText.value === value);
    if (!isExampleScript) {
      this.setState({ customizedProcessing: true });
    } else {
      this.setState({ customizedProcessing: false });
    }
    onChange(this.state);
    this.evaluateScript(value);
    this.validateScript(value);
  }

  onSwitchChange() {
    const { firstSwitch } = this.state;
    const { secondSwitch } = this.state;
    const { thirdSwitch } = this.state;

    const result = scriptExampleText
      .find((e) => e.firstSwitch === firstSwitch
      && e.secondSwitch === secondSwitch
      && e.thirdSwitch === thirdSwitch)
      .value;
    this.onScriptChange(result);
  }

  async onFirstSwitchChange(checked) {
    await this.setState({ firstSwitch: checked });
    this.onSwitchChange();
  }

  async onSecondSwitchChange(checked) {
    await this.setState({ secondSwitch: checked });
    this.onSwitchChange();
  }

  async onThirdSwitchChange(checked) {
    await this.setState({ thirdSwitch: checked });
    this.onSwitchChange();
  }

  onScriptUpload({ file: { status, originFileObj } }) {
    if (status !== 'uploading') {
      const reader = new FileReader();
      reader.onload = ({ target: { result } }) => {
        this.onScriptChange(result);
      };
      reader.readAsText(originFileObj);
    }
  }

  onReset() {
    this.onSwitchChange();
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  async evaluateScript(value) {
    const { onChange } = this.props;
    const regexTestPreprocessInput = /def test_preprocess\(\w+/;
    const regexPostprocessInput = /def test_postprocess\(\w+/;
    const regexSerializationInput = /processor_serialization/;

    if (regexTestPreprocessInput.test(value)) {
      this.setState({ firstSwitch: true });
    } else {
      this.setState({ firstSwitch: false });
    }
    if (regexPostprocessInput.test(value)) {
      this.setState({ secondSwitch: true });
    } else {
      this.setState({ secondSwitch: false });
    }
    if (regexSerializationInput.test(value)
    ) {
      this.setState({ thirdSwitch: true });
    } else {
      this.setState({ thirdSwitch: false });
    }
    onChange(this.state);
  }

  async validateScript(value) {
    const { onChange } = this.props;
    const { firstSwitch, secondSwitch, thirdSwitch } = this.state;
    let message = '';

    // Ensure a train_preprocess method is defined.
    const regexTrainPreprocessInput = /def train_preprocess\(\w+\):/;
    if (!regexTrainPreprocessInput.test(value)) message += 'Train preprocessing input should be similar to "train_preprocess(df):".\n';

    // Ensure train_preprocess returns the right values
    // FIXME: The order of the methods is relevant in the current implementation.
    let regexTrainPreprocessOutput = /return\s+(\w+,\s*\w+|ds)\s*/;
    if (thirdSwitch) regexTrainPreprocessOutput = /return\s+(\w+,\s*\w+|ds),\s*\w+\s*/;
    else if (firstSwitch) regexTrainPreprocessOutput = /def train.*return\s+(\w+,\s*\w+|ds)\s*(.+?(?=test_preprocess))/s;
    else if (secondSwitch) regexTrainPreprocessOutput = /def train.*return\s+(\w+,\s*\w+|ds)\s*(.+?(?=test_postprocess))/s;
    if (thirdSwitch && !regexTrainPreprocessOutput.test(value)) message += 'Method train_preprocess should return X, y, and processor_serialization.\n';
    else if (!regexTrainPreprocessOutput.test(value)) message += 'Method train_preprocess should return X and y.\n';

    if (firstSwitch) {
      // Ensure test_preprocess takes one parameter if third switch is not enabled
      const regexTestPreprocessInput = /def test_preprocess\(\w+\):/;
      if (!regexTestPreprocessInput.test(value) && !thirdSwitch) message += 'Test preprocessing input should be similar to "test_preprocess(df):".\n';
      // Ensure test_postprocess takes two parameters if third switch is enabled
      const regexPreprocessInputS = /def test_preprocess\(\w+, \w+\):/;
      if (!regexPreprocessInputS.test(value) && thirdSwitch) message += 'Test preprocessing input should be similar to "test_preprocess(y, processor_serialization):".\n';
      // Ensure Ensure test_preprocess returns the right values
      // FIXME: The order of the methods is relevant in the current implementation.
      const regexTestPreprocessOutput = secondSwitch ? /test.*return\s+(\w+,\s*\w+|ds)\s*\n(.+?(?=test_postprocess))/s : /test.*return\s+(\w+,\s*\w+|ds)\s*/s;
      if (!regexTestPreprocessOutput.test(value)) message += 'Method test_preprocess should return X and y.\n';
    }
    if (secondSwitch) {
      // Ensure test_postprocess takes one parameter if third switch is not enabled
      const regexPostprocessInput = /def test_postprocess\(\w+\):/;
      if (!regexPostprocessInput.test(value) && !thirdSwitch) message += 'Test postprocessing input should be similar to "test_postprocess(y):".\n';
      // Ensure test_postprocess takes two parameters if third switch is enabled
      const regexPostprocessInputS = /def test_postprocess\(\w+, \w+\):/;
      if (!regexPostprocessInputS.test(value) && thirdSwitch) message += 'Test postprocessing input should be similar to "test_postprocess(y, processor_serialization):".\n';
      // Ensure test_postprocess returns only y or Y
      const regexPostprocessOutput = /return [yY]|ds/;
      if (!regexPostprocessOutput.test(value)) message += 'In test postprocessing, "return y" is missing.\n';
    }

    this.setState({ invalidProcessingMessage: message });
    if (message === '') {
      this.setState({ validProcessing: true });
    } else {
      this.setState({ validProcessing: false });
    }
    onChange(this.state);
  }

  validateForm() {
    const { processordefinition, validProcessing } = this.state;
    const { description, script } = processordefinition;
    const { validated } = this.props;
    if (description && script && validProcessing) {
      validated(true);
    } else {
      validated(false);
    }
  }

  render() {
    const {
      processordefinition,
      customizedProcessing,
      firstSwitch,
      secondSwitch,
      thirdSwitch,
      validProcessing,
      invalidProcessingMessage,
    } = this.state;
    const { description, script } = processordefinition;
    return (
      <>
        <div>
          <Tooltip title="Show dynamic examples for the different processing options. The example code can only be selected as long as the pre- and postprocessing script below is not edited individually.">
            <div style={{ marginBottom: '20px' }}>
              <Text>Processing Specifications:</Text>
              <div className="switch">
                <Switch
                  checked={firstSwitch}
                  size="small"
                  disabled={customizedProcessing}
                  onChange={(e) => this.onFirstSwitchChange(e)}
                />
                <span className="text"><Text>Separate preprocessing of train and test data</Text></span>
              </div>
              <div className="switch">
                <Switch
                  checked={secondSwitch}
                  size="small"
                  disabled={customizedProcessing}
                  onChange={(e) => this.onSecondSwitchChange(e)}
                />
                <span className="text"><Text>Postprocess prediction</Text></span>
              </div>
              <div className="switch">
                <Switch
                  checked={thirdSwitch}
                  size="small"
                  disabled={customizedProcessing || (!firstSwitch && !secondSwitch)}
                  onChange={(e) => this.onThirdSwitchChange(e)}
                />
                <span className="text"><Text>Use information of (train) preprocessing for further processing steps</Text></span>
              </div>
              <div>
                {(!validProcessing)
                  ? (
                    <Text type="danger" style={{ whiteSpace: 'pre-line' }}>{invalidProcessingMessage}</Text>
                  )
                  : ''}
              </div>
            </div>
          </Tooltip>
          <Tooltip title="Enter the Python Script for Preprocessing here">
            <Text type="danger">* </Text>
            <Text>Pre- and Postprocessing Script:</Text>
            <CodeMirror
              value={script}
              options={{
                mode: 'python',
                theme: 'default',
                lineNumbers: true,
              }}
              onChange={(editor, data, value) => {
                this.currentPosition = editor.getCursor();
                this.onScriptChange(value);
                editor.setCursor(this.currentPosition);
              }}
            />
            <Upload
              onChange={(e) => this.onScriptUpload(e)}
              name="file"
              multiple={false}
              showUploadList={false}
            >
              <Button>
                <UploadOutlined />
                Click to Upload
              </Button>
            </Upload>
          </Tooltip>
          <Tooltip title="Reset your customized Python Script to selected default">
            <Popconfirm
              placement="right"
              title={'Reset script to the selected default? \n Your customized preprocessing script will be deleted.'}
              okText="Yes"
              cancelText="No"
              onConfirm={() => this.onReset()}
              disabled={!customizedProcessing}
            >
              <Button
                danger
                disabled={!customizedProcessing}
              >
                Reset
              </Button>
            </Popconfirm>
          </Tooltip>
        </div>
        <div className="newtaskinputdescription">
          <Tooltip title="Enter the Description here">
            <Text type="danger">* </Text>
            <Text>Description:</Text>
            <TextArea
              value={description}
              onChange={(e) => this.onDescriptionChange(e)}
              placeholder="Description of the Dataprocessor"
              autoSize={{ minRows: 2, maxRows: 5 }}
            />
          </Tooltip>
        </div>
      </>
    );
  }
}
export default ProcessorUpload;

ProcessorUpload.propTypes = {
  onChange: PropTypes.func,
  validated: PropTypes.func,
  processordefinition: PropTypes.shape({
    description: PropTypes.string,
    script: PropTypes.string,
  }),
  firstSwitch: PropTypes.bool.isRequired,
  secondSwitch: PropTypes.bool.isRequired,
  thirdSwitch: PropTypes.bool.isRequired,
  customizedProcessing: PropTypes.bool.isRequired,
  validProcessing: PropTypes.bool,
  invalidProcessingMessage: PropTypes.string,
};
ProcessorUpload.defaultProps = {
  validProcessing: false.valueOf,
  invalidProcessingMessage: '',
  onChange: () => {},
  validated: () => {},
  processordefinition: { description: '', script: '' },
};

import React from 'react';
import PropTypes from 'prop-types';
import {
  Form, Input, Modal, message, Select, Upload, Button,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { postDataSchema } from '../../../util/ApiCalls';
import './NewDataSchemaModal.css';

export default function NewDataSchemaModal(props) {
  const { Option } = Select;
  const { TextArea } = Input;
  const {
    visible, hideModal, refresh,
  } = props;
  const [form] = Form.useForm();
  const onFinish = async () => {
    hideModal();
    try {
      let definition = form.getFieldValue('definition');
      try {
        definition = JSON.parse(definition);
      } catch (e) {
        message.warning('Definition is not a valid JSON!');
        console.log(e);
        return;
      }
      const datatype = form.getFieldValue('datatype');
      const name = form.getFieldValue('name');
      const description = form.getFieldValue('description');
      await postDataSchema(definition, datatype, name, description);
      refresh();
      message.success(`Created new DataSchema ${name}`);
    } catch (e) {
      message.warning(e.message);
      console.log(e);
    }
  };

  const onDefinitionUpload = async ({ file: { status, originFileObj } }) => {
    if (status !== 'uploading') {
      const reader = new FileReader();
      reader.onload = ({ target: { result } }) => {
        form.setFieldsValue({ definition: result });
      };
      reader.readAsText(originFileObj);
    }
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  return (
    <Modal
      title="Create a new Dataschema"
      visible={visible}
      onOk={() => {
        form.submit();
      }}
      onCancel={hideModal}
    >
      <Form
        form={form}
        {...layout}
        onFinish={onFinish}
      >
        <Form.Item
          label="Datatype"
          name="datatype"
          rules={[
            {
              required: true,
              message: 'Please select the data type!',
            },
          ]}
        >
          <Select
            placeholder="Select a data type"
          >
            <Option value="ts">time series</Option>
            <Option value="sd">standard</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input a name!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: 'Please add a description!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Definition"
          name="definition"
          rules={[
            {
              required: true,
              message: 'Please add the schema definition!',
            },
          ]}
        >
          <TextArea autoSize={{ minRows: 5, maxRows: 10 }} />
        </Form.Item>
        <Upload
          name="file"
          multiple={false}
          showUploadList={false}
          onChange={(e) => onDefinitionUpload(e)}
        >
          <Button>
            <UploadOutlined />
            Click to Upload
          </Button>
        </Upload>
      </Form>
    </Modal>
  );
}

NewDataSchemaModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  refresh: PropTypes.func.isRequired,
};

import taskTypes from './TaskDetails';

export const lossInformation = [
  {
    taskTypes: [taskTypes.MULTICLASSCLASSIFICATION, taskTypes.BINARYCLASSIFICATION],
    type: 'categorical_crossentropy',
    description: 'Categorical Crossentropy (Loss)',
  },
  {
    taskTypes: [taskTypes.MULTICLASSCLASSIFICATION, taskTypes.BINARYCLASSIFICATION],
    type: 'sparse_categorical_crossentropy',
    description: 'Sparse Categorical Crossentropy (Loss)',
  },
  {
    taskTypes: [taskTypes.BINARYCLASSIFICATION],
    type: 'binary_crossentropy',
    description: 'Binary Crossentropy (Loss)',
  },
  {
    taskTypes: [taskTypes.REGRESSION],
    type: 'mean_absolute_error',
    description: 'Mean Absolute Error (Loss)',
  },
  {
    taskTypes: [taskTypes.REGRESSION],
    type: 'mean_squared_error',
    description: 'Mean Squared Error (Loss)',
  },
  {
    taskTypes: [taskTypes.REGRESSION],
    type: 'mean_absolute_percentage_error',
    description: 'Mean Absolute Percentage Error (Loss)',
  },
  {
    taskTypes: [taskTypes.SEGMENTATION],
    type: 'binary_focal_loss',
    description: 'Binary Focal Loss',
  },
];

export function lossDetails(loss) {
  const expandedLoss = lossInformation
    .find((element) => element.type === loss);
  return expandedLoss;
}

export function lossDetailsByType(taskType) {
  const lossByType = lossInformation
    .filter((loss) => (loss.taskTypes.includes(taskType)));
  return lossByType;
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Button,
  Popconfirm,
} from 'antd';
import {
  PlusCircleOutlined,
  QuestionCircleOutlined,
  DeleteOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';
import NewRunModal from './NewRunModal/NewRunModal';
import RunDetailsModal from './RunDetailsModal/RunDetailsModal';
import { cancelRun, deleteRun, resumeRun } from '../../util/ApiCalls';

class RunToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newRunModalVisible: false,
      runDetailsModalVisible: false,
    };
  }

  showNewRunModal() {
    this.setState({
      newRunModalVisible: true,
    });
  }

  hideNewRunModal() {
    this.setState({
      newRunModalVisible: false,
    });
  }

  showRunDetailsModal() {
    this.setState({
      runDetailsModalVisible: true,
    });
  }

  hideRunDetailsModal() {
    this.setState({
      runDetailsModalVisible: false,
    });
  }

  render() {
    const { selectedRun, refresh } = this.props;
    let run = null;
    let isRunning = false;
    let isScheduled = false;
    if (selectedRun) {
      run = selectedRun.run;
      isRunning = (selectedRun.status === 'running');
      isScheduled = (selectedRun.status === 'scheduled');
    }
    const { newRunModalVisible, runDetailsModalVisible } = this.state;
    const onCancel = async () => {
      try {
        await cancelRun(run);
      } catch (e) {
        console.log(e);
      }
      refresh();
    };
    const onResume = async () => {
      try {
        await resumeRun(run);
      } catch (e) {
        console.log(e);
      }
      refresh();
    };
    const onDelete = async () => {
      try {
        await deleteRun(run);
      } catch (e) {
        console.log(e);
      }
      refresh();
    };

    return (
      <Row gutter={[16, 16]}>
        <Col>
          <Button type="primary" onClick={() => this.showNewRunModal()}>
            <PlusCircleOutlined />
            Start a new Run
          </Button>
        </Col>
        <Col>
          <Button disabled={!run} onClick={() => this.showRunDetailsModal()}>
            <QuestionCircleOutlined />
            Details
          </Button>
        </Col>
        <Col>
          {run && (selectedRun.status === 'stopped' || selectedRun.status === 'failed')
            ? (
              <Popconfirm
                title="Are you sure you want to resume the Run？"
                okText="Yes"
                cancelText="No"
                onConfirm={() => onResume()}
              >
                <Button type="primary">
                  <PlayCircleOutlined />
                  Resume
                </Button>
              </Popconfirm>
            )
            : (
              <Button type="primary" disabled>
                <PlayCircleOutlined />
                Resume
              </Button>
            )}
        </Col>
        <Col>
          {run && selectedRun.run_type === 'fml' && (isRunning || isScheduled)
            ? (
              <Popconfirm
                title="Are you sure you want to pause the Run？"
                okText="Yes"
                cancelText="No"
                onConfirm={() => onCancel()}
              >
                <Button type="primary" danger>
                  <PauseCircleOutlined />
                  Pause
                </Button>
              </Popconfirm>
            )
            : (
              <Button type="primary" danger disabled>
                <PauseCircleOutlined />
                Pause
              </Button>
            )}
        </Col>
        <Col>
          {run
            ? (
              <Popconfirm
                title="Are you sure you want to Delete the Run？"
                okText="Yes"
                cancelText="No"
                onConfirm={() => onDelete()}
              >
                <Button danger>
                  <DeleteOutlined />
                  Delete
                </Button>
              </Popconfirm>
            )
            : (
              <Button danger disabled>
                <DeleteOutlined />
                Delete
              </Button>
            )}
        </Col>
        <NewRunModal
          visible={newRunModalVisible}
          hideModal={() => this.hideNewRunModal()}
          refresh={() => refresh()}
        />
        <RunDetailsModal
          selectedRun={selectedRun}
          visible={runDetailsModalVisible}
          hideModal={() => this.hideRunDetailsModal()}
        />
      </Row>
    );
  }
}

export default RunToolbar;

RunToolbar.propTypes = {
  selectedRun: PropTypes.shape({
    run: PropTypes.string,
    status: PropTypes.string,
    run_type: PropTypes.string,
  }),
  refresh: PropTypes.func,
};

RunToolbar.defaultProps = {
  refresh: () => {},
  selectedRun: PropTypes.shape({
    run: null,
    status: null,
    run_type: null,
  }),
};

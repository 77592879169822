import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Table, Input, Space, Button,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import './DataTable.css';

class DataTable extends Component {
  constructor(props) {
    super(props);
    this.expandColumnProps = this.expandColumnProps.bind(this);
    this.getColumnSearchProps = this.getColumnSearchProps.bind(this);
  }

  getColumnSearchProps(dataIndex, title) {
    return {
      filterDropdown: ({
        setSelectedKeys, selectedKeys, confirm, clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              this.searchInput = node;
            }}
            placeholder={`Search ${title}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) => (record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : ''),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => this.searchInput.select(), 100);
        }
      },
    };
  }

  expandColumnProps({
    className, dataIndex, title, key, children, fixed, sorter, defaultSortOrder,
    filters, onFilter, render, responsive, disableSearch,
  }) {
    let column = {
      className,
      dataIndex,
      title,
      key,
      children,
      fixed,
      sorter,
      defaultSortOrder,
      filters,
      onFilter,
      render,
      responsive,
    };
    if (!disableSearch) {
      column = {
        ...column,
        ...(filters ? { filters, onFilter } : this.getColumnSearchProps(dataIndex, title)),
      };
    }
    return column;
  }

  render() {
    const {
      size, scroll, className, tableLayout, rowSelection, sortDirections,
      pagination, dataSource, columns,
    } = this.props;
    return (
      <Table
        scroll={scroll}
        size={size}
        className={className}
        tableLayout={tableLayout}
        columns={columns.map(this.expandColumnProps)}
        sortDirections={sortDirections}
        dataSource={dataSource}
        pagination={pagination}
        rowSelection={rowSelection}
      />
    );
  }
}

export default DataTable;

DataTable.propTypes = {
  scroll: PropTypes.shape({}),
  size: PropTypes.string,
  className: PropTypes.string,
  tableLayout: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataIndex: PropTypes.oneOfType(
        [
          PropTypes.string,
          PropTypes.arrayOf(PropTypes.string),
        ],
      ).isRequired,
      title: PropTypes.oneOfType(
        [PropTypes.string, PropTypes.func],
      ).isRequired,
    }),
  ).isRequired,
  sortDirections: PropTypes.arrayOf(
    PropTypes.string,
  ),
  dataSource: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pagination: PropTypes.shape({
    defaultPageSize: PropTypes.number,
  }),
  rowSelection: PropTypes.shape({
    type: PropTypes.string,
    onChange: PropTypes.func,
  }),
};

DataTable.defaultProps = {
  scroll: { x: 'max-content' },
  size: 'small',
  className: '',
  tableLayout: 'auto',
  sortDirections: ['ascend', 'descend'],
  pagination: { defaultPageSize: 5 },
  rowSelection: undefined,
};

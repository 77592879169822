import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DataTable from '../TableTemplates/DataTable';
import { getDataSchemas } from '../../util/ApiCalls';

/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

class DataSchemaTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSchema: [],
    };
  }

  async componentDidMount() {
    await this.updateInfo();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.updateInfo();
    }
  }

  async updateInfo() {
    try {
      let dataSchema = await getDataSchemas();
      dataSchema = dataSchema.map((schema) => ({
        key: schema._id,
        ...schema,
        data_type: schema.data_type === 'ts' ? 'time series' : 'standard',
        createdAt: new Date(schema.createdAt).toLocaleString('de-DE'),
        date: schema.createdAt,
      }));
      this.setState({ dataSchema });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { rowSelection } = this.props;
    const { dataSchema } = this.state;
    const columns = [
      {
        title: 'Schema',
        dataIndex: 'name',
        fixed: 'left',
      },
      {
        title: 'Description',
        dataIndex: 'description',
      },
      {
        title: 'Data Type',
        dataIndex: 'data_type',
        filters: [
          {
            text: 'Standard',
            value: 'standard',
          },
          {
            text: 'Time Series',
            value: 'time series',
          },
        ],
        onFilter: (value, record) => record.data_type.indexOf(value) === 0,
      },
      {
        title: 'Creation Date',
        dataIndex: 'createdAt',
        sorter: (a, b) => new Date(a.date) - new Date(b.date),
        defaultSortOrder: 'descend',
      },
    ];

    return (
      <div>
        <DataTable
          rowSelection={rowSelection}
          columns={columns}
          dataSource={dataSchema}
        />
      </div>
    );
  }
}

export default DataSchemaTable;

DataSchemaTable.propTypes = {
  rowSelection: PropTypes.shape({
    type: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
};

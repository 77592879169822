import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import {
  TableOutlined,
  QuestionCircleOutlined,
  DatabaseOutlined,
  CodeOutlined,
  HomeOutlined,
  PlayCircleOutlined,
  AreaChartOutlined,
  SplitCellsOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

const { Sider } = Layout;
const { SubMenu } = Menu;

export default function Sidebar() {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Sider
      collapsible
      theme="dark"
      collapsed={collapsed}
      onCollapse={(e) => setCollapsed(e)}
    >
      <Menu mode="inline" theme="dark">
        <Menu.Item icon={<HomeOutlined />}>
          <Link to="/">
            Overview
          </Link>
        </Menu.Item>
        <Menu.Item icon={<PlayCircleOutlined />}>
          <Link to="/mlruns">
            ML Runs
          </Link>
        </Menu.Item>
        <Menu.Item icon={<AreaChartOutlined />}>
          <Link to="/reporting">
            Reporting
          </Link>
        </Menu.Item>
        <Menu.Item icon={<CodeOutlined />}>
          <Link to="/mltasks">
            ML Tasks
          </Link>
        </Menu.Item>
        <SubMenu title={(
          <>
            <DatabaseOutlined />
            <span>Data</span>
          </>
          )}
        >
          <Menu.Item icon={<TableOutlined />}>
            <Link to="/dataschemas">
              Data Schemas
            </Link>
          </Menu.Item>
          <Menu.Item icon={<SplitCellsOutlined />}>
            <Link to="/datasplits">
              Data Splits
            </Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item icon={<QuestionCircleOutlined />}>
          {/* this lint error is fixed through the rel="noopener" attribute */}
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a href="https://www.user.doc.prenode.io" target="_blank" rel="noopener">User Documentation </a>
        </Menu.Item>
      </Menu>
    </Sider>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Form,
  message,
} from 'antd';
import SetNameItem from '../../FormItems/SetNameItem';
import SelectLeavesItem from '../../FormItems/SelectLeavesItem';
import SelectDataSplitItem from '../../FormItems/SelectDataSplitItem';
import { postEvaluationJob } from '../../../util/ApiCalls';

export default function NewEvaluationJobModal(props) {
  const {
    visible, hideModal, modelInfo,
  } = props;
  const [form] = Form.useForm();

  const onFinish = async () => {
    const leaves = form.getFieldValue('leaves');
    if (leaves === undefined) {
      alert('Please specify at least one Leaf to start a new Evaluation Job.');
      return;
    }
    hideModal();
    try {
      await postEvaluationJob(
        form.getFieldValue('name'),
        form.getFieldValue('leaves').map((leaf) => leaf.id),
        form.getFieldValue('datasplits')[0].key,
        modelInfo.model_id,
        modelInfo.task_id,
      );

      message.success('New evaluation job started!');
    } catch (e) {
      if (e.message === 'Conflict') {
        message.warning('Could not start new Evaluation Job, because there is already another Evaluation Job or Run in Progress!');
      } else if (e.message === 'MissingDataSequence') {
        message.warning('Select a split which is defined for all participating leaves!');
      } else {
        message.warning(e.message);
      }
      console.log(e);
    }
  };
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <Modal
      title="Start a new Evaluation Job"
      visible={visible}
      width={1040}
      onOk={() => {
        form.submit();
      }}
      onCancel={hideModal}
    >
      <Form
        form={form}
        {...layout}
        onFinish={onFinish}
      >
        <SetNameItem
          formName="Evaluation Job"
        />
        <SelectLeavesItem
          form={form}
          title="Select the leaves which should participate in the evaluation job."
          ruleMessage="Please select at least one leaf as participant!"
        />
        <SelectDataSplitItem
          form={form}
        />
      </Form>
    </Modal>
  );
}

NewEvaluationJobModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  modelInfo: PropTypes.shape({
    model_id: PropTypes.string.isRequired,
    task_id: PropTypes.string.isRequired,
  }),
};

NewEvaluationJobModal.defaultProps = {
  modelInfo: {},
};

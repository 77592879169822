import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SliderArrow } from './images/sliderArrow.svg';
import './TagSection.css';

const TagSection = ({
  name, color, width, onSliderSelect,
}) => (
  <div
    className="tag"
    style={{
      background: color,
      width: `${width}%`,
    }}
  >
    <span className="tagtext">{name}</span>
    <span className="tagtext" style={{ fontSize: 12 }}>{`${width}%`}</span>
    <div
      onPointerDown={onSliderSelect}
      className="sliderbutton"
    >
      <SliderArrow style={{ height: '50%', width: '50%' }} />
    </div>
  </div>
);

export default TagSection;

TagSection.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  onSliderSelect: PropTypes.func.isRequired,
};

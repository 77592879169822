/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Form,
  InputNumber,
  Input,
  message,
} from 'antd';
import { postRegister } from '../../../util/ApiCalls';

export default function RegisterModal(props) {
  const {
    visible,
    onOk,
    onCancel,
    onRegister,
  } = props;
  const [form] = Form.useForm();
  const onFinish = async () => {
    try {
      await postRegister(
        form.getFieldValue('id'),
        form.getFieldValue('name'),
        [form.getFieldValue('coordinates1'), form.getFieldValue('coordinates2')],
        form.getFieldValue('address'),
        form.getFieldValue('description'),
      );
      onRegister();
    } catch (e) {
      if (e.message === 'Conflict') {
        message.warning('Leaf is already registered!');
      } else {
        console.log(e);
        onRegister();
      }
    }
  };
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <Modal
      title="Register New Leaf"
      visible={visible}
      onOk={(e) => {
        form.submit();
        onOk(e);
      }}
      onCancel={onCancel}
    >
      <Form
        form={form}
        {...layout}
        onFinish={onFinish}
        initialValues={{
          coordinates1: 49.0069,
          coordinates2: 8.4037,
        }}
      >
        <Form.Item
          label="ID"
          name="id"
          required
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="name"
          label="Name"
          required
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="coordinates1"
          label="Longitude"
          style={{ display: 'none' }}
          required
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="coordinates2"
          label="Latitude"
          style={{ display: 'none' }}
          required
        >
          <InputNumber />
        </Form.Item>

        <Form.Item
          name="address"
          label="Address"
          required
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          required
        >
          <Input />
        </Form.Item>

      </Form>
    </Modal>
  );
}

RegisterModal.propTypes = {
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRegister: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
} from 'antd';
import HiddenOptionsSelector from '../HiddenOptionsSelector/HiddenOptionsSelector';
import CSVDownloadButton from '../CSVDownloadButton/CSVDownloadButton';
import { metricDetails, metricsDetails } from '../../util/MetricsDetails';
import { aggregationsInformation, aggregationDetails, aggregationsDetails } from '../../util/AggregationsDetails';

const AggregationSelector = HiddenOptionsSelector;
const MetricSelector = HiddenOptionsSelector;

function ReportingDetailsToolbar(props) {
  const {
    selectedRuns,
    selectedDataSchema,
    selectAggregations,
    selectMetrics,
    aggData,
  } = props;

  const [CSVHeaders, setCSVHeaders] = useState([]);
  const [CSVData, setCSVData] = useState([]);
  const [metricInputOptions, setMetricInputOptions] = useState([]);
  const [aggregationInputOptions, setAggregationInputOptions] = useState([]);
  const [selectedMetrics, setSelectedMetrics] = useState([]);
  const [selectedAggregation, setSelectedAggregation] = useState([]);

  useEffect(() => {
    const participantMetricHeaders = [];
    aggData.forEach((run) => {
      run.transformed_comparison.forEach((participant) => {
        Object.keys(participant.metrics).forEach((metric) => {
          if (!participantMetricHeaders.some((header) => header.key === `${metric}`)) {
            participantMetricHeaders.push(
              { label: `${metricDetails(metric).family}`, key: `${metric}` },
            );
          }
        });
      });
    });

    const csvHeaders = [
      { label: 'Run Name', key: 'name' },
      { label: 'Aggregation', key: 'aggregation' },
      { label: 'Participant', key: 'participant' },
      ...participantMetricHeaders,
    ];
    setCSVHeaders(csvHeaders);
  }, [aggData]);

  useEffect(() => {
    const csvData = [];
    aggData.forEach((run) => {
      run.transformed_comparison.forEach((participant) => {
        let csvRow = {
          name: run.run_name,
          aggregation: run.aggregation !== '-' ? aggregationDetails(run.aggregation).description : '-',
          participant: participant.leaf_id,
        };
        Object.entries(participant.metrics).forEach((metric) => {
          const key = `${metric[0]}`;
          csvRow = {
            ...csvRow,
            [key]: metric[1],
          };
        });
        csvData.push(csvRow);
      });
    });
    setCSVData(csvData);
  }, [aggData]);

  const onMetricSelection = (childData) => {
    const expandedChildData = metricsDetails(childData);
    const sortByMetrics = (metricA, metricB) => metricA.type < metricB.type;
    setSelectedMetrics(expandedChildData.sort(sortByMetrics));
  };
  const onAggregationSelection = (childData) => {
    const expandedChildData = aggregationsDetails(childData);
    setSelectedAggregation(expandedChildData);
  };

  const onMetricInputOptionsUpdate = () => {
    const metricsTypes = [];
    selectedRuns.forEach((run) => {
      run.task_metrics.forEach((metric) => {
        if (!metricsTypes.includes(metric)) {
          metricsTypes.push(metric);
        }
      });
    });
    const detailedMetrics = metricsDetails(metricsTypes);
    const inputOptions = [];
    detailedMetrics.forEach((metric) => {
      inputOptions.push({
        label: metric.family,
        value: metric.type,
      });
    });
    if (Array.isArray(selectedMetrics) && !selectedMetrics.length) {
      setSelectedMetrics(detailedMetrics);
    }
    setMetricInputOptions(inputOptions);
  };

  const onAggregationInputOptionsUpdate = () => {
    const inputOptions = [];
    if (Array.isArray(selectedRuns) && selectedRuns.length) {
      aggregationsInformation.forEach((aggregation) => {
        inputOptions.push({
          label: aggregation.description,
          value: aggregation.type,
        });
      });
    }
    if (Array.isArray(selectedAggregation) && !selectedAggregation.length) {
      setSelectedAggregation(aggregationsInformation);
    }
    setAggregationInputOptions(inputOptions);
  };

  useEffect(onMetricInputOptionsUpdate, [selectedRuns, selectedDataSchema]);
  useEffect(onAggregationInputOptionsUpdate, [selectedRuns]);

  useEffect(() => {
    selectAggregations(selectedAggregation);
    selectMetrics(selectedMetrics);
  }, [selectedAggregation, selectedMetrics, selectMetrics, selectAggregations]);

  return (
    <Row gutter={[16, 16]}>
      <Col style={{ position: 'relative', top: '4px' }}>Metric:</Col>
      <Col>
        <MetricSelector
          style={{ width: '460px' }}
          inputOptions={metricInputOptions}
          placeholder="Select a Metric"
          width="460px"
          onChange={onMetricSelection}
        />
      </Col>
      <Col style={{ position: 'relative', top: '4px' }}>Aggregation:</Col>
      <Col>
        <AggregationSelector
          style={{ width: '200px' }}
          inputOptions={aggregationInputOptions}
          placeholder="Select an Aggregator"
          onChange={onAggregationSelection}
        />
      </Col>
      <Col>
        <CSVDownloadButton
          headers={CSVHeaders}
          data={CSVData}
          filename="AggregatedRunReport.csv"
          buttonDescription="Export Aggregated Results"
        />
      </Col>
    </Row>
  );
}

export default ReportingDetailsToolbar;

ReportingDetailsToolbar.propTypes = {
  aggData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedRuns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedDataSchema: PropTypes.string,
  selectMetrics: PropTypes.func.isRequired,
  selectAggregations: PropTypes.func.isRequired,
};

ReportingDetailsToolbar.defaultProps = {
  selectedDataSchema: null,
};

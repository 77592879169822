/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Select,
  Tooltip,
} from 'antd';
import { getDataSchemas } from '../../util/ApiCalls';
import CSVDownloadButton from '../CSVDownloadButton/CSVDownloadButton';

class ReportingToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSchemas: [],
      selectedDataSchema: '',
    };
  }

  async componentDidMount() {
    await this.updateInfo();
  }

  async onSelectDataSchema(dataSchema) {
    const { onChange } = this.props;
    await this.setState({ selectedDataSchema: dataSchema });
    onChange(this.state);
  }

  transformCSVData() {
    const csvData = [];
    const { reportData } = this.props;
    reportData.forEach((element) => {
      if (Object.keys(element.params).length !== 0) {
        const expandedObj = {
          epochs: element.params.epochs,
          optimizerType: element.params.optimizer.type,
          learningRate: element.params.optimizer.learning_rate,
          batchSize: element.params.batch_size,
          lossType: element.params.loss.type,
        };
        csvData.push({ ...element, ...expandedObj });
      } else {
        csvData.push(element);
      }
    });
    return csvData;
  }

  async updateInfo() {
    try {
      const fetchedDataSchemas = await getDataSchemas();
      this.setState({ dataSchemas: fetchedDataSchemas });
      // eslint-disable-next-line no-underscore-dangle
      this.onSelectDataSchema(fetchedDataSchemas[0]._id);
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { dataSchemas, selectedDataSchema } = this.state;
    const csvData = this.transformCSVData();
    const csvHeaders = [
      { label: 'Run Name', key: 'name' },
      { label: 'Type', key: 'type' },
      { label: 'Data Split', key: 'data_split_name' },
      { label: 'Data Schema', key: 'data_schema_name' },
      { label: 'Task', key: 'task_name' },
      { label: 'Model Type', key: 'model_type' },
      { label: 'Participants', key: 'participants' },
      { label: 'Iterations', key: 'iterations' },
      { label: 'Metrics', key: 'task_metrics' },
      { label: 'Epochs', key: 'epochs' },
      { label: 'Optimizer', key: 'optimizerType' },
      { label: 'Learning Rate', key: 'learningRate' },
      { label: 'Batch Size', key: 'batchSize' },
      { label: 'Primary Metric', key: 'primary_metric' },
      { label: 'Loss', key: 'lossType' },
    ];

    return (
      <Row gutter={[16, 16]}>
        <Col style={{ position: 'relative', top: '4px' }}>
          Data Schema:
        </Col>
        <Col>
          <Tooltip title="Filter runs by data schema.">
            <Select
              style={{ width: 300 }}
              placeholder="Select a Data Schema"
              value={selectedDataSchema}
              onChange={(e) => this.onSelectDataSchema(e)}
              allowClear
            >
              {dataSchemas.map((dataSchema) => (
                // eslint-disable-next-line no-underscore-dangle
                <Select.Option key={dataSchema._id} value={dataSchema._id} label={dataSchema.name}>
                  {dataSchema.name}
                </Select.Option>
              ))}
            </Select>
          </Tooltip>
        </Col>
        <Col>
          <CSVDownloadButton
            headers={csvHeaders}
            data={csvData}
            filename="RunReport.csv"
            buttonDescription="Export Raw Data"
          />
        </Col>
      </Row>
    );
  }
}

export default ReportingToolbar;

ReportingToolbar.propTypes = {
  onChange: PropTypes.func,
  reportData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

ReportingToolbar.defaultProps = {
  // refresh: () => {},
  onChange: () => { },
};

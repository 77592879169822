import React, { useState } from 'react';
import { Row, Col } from 'antd';
import LeafMenu from '../components/LeafMenu/LeafMenu';
import NotificationMenu from '../components/NotificationMenu/NotificationMenu';
import MultiLeafMap from '../components/Map/MultiLeafMap';
import OverviewPageHeader from '../components/PageHeader/OverviewPageHeader';

export default function Overview() {
  const [value, setValue] = useState(0); // integer state
  /* eslint-disable-next-line */
  const forceUpdate = () => setValue((value) => value + 1);
  return (
    <div>
      <Row>
        <Col span={24}><OverviewPageHeader title="Demo Case" /></Col>
      </Row>

      <Row>
        <Col span={12}>
          <Row style={{ height: '45vh' }}>
            <Col span={24}>
              <MultiLeafMap dummy={value} />
            </Col>
          </Row>
          <Row style={{ height: '50%' }}>
            <Col span={24}>
              <NotificationMenu dummy={value} />
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row style={{ height: '100%' }}>
            <Col span={24}>
              <LeafMenu
                onDeregister={forceUpdate}
                onRegister={forceUpdate}
                dummy={value}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { Map as LeafletMap, TileLayer } from 'react-leaflet';

export default function Map(props) {
  const { center, children } = props;
  return (
    <>
      <link rel="stylesheet" href="//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/leaflet.css" />
      <LeafletMap
        style={{
          minHeight: '100%',
        }}
        center={center}
        zoom={6}
        maxZoom={10}
        attributionControl
        zoomControl
        doubleClickZoom
        scrollWheelZoom
        dragging
        animate
        easeLinearity={0.35}
      >
        <TileLayer url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" />
        {children}
      </LeafletMap>
    </>
  );
}
Map.defaultProps = {
  center: [50, 10],
};
Map.propTypes = {
  center: PropTypes.arrayOf(PropTypes.number),
  children: PropTypes.node.isRequired,
};

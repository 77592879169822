import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Map from './Map';
import LeafMarker from './LeafMarker/LeafMarker';
import { getInfo } from '../../util/ApiCalls';

class SingleLeafMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: {
        // Initialize with rough coordiantes of Germany
        coordinates: [49, 9],
        name: '',
      },
    };
  }

  componentDidMount() {
    const { id } = this.props;
    try {
      getInfo(id).then((data) => {
        if (data) {
          this.setState({ status: data });
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { status } = this.state;
    const { name, coordinates } = status;
    return (
      <Map>
        <LeafMarker
          key={name}
          position={coordinates}
          name={name}
          center={coordinates}
        />
      </Map>
    );
  }
}

SingleLeafMap.propTypes = {
  id: PropTypes.node.isRequired,
};

export default SingleLeafMap;

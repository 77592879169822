import React, { Component } from 'react';
import PropTypes from 'prop-types';

export const DataSplitsContext = React.createContext();

class DataSplitsContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSplit: null,
    };
  }

  render() {
    const { children } = this.props;
    const { selectedSplit } = this.state;
    return (
      <DataSplitsContext.Provider
        value={
          {
            state: { selectedSplit },
            changeSplit: (split) => {
              this.setState({ selectedSplit: split });
            },
          }
        }
      >
        {children}
      </DataSplitsContext.Provider>
    );
  }
}

export default DataSplitsContextProvider;

DataSplitsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DataTable from '../TableTemplates/DataTable';
import { getTasks } from '../../util/ApiCalls';

/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

class TaskTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tasks: [],
    };
  }

  async componentDidMount() {
    await this.updateInfo();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.updateInfo();
    }
  }

  async updateInfo() {
    try {
      let tasks = await getTasks();
      tasks = tasks.map((task) => ({
        key: task._id,
        ...task,
        createdAt: new Date(task.createdAt).toLocaleString('de-DE'),
        date: task.createdAt,
      }));
      this.setState({ tasks });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { rowSelection } = this.props;
    const { tasks } = this.state;
    const columns = [
      {
        title: 'ML Task',
        dataIndex: 'name',
        fixed: 'left',
      },
      {
        title: 'Description',
        dataIndex: 'description',
      },
      {
        title: 'Creation Date',
        dataIndex: 'createdAt',
        sorter: (a, b) => new Date(a.date) - new Date(b.date),
        defaulSortOrder: 'descend',
      },
    ];

    return (
      <div>
        <DataTable
          rowSelection={rowSelection}
          columns={columns}
          dataSource={tasks}
        />
      </div>
    );
  }
}

export default TaskTable;

TaskTable.propTypes = {
  rowSelection: PropTypes.shape({
    type: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
};

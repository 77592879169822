import React from 'react';
import PropTypes from 'prop-types';
import { Fragment } from 'react/cjs/react.production.min';
import {
  Radio,
  Form,
  InputNumber,
  Tooltip,
} from 'antd';
import SelectPrimaryMetricItem from '../../../FormItems/SelectPrimaryMetricItem';
import SetNameItem from '../../../FormItems/SetNameItem';
import SelectLeavesItem from '../../../FormItems/SelectLeavesItem';
import SelectTaskItem from '../../../FormItems/SelectTaskItem';
import SelectDataSplitItem from '../../../FormItems/SelectDataSplitItem';
import { lossDetailsByType } from '../../../../util/LossDetails';

export default function RunDetailsSelection(props) {
  const {
    onTaskSelection,
    onOptimizerSelection,
    selectedMetrics,
    selectedModelType,
    selectedTaskType,
    selectedOptimizer,
    runType,
    form,
  } = props;

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };

  const layoutOptimizerParameters = {
    labelCol: { span: 8 },
  };

  const getIterations = () => {
    if (runType === 'fml' || runType === 'stl') {
      return (
        <Tooltip title="Select the number of iterations for the run.">
          <Form.Item
            name="iterations"
            label="Iterations"
            rules={[
              {
                type: 'integer',
                min: 1,
                required: true,
                message: 'Please input for the number of iterations an integer value greater than zero!',
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
        </Tooltip>
      );
    }
    return null;
  };

  const getOptimizerParams = () => {
    if (selectedOptimizer) {
      const params = (
        <Tooltip title="Select the learning rate for the model.">
          <Form.Item
            {...layoutOptimizerParameters}
            style={(selectedOptimizer !== 'adam') && { marginBottom: '5px' }}
            name="learningrate"
            label="Learning Rate"
            rules={[
              {
                type: 'number',
                min: 0,
                max: 1,
                required: true,
                message: 'Please input a value between 0 and 1 as learning rate!',
              },
            ]}
            message="0.01"
          >
            <InputNumber step={0.1} />
          </Form.Item>
        </Tooltip>
      );
      if (selectedOptimizer === 'dpadam') {
        return (
          <>
            {params}
            <Fragment>
              <Tooltip title="Select the clipping norm (max L2 norm of per microbatch gradients) for the optimizer of the model.">
                <Form.Item
                  {...layoutOptimizerParameters}
                  style={{ marginBottom: '5px' }}
                  name="l2normclip"
                  label="L2 Clipping Norm"
                  rules={[
                    {
                      type: 'number',
                      min: 0,
                      required: true,
                      message: 'Please input a value for the clipping norm!',
                    },
                  ]}
                  message="1.5"
                >
                  <InputNumber step={0.1} />
                </Form.Item>
              </Tooltip>
              <Tooltip title="Select the noise multiplier (ratio of the standard deviation to the clipping norm) for the optimizer of the model.">
                <Form.Item
                  {...layoutOptimizerParameters}
                  style={{ marginBottom: '5px' }}
                  name="noisemultiplier"
                  label="Noise Multiplier"
                  rules={[
                    {
                      type: 'number',
                      min: 0,
                      required: true,
                      message: 'Please input a value for the noise multiplier!',
                    },
                  ]}
                  message="1.3"
                >
                  <InputNumber step={0.1} />
                </Form.Item>
              </Tooltip>
              <Tooltip title="Select the number of microbatches (the number of microbatches into which each minibatch is split) for the optimizer of the model. This number should evenly divide your input batch size.">
                <Form.Item
                  style={{ display: 'none' }} // Won't display until we resolved the > 1 issue
                  {...layoutOptimizerParameters}
                  name="microbatches"
                  label="Number of Microbatches"
                  value={1}
                  rules={[
                    {
                      type: 'integer',
                      min: 1,
                      required: true,
                      message: 'Please input for the number of microbatches an integer value greater than zero!',
                    },
                  ]}
                  message="1"
                >
                  <InputNumber step={10} />
                </Form.Item>
              </Tooltip>
            </Fragment>
          </>
        );
      }
      return params;
    }
    return null;
  };

  const getKerasParams = () => {
    if (selectedModelType === 'keras') {
      return (
        <>
          <Tooltip title="Select the number of epochs the model should be trained in each iteration.">
            <Form.Item
              name="epochs"
              label="Epochs"
              rules={[
                {
                  type: 'integer',
                  min: 1,
                  required: true,
                  message: 'Please input for the number of epochs an integer value greater than zero!',
                },
              ]}
              message="5"
            >
              <InputNumber />
            </Form.Item>
          </Tooltip>
          <Tooltip title="Select an optimizer for the model.">
            <Form.Item
              style={selectedOptimizer && { marginBottom: '5px' }}
              name="optimizer"
              label="Optimizer"
              rules={[
                {
                  required: true,
                  message: 'Please select an optimizer!',
                },
              ]}
            >
              <Radio.Group onChange={(e) => onOptimizerSelection(e.target.value)}>
                <Radio value="adam">Adam</Radio>
                <br />
                <Radio value="sgd">SGD</Radio>
                <br />
                <Radio value="rmsprop">RMSprop</Radio>
                <br />
                <Radio value="dpadam">Differential Private Adam</Radio>
              </Radio.Group>
            </Form.Item>
          </Tooltip>
          {getOptimizerParams()}
          <Tooltip title="Select the batch size for the model.">
            <Form.Item
              name="batchsize"
              label="Batch Size"
              rules={[
                {
                  type: 'integer',
                  min: 1,
                  required: true,
                  message: 'Please input for the batch size an integer value greater than zero!',
                },
              ]}
              message="5"
            >
              <InputNumber />
            </Form.Item>
          </Tooltip>
          <SelectPrimaryMetricItem
            selectedMetrics={selectedMetrics}
          />
          <Tooltip title="Select a loss function for the model.">
            <Form.Item
              name="loss"
              label="Loss"
              rules={[
                {
                  required: true,
                  message: 'Please select a loss!',
                },
              ]}
            >
              <Radio.Group>
                {lossDetailsByType(selectedTaskType)
                  .map((loss) => (
                    <div key={loss.type}>
                      <Radio value={loss.type}>{loss.description}</Radio>
                    </div>
                  ))}
              </Radio.Group>
            </Form.Item>
          </Tooltip>
        </>
      );
    }
    return (
      <SelectPrimaryMetricItem
        selectedMetrics={selectedMetrics}
      />
    );
  };

  return (
    <Form
      form={form}
      {...layout}
      initialValues={{
        epochs: 5,
        iterations: 5,
        learningrate: 0.001,
        l2normclip: 0.2,
        noisemultiplier: 0.5,
        microbatches: 1,
        batchsize: 5,
      }}
    >
      <SetNameItem
        formName="Run"
      />
      <SelectLeavesItem
        form={form}
        title="Select the leaves which should participate in the ML run."
        ruleMessage="Please select leaves as participants!"
      />
      <SelectTaskItem
        form={form}
        onChange={onTaskSelection}
        title="Select the task which should be used for the ML run. A task consists of a model, processing and data schema. In this tier only tasks with keras models are supported for Federated ML."
        modelType={runType === 'fml' ? 'keras' : null}
      />
      {getIterations()}
      {getKerasParams()}
      <SelectDataSplitItem
        form={form}
      />
    </Form>
  );
}

RunDetailsSelection.propTypes = {
  onTaskSelection: PropTypes.func.isRequired,
  onOptimizerSelection: PropTypes.func.isRequired,
  selectedMetrics: PropTypes.oneOfType([PropTypes.array]),
  selectedModelType: PropTypes.string,
  selectedTaskType: PropTypes.string,
  selectedOptimizer: PropTypes.string,
  runType: PropTypes.string.isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

RunDetailsSelection.defaultProps = {
  selectedMetrics: [],
  selectedModelType: '',
  selectedTaskType: '',
  selectedOptimizer: '',
};

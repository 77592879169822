import React, { useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import Highlight from 'react-highlight';
import PropTypes from 'prop-types';
import {
  Modal, Descriptions, Card, List,
} from 'antd';
import { getProcessor, getDataSchema, getModelDefinition } from '../../../util/ApiCalls';
import { metricDetails } from '../../../util/MetricsDetails';

export default function TaskDetailsModal(props) {
  const {
    visible, onOk, onCancel, selectedTask,
  } = props;

  const [processorDetails, setProcessorDetails] = useState('');
  const [dataschemaDetails, setDataschemaDetails] = useState('');
  const [modelDetails, setModelDetails] = useState('');

  useEffect(() => {
    const getDetails = async (taskId, processorId, dataschemaId, modelId) => {
      let newProcessorDetails = {};
      let newDataschemaDetails = {};
      let newModelDetails = {};
      try {
        newProcessorDetails = await getProcessor(processorId);
        newDataschemaDetails = await getDataSchema(dataschemaId);
        newModelDetails = await getModelDefinition(modelId);
        setProcessorDetails(newProcessorDetails);
        setDataschemaDetails(newDataschemaDetails);
        setModelDetails(newModelDetails);
      } catch (e) {
        console.log(e);
      }
    };

    if (selectedTask) {
      getDetails(selectedTask.task, selectedTask.processor_definition_id,
        selectedTask.data_schema_id, selectedTask.model_definition_id);
    }
  }, [selectedTask]);

  return (
    <Modal
      // eslint-disable-next-line
      title={`Task ${selectedTask ? selectedTask.name : ''} - Details`}
      visible={visible}
      onOk={(e) => onOk(e)}
      width={1040}
      onCancel={onCancel}
    >
      <Descriptions bordered column={1}>
        <Descriptions.Item label="Task Name">{selectedTask ? selectedTask.name : ''}</Descriptions.Item>
        <Descriptions.Item label="Task Description">{selectedTask ? selectedTask.description : ''}</Descriptions.Item>
        <Descriptions.Item label="Task Type">{selectedTask ? selectedTask.type : ''}</Descriptions.Item>
        <Descriptions.Item label="Dataprocessor Script">{processorDetails ? <Highlight className="python">{processorDetails.script}</Highlight> : ''}</Descriptions.Item>
        <Descriptions.Item label="Dataprocessor Description">{processorDetails ? processorDetails.description : ''}</Descriptions.Item>
        <Descriptions.Item label="Dataschema Definiton">{dataschemaDetails ? <Card><ReactJson src={dataschemaDetails.definition} /></Card> : ''}</Descriptions.Item>
        <Descriptions.Item label="Model Type">{modelDetails ? modelDetails.type : ''}</Descriptions.Item>
        <Descriptions.Item label="Model Summary">{modelDetails ? <Highlight className="python">{modelDetails.summary}</Highlight> : ''}</Descriptions.Item>
        <Descriptions.Item label="Selected Metrics">
          <List
            size="small"
            dataSource={selectedTask
              ? selectedTask.metrics.map((entry) => metricDetails(entry.type).description)
              : []}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
}

TaskDetailsModal.propTypes = {
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  selectedTask: PropTypes.shape({
    metrics: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        type: PropTypes.string,
      }),
    ),
    task: PropTypes.string,
    _id: PropTypes.string,
    description: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    processor_definition_id: PropTypes.string,
    data_schema_id: PropTypes.string,
    model_definition_id: PropTypes.string,
  }),
};

TaskDetailsModal.defaultProps = {
  selectedTask: PropTypes.shape({
    task: '',
    _id: '',
    description: '',
    name: '',
    type: '',
    processor_definition_id: '',
    data_schema_id: '',
    model_definition_id: '',
    metrics: [],
  }),
};

import React from 'react';
import PropTypes from 'prop-types';
import { CheckOutlined } from '@ant-design/icons';
import DataTable from '../TableTemplates/DataTable';

/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

function ReportingTable(props) {
  const { rowSelection, reportData } = props;

  const adaptedReportData = reportData.map((data) => ({
    key: data.key,
    name: data.name ? data.name : '-',
    type: data.type ? data.type : '-',
    data_split_name: data.data_split_name ? data.data_split_name : '-',
    data_schema_name: data.data_schema_name ? data.data_schema_name : '-',
    task_name: data.task_name ? data.task_name : '-',
    model_type: data.model_type ? data.model_type : '-',
    participants: (data.participants && data.participants.length > 0)
      ? data.participants.reduce((item, accumulator) => `${accumulator}, ${item}`)
      : '-',
    iterations: data.iterations ? data.iterations : '-',
    task_metrics: data.task_metrics,
    _id: data._id,
    task_metrics_aggregated: (data.task_metrics && data.task_metrics.length > 0)
      ? data.task_metrics.reduce((item, accumulator) => `${accumulator}, ${item}`)
      : '-',
    params_epochs: data.params.epochs ? data.params.epochs : '-',
    params_optimizer_type: data.params.optimizer && data.params.optimizer.type ? data.params.optimizer.type : '-',
    params_dp_optimizer: (data.params.optimizer && data.params.optimizer.type === 'dpadam') ? <CheckOutlined /> : '-',
    params_optimizer_learning_rate: data.params.optimizer && data.params.optimizer.learning_rate ? data.params.optimizer.learning_rate : '-',
    params_optimizer_l2_norm_clip: data.params.optimizer && data.params.optimizer.l2_norm_clip ? data.params.optimizer.l2_norm_clip : '-',
    params_optimizer_noise_multiplier: data.params.optimizer && data.params.optimizer.noise_multiplier ? data.params.optimizer.noise_multiplier : '-',
    params_batch_size: data.params.batch_size ? data.params.batch_size : '-',
    primary_metric: data.primary_metric ? data.primary_metric : '-',
    params_loss_type: data.params.loss && data.params.loss.type ? data.params.loss.type : '-',
  }));
  const allClass = 'reportingCols';
  const columns = [
    {
      title: 'Run Name',
      dataIndex: 'name',
      fixed: 'left',
      className: allClass,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      className: allClass,
    },
    {
      title: 'Data Split',
      dataIndex: 'data_split_name',
      className: allClass,
    },
    {
      title: 'Data Schema',
      dataIndex: 'data_schema_name',
      className: allClass,
    },
    {
      title: 'Task',
      dataIndex: 'task_name',
      className: allClass,
    },
    {
      title: 'Model Type',
      dataIndex: 'model_type',
      className: allClass,
    },
    {
      title: 'Participants',
      dataIndex: 'participants',
      className: allClass,
    },
    {
      title: 'Iterations',
      dataIndex: 'iterations',
      className: allClass,
    },
    {
      title: 'Metrics',
      dataIndex: 'task_metrics_aggregated',
      className: allClass,
    },
    {
      title: 'Epochs',
      dataIndex: 'params_epochs',
      className: allClass,
    },
    {
      title: 'Optimizer',
      dataIndex: 'params_optimizer_type',
      className: allClass,
    },
    {
      title: 'DP Optimizer',
      dataIndex: 'params_dp_optimizer',
      className: allClass,
    },
    {
      title: 'Learning Rate',
      dataIndex: 'params_optimizer_learning_rate',
      className: allClass,
    },
    {
      title: 'L2 Clipping Norm',
      dataIndex: 'params_optimizer_l2_norm_clip',
      className: allClass,
    },
    {
      title: 'Noise Multiplier',
      dataIndex: 'params_optimizer_noise_multiplier',
      className: allClass,
    },
    {
      title: 'Batch Size',
      dataIndex: 'params_batch_size',
      className: allClass,
    },
    {
      title: 'Primary Metric',
      dataIndex: 'primary_metric',
      className: allClass,
    },
    {
      title: 'Loss',
      dataIndex: 'params_loss_type',
      className: allClass,
    },
  ];

  return (
    <DataTable
      rowSelection={rowSelection}
      columns={columns}
      dataSource={adaptedReportData}
      pagination={{ defaultPageSize: 5 }}
    />
  );
}

export default ReportingTable;

ReportingTable.propTypes = {
  reportData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rowSelection: PropTypes.shape({
    type: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Tooltip,
  Input,
} from 'antd';

export default function SetNameItem(props) {
  const { formName } = props;

  return (
    <Tooltip title={`Name the ${formName}.`}>
      <Form.Item
        label={`${formName} Name`}
        name="name"
        rules={[
          {
            required: true,
            message: `Please name the ${formName}!`,
          },
        ]}
      >
        <Input />
      </Form.Item>
    </Tooltip>
  );
}
SetNameItem.propTypes = {
  formName: PropTypes.string.isRequired,
};

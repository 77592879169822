import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Button,
  Popconfirm,
} from 'antd';
import {
  PlusCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import NewDataSchemaModal from './NewDataSchemaModal/NewDataSchemaModal';
import { deleteDataSchema } from '../../util/ApiCalls';

class DataSchemaToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newDataSchemaModalVisible: false,
    };
  }

  showNewDataSchemaModal() {
    this.setState({
      newDataSchemaModalVisible: true,
    });
  }

  hideNewDataSchemaModal() {
    this.setState({
      newDataSchemaModalVisible: false,
    });
  }

  render() {
    const { selectedDataSchema, refresh } = this.props;
    const { newDataSchemaModalVisible } = this.state;
    const onDelete = async () => {
      const { _id: selectedId } = selectedDataSchema;
      if (selectedDataSchema) {
        try {
          await deleteDataSchema(selectedId);
        } catch (e) {
          console.log(e);
        }
        refresh();
      }
    };
    return (
      <Row gutter={[16, 16]}>
        <Col>
          <Button type="primary" onClick={() => this.showNewDataSchemaModal()}>
            <PlusCircleOutlined />
            Create a new Dataschema
          </Button>
        </Col>
        <Col>
          {selectedDataSchema
            ? (
              <Popconfirm
                title="Are you sure you want to Delete the Data Schema？"
                okText="Yes"
                cancelText="No"
                onConfirm={() => onDelete()}
              >
                <Button danger>
                  <DeleteOutlined />
                  Delete
                </Button>
              </Popconfirm>
            )
            : (
              <Button danger disabled>
                <DeleteOutlined />
                Delete
              </Button>
            )}
        </Col>
        <NewDataSchemaModal
          visible={newDataSchemaModalVisible}
          hideModal={() => this.hideNewDataSchemaModal()}
          refresh={() => refresh()}
        />
      </Row>
    );
  }
}

export default DataSchemaToolbar;

DataSchemaToolbar.propTypes = {
  selectedDataSchema: PropTypes.shape({
    _id: PropTypes.string,
  }),
  refresh: PropTypes.func,
};

DataSchemaToolbar.defaultProps = {
  refresh: () => {},
  selectedDataSchema: PropTypes.shape({
    _id: null,
  }),
};

import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Overview from './pages/Overview';
import Details from './pages/Details';
import RunOverview from './pages/RunOverview/RunOverview';
import Reporting from './pages/Reporting/Reporting';
import MLTasks from './pages/MLTasks/MLTasks';
import DataSchemas from './pages/DataSchemas/DataSchemas';
import DataSplits from './pages/DataSplits/DataSplits';
import ProtectedRoute from './ProtectedRoute';
import Callback from './pages/Callback';

export default function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route path="/callback" component={Callback} />
          <ProtectedRoute exact path="/" component={Overview} />
          <ProtectedRoute exact path="/mlruns" component={RunOverview} />
          <ProtectedRoute exact path="/reporting" component={Reporting} />
          <ProtectedRoute exact path="/mltasks" component={MLTasks} />
          <ProtectedRoute exact path="/dataschemas" component={DataSchemas} />
          <ProtectedRoute exact path="/datasplits" component={DataSplits} />
          <ProtectedRoute path="/leaf/:id" component={Details} />
        </Switch>
      </Router>
    </div>
  );
}

import socketIOClient from 'socket.io-client';
import cognitoUtils from './UtilsCognito';

// eslint-disable-next-line no-underscore-dangle
const ENDPOINT = window._env_.REACT_APP_FML_UPDATER_URL;
let socket = null;
const updateSocket = async () => {
  if (!socket) {
    let accessToken = '';
    const cognitoUser = cognitoUtils.createCognitoUser();
    if (cognitoUser) {
      await cognitoUser.getSession((err, result) => {
        if (err || !result) {
          return null;
        }
        // TODO: This is a side-effect, no good practice
        accessToken = result.accessToken.jwtToken;
        return accessToken;
      });
    }
    socket = socketIOClient(ENDPOINT, {
      origins: '*:*',
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      },
    });
  }
};

const processData = (data) => data
  .map((performance) => ({
    key: performance.participant,
    ...performance,
  }));

const subscribeToFMLUpdates = async (callback) => {
  await updateSocket();
  socket.on('fml-update', ({ run, data }) => {
    const processedData = processData(data);
    callback({ run, data: processedData });
  });
};

const requestFMLUpdate = async (run) => {
  await updateSocket();
  socket.emit('fml-request', { run });
};

const unsubscribeFromFMLUpdates = async () => {
  if (socket) {
    socket.removeAllListeners();
  }
};

export { subscribeToFMLUpdates, requestFMLUpdate, unsubscribeFromFMLUpdates };

import React, { Component } from 'react';
import {
  Card, Statistic, Progress, Row, Col,
} from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import '../../App.css';
import { getStatusLog } from '../../util/ApiCalls';
import StatusTag from '../StatusTag/StatusTag';

class LeafStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusLogs: [],
    };
  }

  componentDidMount() {
    const { id } = this.props;
    try {
      getStatusLog(id).then((data) => {
        // The API only returns the last 50 events anyway at the moment
        const statusLogs = Array.isArray(data) ? data.slice(0, 100) : [];
        this.setState({ statusLogs });
      });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { statusLogs } = this.state;
    const onlineRate = Math.round((statusLogs.filter((log) => (log.status) === 'online').length / statusLogs.length) * 100);
    return (
      <Card
        style={{ width: '100%' }}
        title="Leaf Status"
      >
        {
        statusLogs.length > 0 // statusLogs.map.filter((log) => (log.status) === "online").length
          ? (
            <Row gutter={16}>
              <Col span={12}>
                <Statistic
                  title="Availability (last 24h)"
                  formatter={() => (
                    <Progress
                      type="dashboard"
                      strokeColor={{
                        '0%': '#87d068',
                        '100%': 'gold',
                      }}
                      format={(rate) => `${rate}%`}
                      percent={onlineRate}
                    />
                  )}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title="Last Update"
                  value={new Date(statusLogs[0].createdAt).toLocaleString('de-DE')}
                  prefix={<RedoOutlined />}
                  valueStyle={{ fontSize: 14 }}
                />
                <StatusTag status={statusLogs[0].status} />
                <br />
              </Col>
            </Row>
          )
          : (
            <Row>
              <Statistic title="Current Status" value="offline" />
            </Row>
          )
        }
      </Card>
    );
  }
}

LeafStatus.propTypes = {
  id: PropTypes.node.isRequired,
};

export default LeafStatus;

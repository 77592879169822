import React from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import LeafStatistics from '../components/LeafStatistics/LeafStatistics';
import LeafStatus from '../components/LeafStatus/LeafStatus';
import LeafModelTable from '../components/TableTemplates/LeafModelTable/LeafModelTable';
import LeafDescription from '../components/LeafDescription/LeafDescription';
import SingleLeafMap from '../components/Map/SingleLeafMap';
import DetailPageHeader from '../components/PageHeader/DetailPageHeader';

export default function Details(props) {
  const { match: { params: { id } } } = props;
  return (
    <>
      <Row>
        <Col span={24}>
          <DetailPageHeader id={id} />
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ padding: '2vh 2vh 0vh 2vh' }}>
        <Col span={8}><LeafDescription id={id} /></Col>
        <Col span={16}><LeafModelTable leafId={id} /></Col>
      </Row>
      <Row gutter={[16, 16]} style={{ padding: '0vh 2vh 2vh 2vh' }}>
        <Col span={8}><SingleLeafMap id={id} /></Col>
        <Col span={8}><LeafStatistics id={id} /></Col>
        <Col span={8}><LeafStatus id={id} /></Col>
      </Row>
    </>
  );
}

Details.propTypes = {
  match: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
    PropTypes.string])
    .isRequired,
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Descriptions,
} from 'antd';
import Highlight from 'react-highlight';
import {
  getModelDefinition,
} from '../../../util/ApiCalls';

function ModelDetailsModal(
  {
    visible, modelInfo, onOk, onCancel,
  },
) {
  const [model, setModel] = useState(false);
  useEffect(() => {
    const updateModel = async () => {
      try {
        const newModel = await getModelDefinition(modelInfo.model_definition_id);
        setModel({ ...newModel, model_counter: modelInfo.model_counter });
      } catch (e) {
        console.log(e);
      }
    };
    if (modelInfo) {
      updateModel();
    }
  }, [modelInfo]);
  return (
    <Modal
      title={`Model model_${model.model_counter} - Details`}
      visible={visible}
      onOk={() => onOk()}
      onCancel={() => onCancel()}
    >
      <Descriptions
        bordered
        layout="vertical"
        column={
          {
            xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1,
          }
        }
      >
        <Descriptions.Item label="Created At">{new Date(model.createdAt).toLocaleString('de-DE')}</Descriptions.Item>
        <Descriptions.Item label="Description">{model.description}</Descriptions.Item>
        <Descriptions.Item label="Type">{model.type}</Descriptions.Item>
        <Descriptions.Item label="Model Summary">
          <Highlight className="python">
            {model.summary}
          </Highlight>
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
}

export default ModelDetailsModal;

ModelDetailsModal.defaultProps = {
  modelInfo: {},
};

ModelDetailsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  modelInfo: PropTypes.shape({
    model_definition_id: PropTypes.string.isRequired,
    model_counter: PropTypes.number.isRequired,
  }),
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

import React, { Component } from 'react';
import {
  Tooltip,
  Typography,
} from 'antd';
import PropTypes from 'prop-types';
import DataSchemaTable from '../../../DataSchemaTable/DataSchemaTable';
import TargetSelectionTable from './TargetSelectionTable';
import { DataSchemaSelectionContext } from './DataSchemaSelectionContext';

/* eslint-disable react/forbid-prop-types */

const { Text } = Typography;

class DataSchemaSelection extends Component {
  constructor(props) {
    super(props);
    const { dataSchema, target } = props;
    this.state = { dataSchema, target };
  }

  async componentDidMount() {
    this.validateForm();
  }

  onSchemaChange(el) {
    const { onChange } = this.props;
    this.setState({ dataSchema: el }, () => {
      onChange(this.state);
      this.validateForm();
    });
  }

  onTargetChange(el) {
    const { onChange } = this.props;
    this.setState({ target: el }, () => {
      onChange(this.state);
      this.validateForm();
    });
  }

  validateForm() {
    const { dataSchema, target } = this.state;
    const { validated } = this.props;
    if (dataSchema && target) {
      validated(true);
    } else {
      validated(false);
    }
  }

  render() {
    const { target } = this.props;
    return (
      <>
        <div>
          <Tooltip title="Select the dataschema">
            <Text type="danger">* </Text>
            <Text>Dataschema:</Text>
            <DataSchemaSelectionContext.Consumer>
              {
                ({ changeSchema, state: { selectedSchema } }) => (
                  <DataSchemaTable
                    rowSelection={
                      {
                        onChange: (selectedRowKey, selectedRow) => {
                          changeSchema(selectedRow[0]);
                          this.onSchemaChange(selectedRow[0]);
                        },
                        type: 'radio',
                        selectedRowKeys: selectedSchema
                          ? [selectedSchema.key]
                          : [],
                      }
                    }
                  />
                )
              }
            </DataSchemaSelectionContext.Consumer>
          </Tooltip>
        </div>
        <div>
          <Tooltip title="Select the target">
            <Text type="danger">* </Text>
            <Text>Target:</Text>
            <DataSchemaSelectionContext.Consumer>
              {
                ({ state: { selectedSchema } }) => (
                  <TargetSelectionTable
                    rowSelection={{
                      onChange: (selectedRowKey) => {
                        this.onTargetChange(selectedRowKey[0]);
                      },
                      type: 'radio',
                      selectedRowKeys: target
                        ? [target]
                        : [],
                    }}
                    selectedSchema={selectedSchema}
                  />
                )
            }
            </DataSchemaSelectionContext.Consumer>
          </Tooltip>
        </div>
      </>
    );
  }
}

export default DataSchemaSelection;

DataSchemaSelection.defaultProps = {
  dataSchema: {},
  target: '',
};

DataSchemaSelection.propTypes = {
  dataSchema: PropTypes.shape({
    definition: PropTypes.object,
  }),
  target: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  validated: PropTypes.func.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { Button } from 'antd';

function CSVDownloadButton(props) {
  const {
    data, filename, headers, buttonDescription,
  } = props;
  const csvLink = React.createRef();
  const downloadCsv = () => {
    csvLink.current.link.click();
  };
  return (
    <div>
      <Button onClick={downloadCsv} shape="round" style={{ float: 'right' }}>{buttonDescription}</Button>
      <CSVLink
        headers={headers}
        data={data}
        filename={filename}
        className="hidden"
        ref={csvLink}
        target="_blank"
      />
    </div>
  );
}

export default CSVDownloadButton;

CSVDownloadButton.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  filename: PropTypes.string.isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.string, label: PropTypes.string }),
  ).isRequired,
  buttonDescription: PropTypes.string,
};

CSVDownloadButton.defaultProps = {
  buttonDescription: 'Download CSV',
};

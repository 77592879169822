import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getRuns, getComparison } from '../../util/ApiCalls';

export const ReportingContext = React.createContext();
/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

function ReportingContextProvider({ children }) {
  const [selectedRuns, setSelectedRuns] = useState([]);
  const [runs, setRuns] = useState([]);
  const [aggregationData, setAggregationData] = useState([]);
  const [selectedDataSchema, setSelectedDataSchema] = useState('');
  const [selectedMetrics, setSelectedMetrics] = useState([]);
  const [selectedAggregations, setSelectedAggregations] = useState([]);
  const runType = (run) => {
    if (run.type === 'fml') {
      return ('federated');
    }
    if (run.type === 'stl') {
      return ('transferred');
    }
    return ('isolated');
  };
  useEffect(() => {
    const fetchRuns = async () => {
      try {
        let fetchedRuns = await getRuns(['fml', 'train', 'stl']);
        fetchedRuns = fetchedRuns.map((run) => ({
          key: run._id,
          ...run,
          run_type: run.type,
          type: runType(run),
          participants: run.participants.sort((a, b) => a >= b),
        }));
        const filteredRuns = fetchedRuns
          .filter((run) => run.data_schema_id === selectedDataSchema)
          .filter((run) => run.status === 'done');
        setRuns(filteredRuns);
      } catch (e) {
        console.log(e);
      }
    };
    fetchRuns();
  }, [selectedDataSchema]);

  useEffect(() => {
    const fetchComparison = async () => {
      try {
        const comparison = await getComparison(
          selectedRuns.map((run) => run._id),
          selectedAggregations.map((agg) => agg.type),
          selectedMetrics.map((metric) => metric.type),
        );
        setAggregationData(comparison);
      } catch (e) {
        console.log(e);
      }
    };
    if (selectedRuns.length > 0 && selectedAggregations.length > 0 && selectedMetrics.length > 0) {
      fetchComparison();
    }
  }, [selectedRuns, selectedAggregations, selectedMetrics]);

  return (
    <ReportingContext.Provider
      value={
          {
            state: {
              selectedRuns,
              runs,
              selectedDataSchema,
              aggregationData,
              selectedMetrics,
              selectedAggregations,
            },
            selectRuns: (newSelectedRuns) => setSelectedRuns(newSelectedRuns),
            selectDataSchema: (newDataSchema) => {
              setSelectedDataSchema(newDataSchema);
              setSelectedRuns([]);
              setAggregationData([]);
              setSelectedAggregations([]);
            },
            selectAggregations: (newAggregations) => setSelectedAggregations(newAggregations),
            selectMetrics: (newMetrics) => setSelectedMetrics(newMetrics),
          }
        }
    >
      {children}
    </ReportingContext.Provider>
  );
}

export default ReportingContextProvider;

ReportingContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

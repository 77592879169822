import React from 'react';
import './Header.css';
import PropTypes from 'prop-types';
import { Menu, Dropdown } from 'antd';
import cognitoUtils from '../../util/UtilsCognito';

export default function MenuHeader(props) {
  const { username } = props;

  const menu = (
    <Menu>
      <Menu.Item eventKey="4.1" onClick={cognitoUtils.signOutCognitoSession}>Logout</Menu.Item>
    </Menu>
  );
  return (
    <header style={{ zIndex: 1 }}>
      <Menu theme="dark" mode="horizontal" style={{ padding: '2vh 0vh 0vh 0vh' }}>
        <Menu.Item style={{ paddingBottom: '0' }}>
          <a href="/">
            <img src="../../mlx_white_transp.png" width="100" className="logo-scroll-fix" alt="prenode mlx" />
          </a>
        </Menu.Item>
        <Menu.Item style={{ float: 'right' }}>
          <Dropdown overlay={menu}>
            <p className="ant-dropdown-link">
              Signed in as:
              {` ${username}`}
            </p>
          </Dropdown>
        </Menu.Item>
      </Menu>
    </header>
  );
}
MenuHeader.propTypes = {
  username: PropTypes.node.isRequired,
};

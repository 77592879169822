import React from 'react';
import PropTypes from 'prop-types';
import { Fragment } from 'react/cjs/react.production.min';
import { LineGraph } from '../LineGraph';
import BiaxialLineGraphRight from './BiaxialLineGraphRight';
import BiaxialLineGraphBottom from './BiaxialLineGraphBottom';
import { schemeRed, schemeBlue } from '../../../util/ColorSchemes';
import CSVDownloadButton from '../../CSVDownloadButton/CSVDownloadButton';

const BiaxialLineGraphLeft = LineGraph;
const getCSVData = (data) => {
  const csvData = [];
  data.forEach((element) => {
    csvData.push(...element.data.map((dataPoint) => ({
      x: dataPoint.x,
      y: dataPoint.y,
      id: element.id,
    })));
  });
  return csvData;
};

function BiaxialLineGraph(props) {
  const { performancePercentage, performanceAbsolute } = props;
  const xAxisName = 'Iteration';
  const yAxisName = 'Performance';

  const bottomGraph = (
    <BiaxialLineGraphBottom
      dataLeft={performancePercentage}
      dataRight={performanceAbsolute}
      colorsLeft={schemeRed}
      colorsRight={schemeBlue}
      xAxisName={xAxisName}
      yAxisName={(performancePercentage.length || performanceAbsolute.length) ? null : 'Performance'}
    />
  );

  const leftGraph = (
    <BiaxialLineGraphLeft
      data={performancePercentage}
      withSelector
      yAxisName={yAxisName}
      enableGridY={!!performancePercentage.length}
      axisColor={schemeRed[0]}
      colors={schemeRed}
      legendAnchor="bottom-left"
      legendTranslateX={0}
      legendTranslateY={70}
      position="absolute"
    />
  );
  const rightGraph = (
    <BiaxialLineGraphRight
      data={performanceAbsolute}
      yAxisName={yAxisName}
      axisColor={schemeBlue[0]}
      colors={schemeBlue}
      legendAnchor="bottom-right"
      legendTranslateX={0}
      legendTranslateY={70}
      enableGridY={!performancePercentage.length}
    />
  );

  const csvButton = (
    <div style={{
      position: 'absolute',
      zIndex: 4,
      bottom: 10,
      right: 10,
    }}
    >
      <CSVDownloadButton
        data={getCSVData(performancePercentage.concat(performanceAbsolute))}
        filename={`${yAxisName}vs${xAxisName}.csv`}
        headers={[
          { label: 'id', key: 'id' },
          { label: xAxisName, key: 'x' },
          { label: yAxisName, key: 'y' },
        ]}
      />
    </div>
  );

  if (performancePercentage.length && performanceAbsolute.length) {
    return (
      <Fragment>
        {bottomGraph}
        {leftGraph}
        {rightGraph}
        {csvButton}
      </Fragment>
    );
  }
  if (performancePercentage.length) {
    return (
      <Fragment>
        {bottomGraph}
        {leftGraph}
        {csvButton}
      </Fragment>
    );
  }
  if (performanceAbsolute.length) {
    return (
      <Fragment>
        {bottomGraph}
        {rightGraph}
        {csvButton}
      </Fragment>
    );
  }
  return (
    bottomGraph
  );
}

export default BiaxialLineGraph;

BiaxialLineGraph.propTypes = {
  performancePercentage: PropTypes.PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.number,
          y: PropTypes.number,
        }),
      ),
    }),
  ).isRequired,
  performanceAbsolute: PropTypes.PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.number,
          y: PropTypes.number,
        }),
      ),
    }),
  ).isRequired,
};

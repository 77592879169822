import React, { Component } from 'react';
import {
  Button, Card, Row, Col,
} from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import LeafItem from './LeafItem/LeafItem';
import { getAllInfo } from '../../util/ApiCalls';
import '../../App.css';
import RegisterModal from './RegisterModal/RegisterModal';

class LeafMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leaves: [],
      modalVisible: false,
    };
  }

  componentDidMount() {
    this.updateInfo();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.updateInfo();
    }
  }

  updateInfo() {
    getAllInfo()
      .then((data) => {
        this.setState({ leaves: data });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  showModal() {
    this.setState({
      modalVisible: true,
    });
  }

  handleModalOk() {
    this.setState({
      modalVisible: false,
    });
  }

  handleModalCancel() {
    this.setState({
      modalVisible: false,
    });
  }

  render() {
    const { leaves, modalVisible } = this.state;
    const { onDeregister, onRegister } = this.props;
    return (
      <Card
        className="scrollable-large"
        title="Available Leaves"
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Button type="primary" onClick={() => this.showModal()}>
              <PlusCircleOutlined />
              Register New Leaf
            </Button>
          </Col>
        </Row>
        {
            !Array.isArray(leaves)
              ? ''
              : leaves.map((leaf) => (
                <Row key={leaf.id} gutter={[16, 16]}>
                  <Col span={24}>
                    <LeafItem
                      name={leaf.name}
                      id={leaf.id}
                      description={leaf.description}
                      onDeregister={onDeregister}
                      className="px-0"
                    />
                  </Col>
                </Row>
              ))
        }
        <RegisterModal
          visible={modalVisible}
          onOk={() => this.handleModalOk()}
          onCancel={(e) => this.handleModalCancel(e)}
          onRegister={onRegister}
        />
      </Card>
    );
  }
}

export default LeafMenu;

LeafMenu.propTypes = {
  onDeregister: PropTypes.func.isRequired,
  onRegister: PropTypes.func.isRequired,
};

import React, { Component } from 'react';
import { Card, Descriptions } from 'antd';
import PropTypes from 'prop-types';
import { getInfo } from '../../util/ApiCalls';
import '../../App.css';

class LeafDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: {
        name: '',
        address: '',
        description: '',
      },
    };
  }

  componentDidMount() {
    const { id } = this.props;
    try {
      getInfo(id).then((data) => {
        if (data) {
          this.setState({ status: data });
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { status: { name, address, description } } = this.state;
    return (
      <Card
        style={{ width: '100%', height: '100%' }}
        title="Leaf Description"
      >
        <Descriptions layout="horizontal">
          <Descriptions.Item key="1" label="Name">{name}</Descriptions.Item>
        </Descriptions>
        <Descriptions layout="horizontal">
          <Descriptions.Item key="2" label="Location">{address}</Descriptions.Item>
        </Descriptions>
        <Descriptions layout="horizontal">
          <Descriptions.Item key="3" label="Description">{description}</Descriptions.Item>
        </Descriptions>
      </Card>
    );
  }
}

LeafDescription.propTypes = {
  id: PropTypes.node.isRequired,
};

export default LeafDescription;

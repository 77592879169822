import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { subscribeToFMLUpdates, requestFMLUpdate, unsubscribeFromFMLUpdates } from '../../util/SocketUpdates';
import { getRuns } from '../../util/ApiCalls';

export const RunContext = React.createContext();

function RunContextProvider({ children }) {
  const [selectedRun, setSelectedRun] = useState(null);
  const [currentRun, setCurrentRun] = useState(null);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [performance, setPerformance] = useState([]);
  const [currentPerformance, setCurrentPerformance] = useState([]);
  const [currentIteration, setCurrentIteration] = useState(null);

  async function fetchData() {
    try {
      const runs = await getRuns(['fml', 'train', 'stl']);
      const current = runs.find((run) => run.status === 'running');
      const scheduledRuns = runs.filter((run) => run.status === 'scheduled');
      if (current) {
      // eslint-disable-next-line no-underscore-dangle
        setCurrentRun(current._id);
      } else if (Array.isArray(scheduledRuns) && scheduledRuns.length) {
        scheduledRuns.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        // eslint-disable-next-line no-underscore-dangle
        setCurrentRun(scheduledRuns[0]._id);
      } else {
        setCurrentRun(null);
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchData();
  }, [selectedRun, currentRun, currentIteration]);

  useEffect(() => {
    subscribeToFMLUpdates(({ run, data }) => {
      if (selectedRun && selectedRun.run === run) {
        setPerformance(data);
      }
    });
    subscribeToFMLUpdates(({ run, data }) => {
      if (currentRun === run) {
        setCurrentPerformance(data);
        let maxIteration = 0;
        data.forEach((leaf) => {
          if (leaf.iterations.length > maxIteration) {
            maxIteration = leaf.iterations.length;
          }
        });
        setCurrentIteration(maxIteration);
      }
    });
    if (selectedRun && selectedRun.run) {
      requestFMLUpdate(selectedRun.run);
    }
    if (currentRun) {
      requestFMLUpdate(currentRun);
    } else {
      fetchData();
    }
    return unsubscribeFromFMLUpdates;
  }, [selectedRun, currentRun]);

  return (
    <RunContext.Provider
      value={
        {
          selectedRun,
          selectedParticipant,
          performance,
          currentRun,
          currentPerformance,
          currentIteration,
          setCurrentRun,
          changeRun: (run) => {
            setSelectedRun(run);
            setSelectedParticipant(null);
            setPerformance([]);
          },
          changeParticipant: (participant) => {
            setSelectedParticipant(participant);
          },
        }
      }
    >
      {children}
    </RunContext.Provider>
  );
}

export default RunContextProvider;

RunContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';

export const DataSchemasContext = React.createContext();

class DataSchemasContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSchema: null,
    };
  }

  render() {
    const { children } = this.props;
    const { selectedSchema } = this.state;
    return (
      <DataSchemasContext.Provider
        value={
          {
            state: { selectedSchema },
            changeSchema: (schema) => {
              this.setState({ selectedSchema: schema });
            },
          }
        }
      >
        {children}
      </DataSchemasContext.Provider>
    );
  }
}

export default DataSchemasContextProvider;

DataSchemasContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

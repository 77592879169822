/* eslint-disable max-len */
import taskTypes from './TaskDetails';

export const metricsInformation = [
  {
    type: 'mae',
    family: 'Mean Absolute Error',
    description: 'Mean Absolute Error',
    taskTypes: [taskTypes.REGRESSION],
    valueRange: 'absolute',
    link: 'https://www.tensorflow.org/api_docs/python/tf/keras/metrics/MeanAbsoluteError',
    y_true_sample: '[0, 1], [0, 0]',
    y_pred_sample: '[1, 1], [0, 0]',
    explanation: 'Same format for truth and prediction',
  },
  {
    type: 'mape',
    family: 'Mean Absolute Percentage Error',
    description: 'Mean Absolute Percentage Error',
    taskTypes: [taskTypes.REGRESSION],
    valueRange: 'percentage',
    link: 'https://www.tensorflow.org/api_docs/python/tf/keras/metrics/MeanAbsolutePercentageError',
    y_true_sample: '[0, 1], [0, 0]',
    y_pred_sample: '[1, 1], [0, 0]',
    explanation: 'Same format for truth and prediction',
  },
  {
    type: 'mse',
    family: 'Mean Squared Error',
    description: 'Mean Squared Error',
    taskTypes: [taskTypes.REGRESSION],
    valueRange: 'absolute',
    link: 'https://www.tensorflow.org/api_docs/python/tf/keras/metrics/MeanSquaredError',
    y_true_sample: '[0, 1], [0, 0]',
    y_pred_sample: '[1, 1], [0, 0]',
    explanation: 'Same format for truth and prediction',
  },
  {
    type: 'rmse',
    family: 'Root Mean Squared Error',
    description: 'Root Mean Squared Error',
    taskTypes: [taskTypes.REGRESSION],
    valueRange: 'absolute',
    link: 'https://www.tensorflow.org/api_docs/python/tf/keras/metrics/RootMeanSquaredError',
    y_true_sample: '[0, 1], [0, 0]',
    y_pred_sample: '[1, 1], [0, 0]',
    explanation: 'Same format for truth and prediction',
  },
  {
    type: 'acc',
    family: 'Accuracy',
    description: 'Default Accuracy',
    taskTypes: [taskTypes.MULTICLASSCLASSIFICATION, taskTypes.BINARYCLASSIFICATION],
    valueRange: 'percentage',
    link: 'https://www.tensorflow.org/api_docs/python/tf/keras/metrics/Accuracy',
    y_true_sample: '[[0], [0], [1], [1]] # binary\n[[1], [2], [0], [1]] # multi-class',
    y_pred_sample: '[[0], [0], [0], [1]] # binary\n[[1], [2], [2], [1]] # multi-class',
    explanation: 'Same format for truth and prediction',
  },
  {
    type: 'sc_acc',
    family: 'Accuracy',
    description: 'Sparse Categorical Accuracy',
    taskTypes: [taskTypes.MULTICLASSCLASSIFICATION, taskTypes.BINARYCLASSIFICATION],
    valueRange: 'percentage',
    link: 'https://www.tensorflow.org/api_docs/python/tf/keras/metrics/SparseCategoricalAccuracy',
    y_true_sample: ' [[0], [0], [1], [1]] # binary \n[[2], [1], [0], [1]] # multi-class',
    y_pred_sample: '[\n\t[0.98,0.02],\n\t[1,0],\n\t[1,0],\n\t[0.2, 0.8]\n] # binary\n# OR\n[\n\t[0.1,0.4,0.8],\n\t[0.05,0.95,0],\n\t[0.1,0.1,0.8],\n\t[0.05,0.95,0]\n] # multi-class',
    explanation: 'Truth data is in class format, prediction data is in probability format.',
  },
  {
    type: 'c_acc',
    family: 'Accuracy',
    description: 'Categorical Accuracy',
    taskTypes: [taskTypes.MULTICLASSCLASSIFICATION, taskTypes.BINARYCLASSIFICATION],
    valueRange: 'percentage',
    link: 'https://www.tensorflow.org/api_docs/python/tf/keras/metrics/CategoricalAccuracy',
    y_true_sample: '[[1,0], [1,0], [0,1], [0,1]] # binary)\n[[0,0,1], [0,1,0], [1,0,0], [0,1,0]] # multi-class',
    y_pred_sample: '[\n\t[0.98,0.02],\n\t[1,0],\n\t[1,0],\n\t[0.2, 0.8]] # binary\n# OR\n[\n\t[0.1,0.4,0.8],\n\t[0.05,0.95,0],\n\t[0.1,0.1,0.8],\n\t[0.05,0.95,0]\n] # multi-class',
    explanation: 'One hot encoded true target, prediction data is in probability format.',
  },
  {
    type: 'b_acc',
    family: 'Accuracy',
    description: 'Binary Accuracy',
    taskTypes: [taskTypes.BINARYCLASSIFICATION],
    valueRange: 'percentage',
    link: 'https://www.tensorflow.org/api_docs/python/tf/keras/metrics/BinaryAccuracy',
    y_true_sample: '[[0],[0],[1],[1]]',
    y_pred_sample: '[[0],[0.5],[0.3],[0.9]]',
    explanation: 'Truth data is in class format, prediction data is in probability format.',
  },
  {
    type: 'aucroc',
    family: 'AUC ROC',
    description: 'AUC ROC',
    taskTypes: [taskTypes.BINARYCLASSIFICATION],
    valueRange: 'percentage',
    link: 'https://www.tensorflow.org/api_docs/python/tf/keras/metrics/AUC',
    y_true_sample: '0, 0, 1, 1',
    y_pred_sample: '0, 0.5, 0.3, 0.9',
    explanation: 'Truth data is in class format, prediction data is in probability format.',
  },
  {
    type: 'sc_aucroc',
    family: 'AUC ROC',
    description: 'Sparse Categorical AUC ROC',
    taskTypes: [taskTypes.MULTICLASSCLASSIFICATION, taskTypes.BINARYCLASSIFICATION],
    valueRange: 'percentage',
    link: 'https://www.tensorflow.org/api_docs/python/tf/keras/metrics/AUC',
    y_true_sample: '[1], [1], [0], [0]',
    y_pred_sample: '[0.98], [1], [0], [0.6]',
    explanation: 'Truth data is in class format, prediction data is in probability format.',
  },
  {
    type: 'c_aucroc',
    family: 'AUC ROC',
    description: 'Categorical AUC ROC',
    taskTypes: [taskTypes.MULTICLASSCLASSIFICATION, taskTypes.BINARYCLASSIFICATION],
    valueRange: 'percentage',
    link: 'https://www.tensorflow.org/api_docs/python/tf/keras/metrics/AUC',
    y_true_sample: '[0, 1], [0, 1], [1, 0], [1, 0]',
    y_pred_sample: '[0.98], [1], [0], [0.6]',
    explanation: 'One hot encoded true target, prediction data is in probability format.',
  },
  {
    type: 'f1',
    family: 'F Beta Score',
    description: 'F1 Score',
    taskTypes: [taskTypes.SEGMENTATION, taskTypes.MULTICLASSCLASSIFICATION, taskTypes.BINARYCLASSIFICATION],
    valueRange: 'percentage',
    link: 'https://deepai.org/machine-learning-glossary-and-terms/f-score',
    y_true_sample: '[0, 1], [0, 1], [1, 0], [1, 0]',
    y_pred_sample: '[0, 0.98], [0.1, 0.9], [0.7,0.3], [0.6,0.4]',
    explanation: 'One hot encoded true target, prediction data is in one-hot probability format.',
  },
];

export function metricsDetails(metrics) {
  const expandedMetrics = metrics
    .map((metric) => metricsInformation
      .find((element) => element.type === metric));

  return expandedMetrics;
}

export function metricDetails(metric) {
  return metricsInformation.find((element) => element.type === metric);
}

export function metricsDetailsByType(taskType) {
  const metricsByType = metricsInformation
    .filter((metric) => (metric.taskTypes.includes(taskType)));
  return metricsByType;
}

export function metricsDetailsByFamily(family) {
  const metricsByFamily = metricsInformation
    .filter((metric) => (metric.family === family));
  return metricsByFamily;
}

export function getMetricsFamilies() {
  const families = metricsInformation
    .map((m) => m.family);
  const uniqueFamilies = [...new Set(families)];
  return uniqueFamilies;
}

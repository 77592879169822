import React from 'react';
import PropTypes from 'prop-types';
import DataTable from '../../TableTemplates/DataTable';

// Graphs the run of one leaf over the course of the rounds
function DPTable(props) {
  const { performance } = props;
  const noTableData = [
    {
      iteration: 'No fetched table data yet',
      data: {
        0: 'No fetched table data yet',
      },
    },
  ];
  const columns = [
    {
      title: 'Iteration',
      dataIndex: 'iteration',
      fixed: 'left',
      ellipsis: true,
    },
    {
      title: 'Epsilon',
      dataIndex: 'epsilon',
      fixed: 'left',
      ellipsis: true,
    },
    {
      title: 'Delta',
      dataIndex: 'delta',
      fixed: 'left',
      ellipsis: true,
    },
    {
      title: 'Order',
      dataIndex: 'opt_order',
      fixed: 'left',
      ellipsis: true,
    },
  ];

  let dpMeasures = noTableData;
  const round = (number) => Math.round((number + Number.EPSILON) * 10000) / 10000;
  if (performance && 'iterations' in performance && performance.iterations.length !== 0) {
    try {
      dpMeasures = performance.iterations.map((iteration) => ({
        key: iteration.iteration,
        iteration: iteration.iteration,
        epsilon: iteration.train_history.differential_privacy.epsilon
          ? round(iteration.train_history.differential_privacy.epsilon)
          : null,
        delta: iteration.train_history.differential_privacy.delta
          ? round(iteration.train_history.differential_privacy.delta)
          : null,
        opt_order: iteration.train_history.differential_privacy.opt_order
          ? round(iteration.train_history.differential_privacy.opt_order)
          : null,
      }));
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <DataTable
      columns={columns}
      dataSource={dpMeasures}
    />
  );
}

export default DPTable;

DPTable.propTypes = {
  performance: PropTypes.shape(
    {
      iterations: PropTypes.arrayOf(PropTypes.shape),
    },
  ),
};
DPTable.defaultProps = {
  performance: null,
};

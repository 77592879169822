import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import cognitoUtils from '../util/UtilsCognito';

/**
  Callback route used after a successful Cognito sign-in. The window URL will contain the
  code. We can use to get a Cognito session, which includes JWT tokens etc
 */
class Callback extends Component {
  constructor(props) {
    super();
    this.state = { loggedIn: false };
    if (props.location.hash || props.location.search) {
      cognitoUtils.parseCognitoWebResponse(window.location.href) // parse the callback URL
        .then(() => cognitoUtils.getCognitoSession()) // get a new session
        .then(() => {
          this.setState({ loggedIn: true });
        });
    }
  }

  componentDidMount() {
  }

  render() {
    const { loggedIn } = this.state;
    return loggedIn ? <Redirect to="/" /> : (
      <span>Loading ...</span>);
  }
}
export default (Callback);

Callback.propTypes = {
  location: PropTypes.objectOf(PropTypes.string).isRequired,
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DataTable from './DataTable';
import { getTasks, getModelDefinition } from '../../util/ApiCalls';

class RunTaskTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tasks: [],
      selectedRow: props.selectedRow,
    };
  }

  async componentDidMount() {
    try {
      const { modelType } = this.props;
      let tasks = await getTasks();
      tasks = await Promise.all(tasks.map(async (task, index) => {
        const model = await getModelDefinition(task.model_definition_id);
        return {
          key: index,
          /* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
          task: task._id,
          task_name: task.name,
          description: task.description,
          model_definition_id: task.model_definition_id,
          model_type: model.type,
        };
      }));
      if (modelType) {
        tasks = await tasks.filter((task) => task.model_type === modelType);
      }
      this.setState({ tasks });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { onChange } = this.props;
    const { tasks, selectedRow } = this.state;
    const columns = [
      {
        title: 'Task',
        dataIndex: 'task_name',
      },
      {
        title: 'Description',
        dataIndex: 'description',
      },
    ];

    const rowSelection = {
      selectedRowKeys: selectedRow,
      type: 'radio',
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          selectedRow: selectedRowKeys,
        });
        onChange(selectedRows);
      },
    };
    return (
      <>
        <DataTable
          rowSelection={rowSelection}
          columns={columns}
          dataSource={tasks}
        />
      </>
    );
  }
}

export default RunTaskTable;

RunTaskTable.propTypes = {
  onChange: PropTypes.func,
  modelType: PropTypes.string,
  selectedRow: PropTypes.arrayOf(PropTypes.number),
};

RunTaskTable.defaultProps = {
  onChange: () => {},
  modelType: null,
  selectedRow: [],
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DataTable from './DataTable';
import StatusTag from '../StatusTag/StatusTag';
import { getAllStatus, getAllInfo } from '../../util/ApiCalls';

class LeafTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leaves: [],
      selectedRow: props.selectedRow,
    };
  }

  async componentDidMount() {
    try {
      const [leafInfo, leafStatus] = await Promise.all([getAllInfo(), getAllStatus()]);
      let leaves;
      if (Array.isArray(leafInfo)) {
        leaves = await leafStatus
          .filter((leaf) => (leafInfo.find((info) => info.id === leaf.id)))
          .map((leaf, index) => ({
            key: index,
            name: leafInfo.find((info) => info.id === leaf.id).name,
            ...leaf,
          }));
      } else {
        leaves = [];
      }
      this.setState({ leaves });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { onChange } = this.props;
    const { leaves, selectedRow } = this.state;
    const columns = [
      {
        title: 'Leaf ID',
        dataIndex: 'id',
      },
      {
        title: 'Leaf Name',
        dataIndex: 'name',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (state) => <StatusTag status={state} />,
      },
    ];

    const rowSelection = {
      selectedRowKeys: selectedRow,
      type: 'checkbox',
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          selectedRow: selectedRowKeys,
        });
        onChange(selectedRows);
      },
    };

    return (
      <>
        <DataTable
          rowSelection={rowSelection}
          columns={columns}
          dataSource={leaves}
        />
      </>
    );
  }
}

export default LeafTable;

LeafTable.propTypes = {
  onChange: PropTypes.func,
  selectedRow: PropTypes.arrayOf(PropTypes.number),
};

LeafTable.defaultProps = {
  onChange: () => {},
  selectedRow: [],
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BiaxialLineGraph from '../../../LineGraph/BiaxialLineGraph/BiaxialLineGraph';
import HiddenOptionsSelector from '../../../HiddenOptionsSelector/HiddenOptionsSelector';
import { metricsDetails } from '../../../../util/MetricsDetails';
import { getTask, getRun } from '../../../../util/ApiCalls';

function LeafRunGraph(props) {
  const { run, performance } = props;
  const [metricInputOptions, setMetricInputOptions] = useState([]);
  const [selectedItems, setSelectedItems] = useState(null);
  const onItemSelection = (childData) => {
    const expandedChildData = metricsDetails(childData);
    const sortByMetrics = (metricA, metricB) => metricA.type < metricB.type;
    setSelectedItems(expandedChildData.sort(sortByMetrics));
  };

  const graphDataPercentage = [];
  const graphDataAbsolute = [];
  if (selectedItems && performance && performance.iterations) {
    selectedItems.forEach((item) => {
      const newListItem = {
        id: item.family,
        data: performance.iterations
          .map((p) => ({
            x: p.iteration,
            y: p.evaluation[item.type],
          })),
      };
      if (item.valueRange === 'percentage') {
        graphDataPercentage.push(newListItem);
      } else if (item.valueRange === 'absolute') {
        graphDataAbsolute.push(newListItem);
      }
    });
  }

  useEffect(() => {
    const getTaskDetails = async () => {
      try {
        const task = await getTask(
          (await getRun(run)
          ).task_id,
        );
        const metricsTypes = (task.metrics).map((metric) => metric.type);
        const inputOptions = [];
        const detailedMetrics = metricsDetails(metricsTypes);
        detailedMetrics.forEach((metric) => {
          inputOptions.push({
            label: metric.family,
            value: metric.type,
          });
        });
        setSelectedItems(detailedMetrics);
        setMetricInputOptions(inputOptions);
      } catch (e) {
        console.log(e);
      }
    };
    if (run) {
      getTaskDetails(run);
    }
  }, [run]);

  return (
    <>
      <HiddenOptionsSelector
        inputOptions={metricInputOptions}
        placeholder="Select a Metric"
        onChange={onItemSelection}
        style={{ width: '100%' }}
      />
      <div style={{ position: 'static', height: '590px' }}>
        <BiaxialLineGraph
          performanceAbsolute={graphDataAbsolute.sort((dataA, dataB) => dataA.id < dataB.id)}
          performancePercentage={graphDataPercentage.sort((dataA, dataB) => dataA.id < dataB.id)}
        />
      </div>
    </>
  );
}

export default LeafRunGraph;

LeafRunGraph.propTypes = {
  run: PropTypes.string.isRequired,
  performance: PropTypes.shape(
    {
      iterations: PropTypes.arrayOf(PropTypes.shape),
    },
  ),
};
LeafRunGraph.defaultProps = {
  performance: null,
};

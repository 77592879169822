import React from 'react';
import PropTypes from 'prop-types';
import { Descriptions } from 'antd';
import Highlight from 'react-highlight';

export default function Review(props) {
  const {
    description: taskDescription,
    name: taskName,
    type: taskType,
    metrics: taskMetrics,
    processordefinition: { description: processordefinitionDescription, script },
    model: { description: modelDescription, definition, type },
    dataschema: { dataSchema: { name: dataSchemaName }, target },
  } = props;
  return (
    <>
      <Descriptions
        title="Task Details"
        bordered
        column={
          {
            xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1,
          }
        }
      >
        <Descriptions.Item label="Task Name">{taskName}</Descriptions.Item>
        <Descriptions.Item label="Task Description">{taskDescription}</Descriptions.Item>
        <Descriptions.Item label="Task Type">{taskType}</Descriptions.Item>
        <Descriptions.Item label="Data Schema Name">{dataSchemaName}</Descriptions.Item>
        <Descriptions.Item label="Target">{target}</Descriptions.Item>
        <Descriptions.Item label="Model Type">{type}</Descriptions.Item>
        <Descriptions.Item label="Model Definition">
          <Highlight className="python">
            {definition}
          </Highlight>
        </Descriptions.Item>
        <Descriptions.Item label="Model Description">{modelDescription}</Descriptions.Item>
        <Descriptions.Item label="Model Evaluation Metrics">{taskMetrics.map((metric) => metric.description).join(', ') }</Descriptions.Item>
        <Descriptions.Item label="Processing Script">
          <Highlight className="python">
            {script}
          </Highlight>
        </Descriptions.Item>
        <Descriptions.Item label="Processing Description">{processordefinitionDescription}</Descriptions.Item>
      </Descriptions>
    </>
  );
}

Review.propTypes = {
  description: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  metrics: PropTypes.arrayOf(PropTypes.object),
  processordefinition: PropTypes.shape({
    description: PropTypes.string,
    script: PropTypes.string,
  }),
  model: PropTypes.shape({
    description: PropTypes.string,
    definition: PropTypes.string,
    type: PropTypes.string,
  }),
  dataschema: PropTypes.shape({
    dataSchema: PropTypes.shape({
      name: PropTypes.string,
    }),
    target: PropTypes.string,
  }),
};
Review.defaultProps = {
  description: '',
  name: '',
  type: '',
  metrics: [],
  processordefinition: { description: '', script: '' },
  model: { description: '', definition: '' },
  dataschema: { dataSchema: { name: '' }, target: '' },
};

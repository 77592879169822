import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Select,
  Tooltip,
  Form,
  Typography,
} from 'antd';
import taskTypes from '../../../../util/TaskDetails';

const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;

class NewTaskDetails extends Component {
  constructor(props) {
    super();
    const { description, name, type } = props;
    this.state = { description, name, type };
    this.formRef = React.createRef();
  }

  async componentDidMount() {
    const { description, name, type } = this.state;
    this.formRef.current.setFieldsValue({
      description, name, type,
    });
    this.validateForm();
  }

  async onDescriptionChange({ target: { value } }) {
    this.validateForm();
    const { onChange } = this.props;
    await this.setStateAsync({ description: value });
    onChange(this.state);
  }

  async onNameChange({ target: { value } }) {
    this.validateForm();
    const { onChange } = this.props;
    await this.setStateAsync({ name: value });
    onChange(this.state);
  }

  async onTypeChange(value) {
    this.validateForm();
    const { onChange } = this.props;
    await this.setStateAsync({ type: value });
    onChange(this.state);
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  async validateForm() {
    const { validated } = this.props;
    if (await this.formRef.current.getFieldValue('name') && await this.formRef.current.getFieldValue('description') && await this.formRef.current.getFieldValue('type')) {
      validated(true);
    } else {
      validated(false);
    }
  }

  render() {
    const { name, description } = this.state;
    return (
      <Form ref={this.formRef}>
        <Tooltip title="Enter a name for the task here">
          <Text type="danger">* </Text>
          <Text>Name:</Text>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >

            <TextArea
              value={name}
              onChange={(e) => this.onNameChange(e)}
              placeholder="Name of the Task"
              autoSize={{ minRows: 1, maxRows: 2 }}
            />
          </Form.Item>
        </Tooltip>
        <Tooltip title="Select task type here">
          <Text type="danger">* </Text>
          <Text>Type:</Text>
          <Form.Item
            name="type"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select onChange={(e) => this.onTypeChange(e)}>
              {Object.keys(taskTypes)
                .map((taskTypeKey) => (
                  <Option value={taskTypes[taskTypeKey]} key={taskTypes[taskTypeKey]}>
                    {taskTypes[taskTypeKey]}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Tooltip>
        <Tooltip title="Enter a description for the task here">
          <Text type="danger">* </Text>
          <Text>Description:</Text>
          <Form.Item
            name="description"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TextArea
              value={description}
              onChange={(e) => this.onDescriptionChange(e)}
              placeholder="Description of the Task"
              autoSize={{ minRows: 2, maxRows: 5 }}
            />
          </Form.Item>
        </Tooltip>
      </Form>
    );
  }
}
export default NewTaskDetails;

NewTaskDetails.propTypes = {
  onChange: PropTypes.func,
  validated: PropTypes.func,
  name: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
};
NewTaskDetails.defaultProps = {
  onChange: () => {},
  validated: () => {},
  name: '',
  description: '',
  type: '',
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Button,
} from 'antd';
import {
  PlusCircleOutlined,
} from '@ant-design/icons';
import NewDataSplitModal from './NewDataSplitModal/NewDataSplitModal';

class DataSplitToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newDataSplitModalVisible: false,
    };
  }

  showNewDataSplitModal() {
    this.setState({
      newDataSplitModalVisible: true,
    });
  }

  hideNewDataSplitModal() {
    this.setState({
      newDataSplitModalVisible: false,
    });
  }

  render() {
    const { refresh } = this.props;
    const { newDataSplitModalVisible } = this.state;

    return (
      <Row gutter={[16, 16]}>
        <Col>
          <Button type="primary" onClick={() => this.showNewDataSplitModal()}>
            <PlusCircleOutlined />
            Create a new Data Split
          </Button>
        </Col>
        <NewDataSplitModal
          visible={newDataSplitModalVisible}
          hideModal={() => this.hideNewDataSplitModal()}
          refresh={() => refresh()}
        />
      </Row>
    );
  }
}

export default DataSplitToolbar;

DataSplitToolbar.propTypes = {
  selectedDataSplit: PropTypes.shape({
    _id: PropTypes.string,
  }),
  refresh: PropTypes.func,
};

DataSplitToolbar.defaultProps = {
  refresh: () => {},
  selectedDataSplit: PropTypes.shape({
    _id: null,
  }),
};

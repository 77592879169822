import React from 'react';
import PropTypes from 'prop-types';
import DataTable from '../../TableTemplates/DataTable';
import { metricsInformation } from '../../../util/MetricsDetails';

// Graphs the run of one leaf over the course of the rounds
function LeafRunTable({ performance }) {
  const columns = [
    {
      title: 'Iteration',
      dataIndex: 'iteration',
      fixed: 'left',
    },
  ];
  const round = (number) => Math.round((number + Number.EPSILON) * 10000) / 10000;
  if (performance
    && performance.iterations
    && performance.iterations[0]
    && performance.iterations[0].evaluation) {
    const appendColumns = Object.keys(performance.iterations[0].evaluation);
    appendColumns.forEach((col) => {
      const newColumn = {
        title: metricsInformation.find((m) => m.type === col).family,
        dataIndex: ['evaluation', `${col}`],
        render: (entry) => (entry ? round(entry) : ''),
      };
      columns.push(newColumn);
    });
  }

  return (
    <DataTable
      columns={columns}
      dataSource={performance
        ? performance.iterations.map((p) => ({ key: p.iteration, ...p }))
        : []}
      scroll={{ x: 'max-content' }}
    />
  );
}

export default LeafRunTable;

LeafRunTable.propTypes = {
  performance: PropTypes.shape(
    {
      iterations: PropTypes.arrayOf(PropTypes.shape),
    },
  ),
};
LeafRunTable.defaultProps = {
  performance: null,
};

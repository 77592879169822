import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Upload, Button, Select,
  Tooltip,
  Typography,
} from 'antd';
import './ModelUpload.css';
import { UploadOutlined, ToolTwoTone } from '@ant-design/icons';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/mode/python/python';
import 'codemirror/lib/codemirror.css';

const { TextArea } = Input;
const { Text, Paragraph } = Typography;
const { Option } = Select;

class ModelUpload extends Component {
  constructor(props) {
    super();
    const { model } = props;
    this.state = { model };

    this.typeSelection = {
      keras: 'from tensorflow.keras.layers import Dense\nfrom tensorflow.keras import Sequential\n# Import all needed keras elements.\n\ndef get_model():\n\tmodel = Sequential()\n\t# Define your model here.\n\n\treturn model',
      sklearn: 'from sklearn.tree import DecisionTreeClassifier\n# Import all needed sklearn packages.\n\ndef get_model():\n\t# Define your model with parameters here.\n\tmodel = DecisionTreeClassifier(random_state=0)\n\n\treturn model',
      custom: 'import tensorflow as tf\n# Create a custom tensorflow model as a class with methods fit() and predict().\nclass NeuralNet(Model):\n\n\tdef fit(self, ds):\n\t\t# Fit takes in a tf.Dataset and optimizes the model parameters with it.\n\n\tdef predict(self, x):\n\t\t# Predict takes in the X values as an array and returns the predictions.\n\t\treturn neural_net(x, is_training=False)\n\ndef get_model():\n\t# Return the instantiated model. \n\treturn neural_net()',
      xgboost: 'import xgboost as xgb \n\ndef get_model():\n\t# Define your model with parameters here.\n\tmodel = xgb.sklearn.XGBModel()\n\n\treturn model',
    };
  }

  async componentDidMount() {
    this.validateForm();
  }

  async onDescriptionChange({ target: { value } }) {
    const { model } = this.state;
    const { onChange } = this.props;
    await this.setStateAsync({ model: { ...model, description: value } });
    onChange(this.state);
    this.validateForm();
  }

  async onDefinitionChange(value) {
    const { model } = this.state;
    const { onChange } = this.props;
    await this.setStateAsync({ model: { ...model, definition: value } });
    onChange(this.state);
    this.validateForm();
  }

  async onTypeChange(value) {
    const { model } = this.state;
    this.example = value;
    const { onChange } = this.props;
    await this.setStateAsync({ model: { ...model, type: value } });
    onChange(this.state);
    this.validateForm();
    this.onDefinitionChange(this.typeSelection[value]);
  }

  onDefinitionUpload({ file: { status, originFileObj } }) {
    if (status !== 'uploading') {
      const reader = new FileReader();
      reader.onload = ({ target: { result } }) => {
        this.onDefinitionChange(result);
      };
      reader.readAsText(originFileObj);
    }
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  validateForm() {
    const { model } = this.state;
    const { definition, description, type } = model;
    const { validated } = this.props;
    if (description && definition && type) {
      validated(true);
    } else {
      validated(false);
    }
  }

  render() {
    const { model } = this.state;
    const { definition, description, type } = model;
    return (
      <>
        <div className="newtaskinputtype">
          <Tooltip title="Select the model type">
            <Text type="danger">* </Text>
            <Text>Model Type: </Text>
            <Paragraph>
              <Select
                id="type"
                size="middle"
                value={type}
                defaultValue="keras"
                onSelect={(e) => this.onTypeChange(e)}
              >
                <Option value="keras">Keras Model</Option>
                <Option value="xgboost">XGBoost Model</Option>
                <Option value="sklearn">
                  skLearn Model
                  <Text type="secondary"> in Alpha </Text>
                  <ToolTwoTone />
                </Option>
                <Option value="custom">
                  Custom Tensorflow Model
                  <Text type="secondary"> in Alpha </Text>
                  <ToolTwoTone />
                </Option>
              </Select>
            </Paragraph>
          </Tooltip>
        </div>
        <div>
          <Tooltip title="Enter the model definition">
            <Text type="danger">* </Text>
            <Text>Model Definition:</Text>
            <CodeMirror
              value={definition}
              options={{
                mode: 'python',
                theme: 'default',
                lineNumbers: true,
              }}
              onChange={(editor, data, value) => {
                this.currentPosition = editor.getCursor();
                this.onDefinitionChange(value);
                editor.setCursor(this.currentPosition);
              }}
            />
            <Upload
              name="file"
              multiple={false}
              showUploadList={false}
              onChange={(e) => this.onDefinitionUpload(e)}
            >
              <Button>
                <UploadOutlined />
                Click to Upload
              </Button>
            </Upload>

          </Tooltip>
        </div>
        <div className="newtaskinputdescription">
          <Tooltip title="Enter the Description here">
            <Text type="danger">* </Text>
            <Text>Description:</Text>
            <TextArea
              value={description}
              id="description"
              autoSize={{ minRows: 2, maxRows: 5 }}
              placeholder="Model Description"
              onChange={(e) => this.onDescriptionChange(e)}
            />
          </Tooltip>
        </div>
      </>
    );
  }
}

ModelUpload.propTypes = {
  onChange: PropTypes.func,
  validated: PropTypes.func,
  model: PropTypes.shape({
    description: PropTypes.string,
    definition: PropTypes.string,
    type: PropTypes.string,
  }),
};
ModelUpload.defaultProps = {
  onChange: () => {},
  validated: () => {},
  model: { description: '', definition: '', type: '' },
};

export default ModelUpload;

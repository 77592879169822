import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Checkbox,
  Typography,
  Tooltip,
} from 'antd';
import {
  metricsInformation,
  metricsDetailsByType,
} from '../../../../util/MetricsDetails';
import MetricCarousel from './MetricCarousel';

const { Text } = Typography;

class MetricSelection extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedMetrics: props.metrics, redundantMetrics: false };
  }

  async componentDidMount() {
    this.validateForm();
  }

  onMetricsChange(selectedMetrics) {
    const { onChange } = this.props;
    onChange(selectedMetrics);
    this.setState({ selectedMetrics }, () => {
      onChange(selectedMetrics);
      this.validateForm();
    });
  }

  validateForm() {
    const hasRedundantMetrics = (metrics) => {
      const families = metrics.map((m) => m.family);
      return new Set(families).size !== families.length;
    };
    const { selectedMetrics } = this.state;
    const { validated } = this.props;
    if (hasRedundantMetrics(selectedMetrics)) {
      this.setState({ redundantMetrics: true });
      validated(false);
    } else {
      this.setState({ redundantMetrics: false });
      if (!selectedMetrics || selectedMetrics.length === 0) {
        validated(false);
      } else {
        validated(true);
      }
    }
  }

  render() {
    const { taskType } = this.props;
    const { redundantMetrics, selectedMetrics } = this.state;
    return (
      <>
        <Row>
          <Col span={6}>
            <Tooltip title="Select the Metrics. The cards on the right side provide more information about the available metrics.">
              <Text type="danger">* </Text>
              <Text>{`Available ${taskType} Metrics:`}</Text>
              <div>
                <Checkbox.Group
                  value={selectedMetrics}
                  onChange={(value) => this.onMetricsChange(value)}
                >
                  {metricsInformation.map((metric) => (
                    <div key={metric.type}>
                      <Checkbox
                        disabled={!metricsDetailsByType(taskType).includes(metric)}
                        value={metric}
                      >
                        {metric.description}
                      </Checkbox>
                      <br />
                    </div>
                  ))}
                </Checkbox.Group>
              </div>
            </Tooltip>
            {redundantMetrics
              ? (
                <Text type="danger">You&apos;ve selected redundant metrics. Please select only one from each Category (e.g. Accuracy, AUCROC)!</Text>
              )
              : ''}
          </Col>
          <Col span={18}>
            <MetricCarousel taskType={taskType} />
          </Col>
        </Row>
      </>
    );
  }
}

export default MetricSelection;

MetricSelection.propTypes = {
  onChange: PropTypes.func,
  validated: PropTypes.func,
  taskType: PropTypes.string.isRequired,
  metrics: PropTypes.arrayOf(PropTypes.shape({})),
};

MetricSelection.defaultProps = {
  onChange: () => {},
  validated: () => {},
  metrics: [],
};

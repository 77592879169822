import React from 'react';
import { Row, Col, Card } from 'antd';
import DataSchemaView from '../../components/DataSchemaTable/DataSchemaView';
import DataSchemaTable from '../../components/DataSchemaTable/DataSchemaTable';
import OverviewPageHeader from '../../components/PageHeader/OverviewPageHeader';
import DataSchemaToolbar from '../../components/DataSchemaToolbar/DataSchemaToolbar';
import DataSchemasContextProvider, { DataSchemasContext } from './DataSchemasContext';

export default function DataSchemas() {
  return (
    <DataSchemasContextProvider>
      <Row>
        <Col span={24}>
          <OverviewPageHeader title="Data Schemas" />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <DataSchemasContext.Consumer>
            {
              ({ changeSchema, state: { selectedSchema } }) => (
                <Row gutter={[16, 16]}>
                  <Col span={14}>
                    <Card>
                      <DataSchemaToolbar
                        selectedDataSchema={selectedSchema}
                        refresh={() => changeSchema(null)}
                      />
                    </Card>
                    <DataSchemaTable
                      rowSelection={
                      {
                        onChange: (selectedRowKey, selectedRow) => {
                          changeSchema(selectedRow[0]);
                        },
                        type: 'radio',
                        selectedRowKeys: selectedSchema
                          ? [selectedSchema.key]
                          : [],
                      }
                    }
                    />
                  </Col>
                  <Col span={10}>
                    <DataSchemaView selectedSchema={selectedSchema} />
                  </Col>
                </Row>
              )
            }
          </DataSchemasContext.Consumer>
        </Col>
      </Row>
    </DataSchemasContextProvider>
  );
}

import React from 'react';
import { Row, Col, Card } from 'antd';
import DataSplitsContextProvider, { DataSplitsContext } from './DataSplitsContext';
import DataSplitToolbar from '../../components/DataSplitToolbar/DataSplitToolbar';
import DataSplitTable from '../../components/DataSplitTable/DataSplitTable';
import OverviewPageHeader from '../../components/PageHeader/OverviewPageHeader';

export default function DataSchemas() {
  return (
    <DataSplitsContextProvider>
      <Row>
        <Col span={24}>
          <OverviewPageHeader title="Data Splits" />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <DataSplitsContext.Consumer>
            {
              ({ changeSplit, state: { selectedSplit } }) => (
                <div>
                  <Card>
                    <DataSplitToolbar
                      selectedDataSplit={selectedSplit}
                      refresh={() => changeSplit(null)}
                    />
                  </Card>
                  <DataSplitTable />
                </div>
              )
            }
          </DataSplitsContext.Consumer>
        </Col>
      </Row>
    </DataSplitsContextProvider>
  );
}

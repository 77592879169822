import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Button,
} from 'antd';
import {
  PlusCircleOutlined,
  QuestionCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import NewTaskModal from './NewTaskModal/NewTaskModal';
import TaskDetailsModal from './TaskDetailsModal/TaskDetailsModal';
import DeleteModal from './DeleteModal/DeleteModal';

class TaskToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newTaskModalVisible: false,
      taskDetailsModalVisible: false,
      deleteModalVisible: false,
    };
  }

  // New Task
  showNewTaskModal() {
    this.setState({
      newTaskModalVisible: true,
    });
  }

  handleNewTaskModalOk(e) {
    console.log(e);
    this.setState({
      newTaskModalVisible: false,
    });
  }

  handleNewTaskModalCancel(e) {
    console.log(e);
    this.setState({
      newTaskModalVisible: false,
    });
  }

  // Task details
  showTaskDetailsModal() {
    this.setState({
      taskDetailsModalVisible: true,
    });
  }

  handleTaskDetailsModalOk() {
    this.setState({
      taskDetailsModalVisible: false,
    });
  }

  handleTaskDetailsModalCancel() {
    this.setState({
      taskDetailsModalVisible: false,
    });
  }

  // Task delete
  showDeleteModal() {
    this.setState({
      deleteModalVisible: true,
    });
  }

  handleDeleteModalOk() {
    this.setState({
      deleteModalVisible: false,
    });
  }

  handleDeleteModalCancel() {
    this.setState({
      deleteModalVisible: false,
    });
  }

  render() {
    const { selectedTask, refresh } = this.props;
    let task = null;
    if (selectedTask) {
      task = selectedTask.key;
    }
    const { newTaskModalVisible, taskDetailsModalVisible, deleteModalVisible } = this.state;

    return (
      <Row gutter={[16, 16]}>
        <Col>
          <Button type="primary" onClick={() => this.showNewTaskModal()}>
            <PlusCircleOutlined />
            Create a new Task
          </Button>
        </Col>
        <Col>
          <Button disabled={!task} onClick={() => this.showTaskDetailsModal()}>
            <QuestionCircleOutlined />
            Details
          </Button>
        </Col>
        <Col>
          <Button disabled={!task} onClick={() => this.showDeleteModal()} danger>
            <DeleteOutlined />
            Delete
          </Button>
        </Col>
        <NewTaskModal
          visible={newTaskModalVisible}
          onOk={() => {
            this.handleNewTaskModalOk();
          }}
          refresh={() => refresh()}
          onCancel={(e) => this.handleNewTaskModalCancel(e)}
        />
        <TaskDetailsModal
          selectedTask={selectedTask}
          visible={taskDetailsModalVisible}
          onOk={() => {
            this.handleTaskDetailsModalOk();
          }}
          onCancel={(e) => this.handleTaskDetailsModalCancel(e)}
        />
        <DeleteModal
          selectedTask={selectedTask}
          visible={deleteModalVisible}
          onOk={() => {
            this.handleDeleteModalOk();
          }}
          refresh={() => refresh()}
          onCancel={(e) => this.handleDeleteModalCancel(e)}
        />
      </Row>
    );
  }
}

export default TaskToolbar;

TaskToolbar.propTypes = {
  selectedTask: PropTypes.shape({
    key: PropTypes.string,
    status: PropTypes.string,
  }),
  refresh: PropTypes.func,
};

TaskToolbar.defaultProps = {
  refresh: () => {},
  selectedTask: PropTypes.shape({
    key: null,
    status: null,
  }),
};

import React, { Component } from 'react';
import { PageHeader, Breadcrumb, Typography } from 'antd';
import PropTypes from 'prop-types';
import { getInfo } from '../../util/ApiCalls';

const { Title } = Typography;

class DetailPageHeader extends Component {
  constructor(props) {
    super(props);
    const { id } = props;
    this.state = {
      title: id,
    };
  }

  componentDidMount() {
    const { id } = this.props;
    const { title } = this.state;
    getInfo(id).then((data) => {
      if (data && data.name) {
        this.setState({ title: `${data.name} (${title})` });
      }
    });
  }

  render() {
    const { title } = this.state;
    return (
      <PageHeader
        className="site-page-header"
        ghost={false}
      >
        <Breadcrumb>
          <Breadcrumb.Item><a href="/">Overview</a></Breadcrumb.Item>
          <Breadcrumb.Item>Leaf Details</Breadcrumb.Item>
        </Breadcrumb>
        <Title level={4} padding={0}>
          {title}
        </Title>
      </PageHeader>
    );
  }
}

DetailPageHeader.propTypes = {
  id: PropTypes.node.isRequired,
};

export default DetailPageHeader;

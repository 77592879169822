import React, { useEffect, useState } from 'react';
import Highlight from 'react-highlight';
import PropTypes from 'prop-types';
import { Modal, Descriptions } from 'antd';
import { getModelsByTaskId, delTask } from '../../../util/ApiCalls';

export default function DeleteModal(props) {
  const {
    visible, onOk, onCancel, selectedTask, refresh,
  } = props;
  const [modelIds, setModelIds] = useState([]);

  useEffect(() => {
    const getDeleteDetails = async (taskId) => {
      let taskModelIds = {};
      try {
        taskModelIds = await getModelsByTaskId(taskId);
        setModelIds(taskModelIds);
      } catch (e) {
        console.log(e);
      }
    };

    if (selectedTask) {
      // eslint-disable-next-line
      getDeleteDetails(selectedTask._id);
    }
  }, [selectedTask]);

  return (
    <Modal
      title={`Are you sure to delete the Task ${selectedTask ? selectedTask.name : ''} - Task deletion`}
      visible={visible}
      onOk={async () => {
        try {
          await delTask(selectedTask.key);
          refresh();
          onOk();
        } catch (e) {
          console.log(e);
        }
      }}
      width={1040}
      onCancel={onCancel}
    >
      <Descriptions bordered column={1}>
        <Descriptions.Item label="Task Name">{selectedTask ? selectedTask.name : ''}</Descriptions.Item>
        <Descriptions.Item label="Task Description">{selectedTask ? selectedTask.description : ''}</Descriptions.Item>
        <Descriptions.Item label="Dataprocessor ID">{selectedTask ? <Highlight>{selectedTask.processor_definition_id}</Highlight> : ''}</Descriptions.Item>
        <Descriptions.Item label="Model ID">{selectedTask ? <Highlight>{selectedTask.model_definition_id}</Highlight> : ''}</Descriptions.Item>
        <Descriptions.Item label="Count of related Models which will be deleted">{modelIds ? <Highlight>{JSON.stringify(modelIds.length)}</Highlight> : ''}</Descriptions.Item>
      </Descriptions>
    </Modal>
  );
}

DeleteModal.propTypes = {
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  refresh: PropTypes.func.isRequired,
  selectedTask: PropTypes.shape({
    key: PropTypes.string,
    task: PropTypes.string,
    _id: PropTypes.string,
    description: PropTypes.string,
    name: PropTypes.string,
    processor_definition_id: PropTypes.string,
    data_schema_id: PropTypes.string,
    model_definition_id: PropTypes.string,
  }),
};

DeleteModal.defaultProps = {
  selectedTask: PropTypes.shape({
    key: null,
    task: '',
    _id: '',
    description: '',
    name: '',
    processor_definition_id: '',
    data_schema_id: '',
    model_definition_id: '',
  }),
};

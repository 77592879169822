import React from 'react';
import L from 'leaflet';
import { Marker, Popup } from 'react-leaflet';
import PropTypes from 'prop-types';

export default function LeafMarker(props) {
  const icon = L.icon({
    iconUrl: 'https://upload.wikimedia.org/wikipedia/commons/8/88/Map_marker.svg',
    iconSize: [32, 32],
  });
  const { name, position } = props;
  return (
    position
      ? (
        <Marker position={position} icon={icon}>
          <Popup>
            {name}
          </Popup>
        </Marker>
      )
      : <></>);
}

LeafMarker.propTypes = {
  name: PropTypes.string.isRequired,
  position: PropTypes.arrayOf(PropTypes.number).isRequired,
};

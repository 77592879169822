import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Tooltip,
  Radio,
} from 'antd';
import { metricsInformation } from '../../util/MetricsDetails';

export default function SelectPrimaryMetricItem(props) {
  const { selectedMetrics } = props;

  return (
    <Tooltip title="Select a primary metric. It will be displayed in the overview table.">
      <Form.Item
        name="primarymetric"
        label="Primary Metric"
        rules={[
          {
            required: true,
            message: 'Please select a primary metric!',
          },
        ]}
      >
        <Radio.Group>
          {
        selectedMetrics.map((metric) => (
          <div key={metric.type}>
            <Radio value={metric.type}>
              {metricsInformation.find((m) => m.type === metric.type).family}
            </Radio>
            <br />
          </div>
        ))
      }
        </Radio.Group>
      </Form.Item>
    </Tooltip>
  );
}

SelectPrimaryMetricItem.propTypes = {
  selectedMetrics: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    type: PropTypes.string,
  })).isRequired,
};

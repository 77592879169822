import React, { Component } from 'react';
import Map from './Map';
import LeafMarker from './LeafMarker/LeafMarker';
import { getAllInfo } from '../../util/ApiCalls';

class MultiLeafMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leaves: [],
    };
  }

  componentDidMount() {
    this.updateInfo();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.updateInfo();
    }
  }

  updateInfo() {
    getAllInfo()
      .then((data) => {
        this.setState({ leaves: data });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const { leaves } = this.state;
    return (
      <Map>
        { !Array.isArray(leaves)
          ? ''
          : leaves.map((leaf) => (
            <LeafMarker
              key={leaf.name}
              position={leaf.coordinates}
              name={leaf.name}
              center={[50, 10]}
            />
          ))}
      </Map>
    );
  }
}

export default MultiLeafMap;

import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveLine } from '@nivo/line';

const LineGraphSimple = ({
  data, xAxisName, yAxisName, maxYScale, height, position,
}) => (
  <div style={{
    height,
    position,
    width: '100%',
  }}
  >
    <ResponsiveLine
      data={data}
      colors="#13B3FD"
      margin={{
        top: 5,
        right: 50,
        bottom: 50,
        left: 50,
      }}
      xScale={{
        type: 'time',
        format: '%Y-%m-%dT%H:%M:%S.%L%Z',
      }}
      yScale={{
        type: 'linear', min: 0, max: maxYScale, stacked: false, reverse: false,
      }}
      yFormat=" >-.4f"
      enableArea
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: yAxisName,
        legendOffset: -40,
        legendPosition: 'middle',
      }}
      axisBottom={{
        format: '%H:%M',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 45,
        tickValues: 10,
        legend: xAxisName,
        legendOffset: 42,
        legendPosition: 'middle',
      }}
      enableGridY
      pointSize={5}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
    />
  </div>
);

export default LineGraphSimple;

LineGraphSimple.propTypes = {
  data: PropTypes.PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.date,
          y: PropTypes.number,
        }),
      ),
    }),
  ).isRequired,
  xAxisName: PropTypes.string,
  yAxisName: PropTypes.string,
  maxYScale: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  height: PropTypes.string,
  position: PropTypes.string,
};
LineGraphSimple.defaultProps = {
  xAxisName: 'time',
  yAxisName: null,
  maxYScale: 'auto',
  height: '200px',
  position: 'static',
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Form,
  message,
  Steps,
  Button,
} from 'antd';
import './NewRunModal.css';
import RunDetailsSelection from './NewRunModalSteps/RunDetailsSelection';
import RunTypeSelection from './NewRunModalSteps/RunTypeSelection/RunTypeSelection';
import { postRun } from '../../../util/ApiCalls';

const { Step } = Steps;

export default function NewRunModal(props) {
  const {
    visible,
    hideModal,
    refresh,
  } = props;
  const [form] = Form.useForm();
  const [selectedMetrics, setSelectedMetrics] = useState([]);
  const [selectedModelType, setSelectedModelType] = useState('');
  const [selectedTaskType, setSelectedTaskType] = useState('');
  const [selectedOptimizer, setSelectedOptimizer] = useState('');
  const [currentState, setCurrentState] = useState(0);
  const [runType, setRunType] = useState('fml');

  const onTaskSelection = (childData) => {
    if (selectedTaskType !== childData.type) {
      form.resetFields(['loss', 'primarymetric']);
      setSelectedTaskType(childData.type);
    }
    setSelectedModelType(form.getFieldValue('task')[0].model_type);
    setSelectedMetrics(childData.metrics);
  };

  const onOptimizerSelection = (value) => {
    setSelectedOptimizer(value);
  };

  const next = () => {
    const nextState = currentState + 1;
    setCurrentState(nextState);
  };

  const prev = () => {
    const prevState = currentState - 1;
    setCurrentState(prevState);
  };

  const onDone = async () => {
    await form.validateFields();
    const leaves = form.getFieldValue('leaves');
    if ((leaves === undefined || leaves.length < 2) && runType === 'fml') {
      alert('Please specify at least two Leaves to start a new federated run.');
      return;
    }
    hideModal();
    try {
      await postRun(
        runType,
        form.getFieldValue('iterations'),
        form.getFieldValue('epochs'),
        form.getFieldValue('optimizer'),
        form.getFieldValue('learningrate'),
        form.getFieldValue('l2normclip'),
        form.getFieldValue('noisemultiplier'),
        form.getFieldValue('microbatches'),
        form.getFieldValue('batchsize'),
        form.getFieldValue('datasplits')[0].key,
        form.getFieldValue('leaves').map((leaf) => leaf.id),
        form.getFieldValue('task')[0].task,
        form.getFieldValue('primarymetric'),
        form.getFieldValue('loss'),
        form.getFieldValue('name'),
        form.getFieldValue('task')[0].model_type,
      );
      refresh();
      message.success('New run started!');
    } catch (e) {
      if (e.message === 'MissingDataSequence') {
        message.warning('Select a split which is defined for all participating leafs!');
      } else {
        message.warning('Run creation failed.');
      }
      console.log(e);
    }
  };

  const getDetailStepTitle = () => {
    if (runType === 'fml') {
      return 'Federated Run Details';
    }
    if (runType === 'train') {
      return 'Isolated Run Details';
    }
    if (runType === 'stl') {
      return 'STL Run Details';
    }
    return null;
  };

  const steps = [
    {
      title: 'Run Type',
      content: (
        <RunTypeSelection
          setRunType={setRunType}
          runType={runType}
        />
      ),
    },
    {
      title: getDetailStepTitle(),
      content: (
        <RunDetailsSelection
          onTaskSelection={onTaskSelection}
          onOptimizerSelection={onOptimizerSelection}
          selectedMetrics={selectedMetrics}
          selectedModelType={selectedModelType}
          selectedTaskType={selectedTaskType}
          selectedOptimizer={selectedOptimizer}
          runType={runType}
          form={form}
        />
      ),
    },
  ];

  return (
    <Modal
      visible={visible}
      onCancel={hideModal}
      width={1040}
      footer={[
        currentState > 0 ? (
          <Button
            key="previous"
            style={{ margin: 8 }}
            onClick={() => prev()}
          >
            Previous
          </Button>
        ) : null,
        currentState < steps.length - 1 ? (
          <Button
            key="next"
            type="primary"
            onClick={() => next()}
          >
            Next
          </Button>
        ) : null,
        currentState === steps.length - 1 ? (
          <Button
            key="done"
            type="primary"
            onClick={() => onDone()}
          >
            Done
          </Button>
        ) : null,
      ]}
    >
      <Steps
        className="run-steps"
        current={currentState}
      >
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[currentState].content}</div>
    </Modal>
  );
}

NewRunModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveLine } from '@nivo/line';
import CSVDownloadButton from '../CSVDownloadButton/CSVDownloadButton';

// convert to format for csv Download
const getCSVData = (data) => {
  const csvData = [];
  data
    .filter((d) => d.id !== 'none')
    .forEach((element) => {
      csvData.push(...element.data.map((dataPoint) => ({
        x: dataPoint.x,
        y: dataPoint.y,
        id: element.id,
      })));
    });
  return csvData;
};

const getXTicksLength = (data) => {
  let counter = 0;
  if (Array.isArray(data) && data.length) {
    if ((data.map((graphInstance) => graphInstance.id)[data.length - 1]) === 'none') {
      data.forEach((graphInstance) => {
        counter += graphInstance.data.length;
      });
    } else if (Array.isArray(data) && data.length) {
      counter = data.reduce((graphInstance) => graphInstance).data.length;
    }
  }
  return counter;
};

const getLegendData = (data, colors) => {
  let legendData = data
    .filter((d) => d.id !== 'none')
    .map((d, index) => ({ id: d.id, label: d.id, color: colors[index % colors.length] }));

  const legendLength = legendData.length;
  if (legendLength > 30) {
    const firstPart = legendData.slice(0, 15);
    const secondPart = legendData.slice(legendLength - 15, legendLength);
    firstPart.push({
      id: 'further legend entries',
      label: '...',
      color: '#ffffff',
    });
    legendData = firstPart.concat(secondPart);
  }
  return legendData;
};

const styleById = {
  none: {
    strokeDasharray: '6, 6',
    strokeWidth: 1,
  },
  default: {
    strokeWidth: 2,
  },
};
const DashedLine = ({
  series, lineGenerator, xScale, yScale,
}) => series.map(({ id, data, color }) => (
  <path
    key={id}
    d={lineGenerator(
      data.map((d) => ({
        x: xScale(d.data.x),
        y: d.data.y !== null ? yScale(d.data.y) : null,
      })),
    )}
    fill="none"
    stroke={color}
    style={styleById[id] || styleById.default}
  />
));

const LineGraph = ({
  data, withSelector, xAxisName, yAxisName, height, position,
  legendAnchor, legendTranslateX, legendTranslateY, legends,
  colors, axisColor, layers, useMesh,
  yScale, enableGridX, axisRight, enableGridY,
  sliceTooltip, enableSlices,
}) => {
  let graphColors = [];
  if (withSelector) {
    for (let i = 0; i < data.length; i += 1) {
      graphColors.push(colors[i]);
    }
  } else graphColors = colors;

  return (
    <div style={{
      height,
      position,
      width: '100%',
      zIndex: 1,
    }}
    >
      <ResponsiveLine
        data={data}
        margin={{
          top: 50, right: 110, bottom: 70, left: 60,
        }}
        xScale={(getXTicksLength(data) > 10)
          ? { type: 'linear' }
          : { type: 'point' }}
        yScale={yScale || {
          type: 'linear', min: ((!Array.isArray(data) || !data.length) ? '0' : 'auto'), max: ((!Array.isArray(data) || !data.length) ? '1' : 'auto'), stacked: false, reverse: false,
        }}
        layers={layers}
        yFormat=" >-.4f"
        axisTop={null}
        axisRight={axisRight}
        axisBottom={xAxisName
          ? {
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            tickValues: 10,
            legend: xAxisName,
            legendOffset: 36,
            legendPosition: 'middle',
          }
          : null}
        axisLeft={yAxisName
          ? {
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: yAxisName,
            legendOffset: -53,
            legendPosition: 'middle',
          }
          : null}
        colors={graphColors}
        theme={{
          axis: {
            ticks: {
              line: {
                stroke: axisColor,
              },
              text: { fill: axisColor },
            },
            legend: {
              text: {
                fill: axisColor,
              },
            },
          },
        }}
        pointSize={5}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={useMesh}
        enableGridX={enableGridX}
        enableGridY={enableGridY}
        enableSlices={enableSlices}
        sliceTooltip={sliceTooltip}
        legends={legends || [
          {
            data: getLegendData(data, graphColors),
            anchor: legendAnchor,
            direction: 'column',
            justify: false,
            translateX: legendTranslateX,
            translateY: legendTranslateY,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 15,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
      {xAxisName && yAxisName
        ? (
          <CSVDownloadButton
            data={getCSVData(data)}
            filename={`${yAxisName}vs${xAxisName}.csv`}
            headers={[
              { label: 'id', key: 'id' },
              { label: xAxisName, key: 'x' },
              { label: yAxisName, key: 'y' },
            ]}
          />
        )
        : ''}
    </div>
  );
};

export { LineGraph, getXTicksLength };

LineGraph.propTypes = {
  data: PropTypes.PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.number,
        }),
      ),
    }),
  ).isRequired,
  enableSlices: PropTypes.string,
  enableGridX: PropTypes.bool,
  enableGridY: PropTypes.bool,
  useMesh: PropTypes.bool,
  sliceTooltip: PropTypes.func,
  layers: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  ),
  withSelector: PropTypes.bool,
  xAxisName: PropTypes.string,
  yAxisName: PropTypes.string,
  yScale: PropTypes.shape(),
  height: PropTypes.string,
  legendAnchor: PropTypes.string,
  legendTranslateX: PropTypes.number,
  legendTranslateY: PropTypes.number,
  colors: PropTypes.oneOfType(
    [
      PropTypes.shape({ scheme: PropTypes.string }),
      PropTypes.arrayOf(PropTypes.string),
    ],
  ),
  position: PropTypes.string,
  axisColor: PropTypes.string,
  legends: PropTypes.arrayOf(PropTypes.shape()),
  axisRight: PropTypes.shape(),
};
LineGraph.defaultProps = {
  sliceTooltip: undefined,
  enableSlices: undefined,
  enableGridX: true,
  enableGridY: true,
  useMesh: true,
  xAxisName: null,
  layers: ['grid', 'markers', 'axes', 'areas', 'crosshair', DashedLine, 'points', 'slices', 'mesh', 'legends'],
  withSelector: false,
  yScale: null,
  yAxisName: undefined,
  height: '560px',
  legendAnchor: 'right',
  legendTranslateX: 100,
  legendTranslateY: 0,
  axisColor: 'not specified',
  colors: { scheme: 'spectral' },
  position: 'static',
  legends: null,
  axisRight: null,
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Modal, message, Tooltip, Switch,
} from 'antd';
import SelectLeavesItem from '../../FormItems/SelectLeavesItem';
import SetNameItem from '../../FormItems/SetNameItem';
import { postDataSequence, postDataSplit } from '../../../util/ApiCalls';
import TagSlider from './TagSlider/TagSlider';

/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
export default function NewDataSplitModal(props) {
  const {
    visible, hideModal, refresh,
  } = props;
  const [validationSplit, setValidationSplit] = useState(false);
  const [form] = Form.useForm();

  const onValidationSplitSelection = (checked) => {
    setValidationSplit(!!checked);
  };

  const onSliderChange = (values) => {
    form.setFieldsValue({ splitParameters: values });
  };

  const onFinish = async () => {
    const leaves = form.getFieldValue('leaves');
    if (leaves === undefined || leaves.length < 1) {
      alert('Please specify at least one leaf to create a split.');
      return;
    }
    hideModal();
    const leafIds = form.getFieldValue('leaves').map((leaf) => leaf.id);
    const splitParams = (form.getFieldValue('splitParameters')).map((splitParam) => splitParam / 100);
    try {
      const response = [];
      response.push(await Promise.all(leafIds
        .map((leafId) => postDataSequence(leafId, splitParams, validationSplit))));
      const seqIds = response[0].map((element) => element._id);
      const dsplitname = form.getFieldValue('name');
      const dsplittype = 'Percentage';
      const datasplit = {
        name: dsplitname,
        type: dsplittype,
        dataSequences: seqIds,
      };
      await postDataSplit(datasplit);
      refresh();
      message.success('Data split created!');
    } catch (e) {
      message.warning(e.message);
    }
  };

  const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 30 },
  };
  return (
    <Modal
      title="Create a new Data Split"
      visible={visible}
      onOk={() => {
        form.submit();
      }}
      onCancel={hideModal}
      className="data-split-modal"
      width={700}
    >
      <Form
        form={form}
        {...layout}
        onFinish={onFinish}
      >
        <SetNameItem
          formName="Data Split"
        />
        <SelectLeavesItem
          form={form}
          title="Choose the leaves which should be selected for the split."
          ruleMessage="Please select at least one leaf!"
          newDataSplit
        />
        <Form.Item
          name="validationSplit"
          label="Validation Split"
        >
          <Tooltip title="If a validation set is selected, the validation data will not be used for federated machine learning.">
            <Switch disabled onChange={onValidationSplitSelection} />
          </Tooltip>
        </Form.Item>
        <Form.Item
          name="splitParameters"
          rules={[{
            validator(rule, value) {
              if (!value.includes(0)) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The value for a split must not be zero!'));
            },
          }]}
        >
          <TagSlider
            validationSplit={validationSplit}
            onChange={onSliderChange}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

NewDataSplitModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  refresh: PropTypes.func.isRequired,
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './RunTypeSelection.css';
import {
  Button,
} from 'antd';

export default function RunTypeSelection(props) {
  const {
    runType,
    setRunType,
  } = props;

  const [fmlClass, setFmlClass] = useState(runType === 'fml' ? 'selectedButton' : 'idleButton');
  const [stlClass, setStlClass] = useState(runType === 'stl' ? 'selectedButton' : 'idleButton');
  const [imlClass, setImlClass] = useState(runType === 'train' ? 'selectedButton' : 'idleButton');

  return (
    <div className="select-run-type-button-group">
      <Button
        className={fmlClass}
        onClick={() => {
          setFmlClass('selectedButton');
          setStlClass('idleButton');
          setImlClass('idleButton');
          setRunType('fml');
        }}
      >
        <span className="card-header">Federated ML Run</span>
        <br />
        <br />
        Train a model with federated machine
        <br />
        learning across multiple leaves.
      </Button>
      <Button
        className={stlClass}
        onClick={() => {
          setImlClass('idleButton');
          setStlClass('selectedButton');
          setFmlClass('idleButton');
          setRunType('stl');
        }}
      >
        <span className="card-header">STL ML Run</span>
        <br />
        <br />
        Train a model with sequential transfer
        <br />
        learning across multiple leaves.
      </Button>
      <Button
        className={imlClass}
        onClick={() => {
          setImlClass('selectedButton');
          setStlClass('idleButton');
          setFmlClass('idleButton');
          setRunType('train');
        }}
      >
        <span className="card-header">Isolated ML Run</span>
        <br />
        <br />
        Train one model on one or
        <br />
        multiple leaves individually.
      </Button>
    </div>
  );
}

RunTypeSelection.propTypes = {
  runType: PropTypes.string.isRequired,
  setRunType: PropTypes.func.isRequired,
};

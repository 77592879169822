import { CognitoAuth } from 'amazon-cognito-auth-js/dist/amazon-cognito-auth';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { config as AWSConfig } from 'aws-sdk';

/* eslint-disable no-underscore-dangle */
const appConfig = {
  region: window._env_.REACT_APP_COGNITO_REGION,
  userPool: window._env_.REACT_APP_COGNITO_USER_POOL,
  userPoolBaseUri: window._env_.REACT_APP_COGNITO_USER_POOL_BASE_URI,
  clientId: window._env_.REACT_APP_COGNITO_CLIENT_ID,
  callbackUri: window._env_.REACT_APP_COGNITO_CALLBACK_URI,
  signoutUri: window._env_.REACT_APP_COGNITO_SIGNOUT_URI,
  tokenScopes: [
    'openid',
    'email',
    'profile',
  ],
};
AWSConfig.region = appConfig.region;

// Creates a CognitoAuth instance
const createCognitoAuth = () => {
  const appWebDomain = appConfig.userPoolBaseUri.replace('https://', '').replace('http://', '');
  const auth = new CognitoAuth({
    UserPoolId: appConfig.userPool,
    ClientId: appConfig.clientId,
    AppWebDomain: appWebDomain,
    TokenScopesArray: appConfig.tokenScopes,
    RedirectUriSignIn: appConfig.callbackUri,
    RedirectUriSignOut: appConfig.signoutUri,
  });
  return auth;
};

// Creates a CognitoUserPool instance
const createCognitoUserPool = () => new CognitoUserPool({
  UserPoolId: appConfig.userPool,
  ClientId: appConfig.clientId,
});

// Creates a CognitoUser instance
const createCognitoUser = () => {
  const pool = createCognitoUserPool();
  return pool.getCurrentUser();
};

// Get the URI of the hosted sign in screen
const getCognitoSignInUri = () => {
  const signinUri = `${appConfig.userPoolBaseUri}/login?response_type=code&client_id=${appConfig.clientId}&redirect_uri=${appConfig.callbackUri}`;
  return signinUri;
};

// Parse the response from a Cognito callback URI (assumed a token or code is in the supplied href).
// Returns a promise.
const parseCognitoWebResponse = (href) => new Promise((resolve, reject) => {
  const auth = createCognitoAuth();

  // userHandler will trigger the promise
  auth.userhandler = {
    onSuccess(result) {
      resolve(result);
    },
    onFailure(err) {
      reject(new Error(`Failure parsing Cognito web response: ${err}`));
    },
  };
  auth.parseCognitoWebResponse(href);
});

// Gets a new Cognito session. Returns a promise.
const getCognitoSession = () => new Promise((resolve, reject) => {
  const cognitoUser = createCognitoUser();
  cognitoUser.getSession((err, result) => {
    if (err || !result) {
      reject(new Error(`Failure getting Cognito session: ${err}`));
      return;
    }

    // Resolve the promise with the session credentials
    const session = {
      credentials: {
        accessToken: result.accessToken.jwtToken,
        idToken: result.idToken.jwtToken,
        refreshToken: result.refreshToken.token,
      },
      user: {
        userName: result.idToken.payload['cognito:username'],
        email: result.idToken.payload.email,
      },
    };
    resolve(session);
  });
});

// Sign out of the current session (will redirect to signout URI)
const signOutCognitoSession = () => {
  const auth = createCognitoAuth();
  auth.signOut();
};

export default {
  createCognitoAuth,
  createCognitoUser,
  createCognitoUserPool,
  getCognitoSession,
  getCognitoSignInUri,
  parseCognitoWebResponse,
  signOutCognitoSession,
};

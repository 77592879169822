import React from 'react';
import { Row, Col, Card } from 'antd';
import RunToolbar from '../../components/RunToolbar/RunToolbar';
import RunTable from '../../components/RunTable/RunTable';
import RunDetailsFederated from '../../components/RunDetailsFederated/RunDetailsFederated';
import RunParticipantDetails from '../../components/RunParticipantDetails/RunParticipantDetails';
import OverviewPageHeader from '../../components/PageHeader/OverviewPageHeader';
import RunDetailsIsolated from '../../components/RunDetailsIsolated/RunDetailsIsolated';
import RunContextProvider, { RunContext } from './RunContext';

export default function RunOverview() {
  return (
    <RunContextProvider>
      <Row>
        <Col span={24}><OverviewPageHeader title="Federated Machine Learning Overview" /></Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card>
            <RunContext.Consumer>
              {({ changeRun, selectedRun, setCurrentRun }) => (
                <RunToolbar
                  selectedRun={selectedRun}
                  refresh={() => {
                    setCurrentRun('');
                    changeRun(null);
                  }}
                />
              )}
            </RunContext.Consumer>
          </Card>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <RunContext.Consumer>
                {({ changeRun, changeParticipant, selectedRun }) => (
                  <RunTable rowSelection={
                    {
                      onChange: (selectedRowKey, selectedRow) => {
                        changeRun(selectedRow[0]);
                        changeParticipant(null);
                      },
                      type: 'radio',
                      selectedRowKeys: selectedRun
                        ? [selectedRun.key]
                        : [],
                    }
                  }
                  />
                )}
              </RunContext.Consumer>
            </Col>
          </Row>
        </Col>
      </Row>

      <RunContext.Consumer>
        {({
          changeParticipant, selectedRun, selectedParticipant, performance,
        }) => {
          const isTrainHistoryMissing = performance.some(
            (onePerformance) => onePerformance.iterations.some(
              (iter) => Object.keys(iter.train_history).length === 0,
            ),
          );
          if (selectedRun && (selectedRun.run_type === 'fml' || selectedRun.run_type === 'stl')) {
            return (
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <RunDetailsFederated
                    rowSelection={
                    {
                      onChange: (selectedRowKey, selectedRow) => {
                        changeParticipant(selectedRow[0]);
                      },
                      type: 'radio',
                      selectedRowKeys: selectedParticipant
                        ? [selectedParticipant.key]
                        : [],
                    }
                  }
                    run={selectedRun ? selectedRun.run : null}
                    performance={performance}
                  />
                </Col>
                <Col span={12}>
                  <Card
                    style={{ height: '100%', maxWidth: '1200px' }}
                    title="Results"
                  >
                    {selectedParticipant ? (
                      <RunParticipantDetails
                        run={selectedRun.run}
                        participant={selectedParticipant.participant}
                        performance={performance}
                        differentialPrivacy={selectedRun.dp}
                        runType={selectedRun.run_type}
                        isTrainHistoryMissing={isTrainHistoryMissing}
                      />
                    ) : 'please select a leaf.'}
                  </Card>
                </Col>
              </Row>
            );
          }
          if (selectedRun && selectedRun.run_type === 'train') {
            const detailsIsolatedRun = (width, moreDetails) => (
              <Row gutter={[16, 16]}>
                <Col span={width}>
                  <RunDetailsIsolated
                    rowSelection={
                      {
                        onChange: (selectedRowKey, selectedRow) => {
                          changeParticipant(selectedRow[0]);
                        },
                        type: 'radio',
                        selectedRowKeys: selectedParticipant
                          ? [selectedParticipant.key]
                          : [],
                      }
                    }
                    run={selectedRun ? selectedRun.run : null}
                  />
                </Col>
                {moreDetails}
              </Row>
            );
            const detailsParticipantRun = (
              <Col span={12}>
                <Card
                  style={{ height: '100%', maxWidth: '1200px' }}
                  title="Results"
                >
                  {selectedParticipant ? (
                    <RunParticipantDetails
                      run={selectedRun.run}
                      participant={selectedParticipant.participant}
                      performance={performance}
                      differentialPrivacy={selectedRun.dp}
                      runType={selectedRun.run_type}
                      isTrainHistoryMissing={isTrainHistoryMissing}
                    />
                  ) : 'please select a leaf.'}
                </Card>
              </Col>
            );
            if (isTrainHistoryMissing) {
              return (detailsIsolatedRun(24, null));
            }
            return (detailsIsolatedRun(12, detailsParticipantRun));
          }
          return null;
        }}
      </RunContext.Consumer>
    </RunContextProvider>
  );
}

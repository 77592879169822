/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Popconfirm, Select, Tooltip, Card,
} from 'antd';
import {
  RocketOutlined,
  DeleteOutlined,
  ExperimentOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import Form from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import DataTable from '../DataTable';
import {
  getEvaluations,
  getDatasplitsForLeaf,
  deployJob,
  deleteModel,
  getInfo,
} from '../../../util/ApiCalls';
import ModelDetailsModal from './ModelDetailsModal';
import NewEvaluationJobModal from './NewEvaluationJobModal';
import { metricsInformation } from '../../../util/MetricsDetails';

const { Option } = Select;

function LeafModelTable({ leafId }) {
  const [selectedMetric, setSelectedMetric] = useState(metricsInformation[0].type);
  const [leafDataSplits, setLeafDataSplits] = useState([]);
  const [selectedDatasequence, setSelectedDatasequence] = useState(null);
  const [evaluations, setEvaluations] = useState([]);
  const [modelDetailsModalVisible, setModelDetailsModalVisible] = useState(false);
  const [newEvaluationJobModalVisible, setNewEvaluationJobModalVisible] = useState(false);
  const [selectedModelInfoDetails, setSelectedModelInfoDetails] = useState(null);
  const [selectedModelInfoEvaluation, setSelectedModelInfoEvaluation] = useState(null);

  const update = useCallback(async () => {
    const getScoreForMetricFromEvaluation = (evaluation) => {
      const round = (number) => Math.round((number + Number.EPSILON) * 10000) / 10000;
      if (evaluation.scores) {
        const scoreForMetric = evaluation.scores.find((score) => score.type === selectedMetric);
        if (scoreForMetric) {
          return round(scoreForMetric.value);
        }
      }
      return '-';
    };
    if (selectedDatasequence) {
      try {
        let evals = await getEvaluations(selectedDatasequence);
        const leafInfo = await getInfo(leafId);
        if (Array.isArray(evals)) {
          evals = evals
            .filter(
              (evaluation) => evaluation.metrics
                .map((metric) => metric.type)
                .includes(selectedMetric),
            )
            .sort((a, b) => (a._id < b._id ? 1 : -1))
            .map((evaluation) => (
              {
                // eslint-disable-next-line
                key: evaluation._id,
                name: `model_${evaluation.counter}`,
                task_name: evaluation.task_name,
                task_id: evaluation.task_id,
                model_description: evaluation.model_definition_description,
                score: getScoreForMetricFromEvaluation(evaluation),
                ...evaluation,
                deployed: leafInfo.deployed_model === evaluation._id,
              }
            ));
          setEvaluations(evals);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [selectedMetric, selectedDatasequence]);

  useEffect(() => {
    update();
  }, [leafId, selectedMetric, selectedDatasequence, update]);

  useEffect(() => {
    const updateDatasplits = async () => {
      try {
        const datasplits = await getDatasplitsForLeaf(leafId);
        setLeafDataSplits(datasplits);
        setSelectedDatasequence(datasplits[0].data_sequence_id);
      } catch (e) {
        console.log(e);
      }
    };
    updateDatasplits();
  }, [leafId]);

  const handleDeploy = async (record) => {
    try {
      await deployJob(record._id, record.task_id, leafId);
    } catch (e) {
      console.log(e);
    }
    update();
  };

  const handleDelete = async (record) => {
    try {
      await deleteModel(record._id);
    } catch (e) {
      console.log(e);
    }
    update();
  };

  const showModelDetailsModal = async (record) => {
    setSelectedModelInfoDetails({
      model_definition_id: record.model_definition_id,
      model_counter: record.counter,
    });
    setModelDetailsModalVisible(true);
  };

  const showNewEvaluationJobModal = async (record) => {
    setSelectedModelInfoEvaluation({
      model_id: record.key,
      task_id: record.task_id,
    });
    setNewEvaluationJobModalVisible(true);
  };

  const hideNewEvaluationJobModal = () => {
    setNewEvaluationJobModalVisible(false);
  };

  const columns = [
    {
      title: 'Model',
      dataIndex: 'name',
      key: 'model',
      render: (text, record) => (
        <Button type="link" onClick={() => showModelDetailsModal(record)}>{text}</Button>
      ),
    },
    {
      title: 'Task',
      dataIndex: 'task_name',
      key: 'task',
    },
    {
      title: 'Description',
      dataIndex: 'model_description',
      key: 'model_description',
    },
    {
      title: `Score (${selectedMetric})`,
      dataIndex: 'score',
      key: 'score',
    },
    {
      title: () => <Tooltip title="This will deploy the model instance. After a successful deployment, further calls to the predictor will be processed using this model.">Deploy</Tooltip>,
      dataIndex: 'operation',
      disableSearch: true,
      render: (text, record) => (
        !record.deployed ? (
          <Popconfirm title="Sure you want to deploy?" onConfirm={() => handleDeploy(record)}>
            <Button type="primary">
              <RocketOutlined />
            </Button>
          </Popconfirm>
        ) : (
          <Button type="primary" disabled>
            <RocketOutlined />
          </Button>
        )
      ),
    },
    {
      title: () => <Tooltip title="This will delete the model instance. Base models and other related models will still persist.">Delete</Tooltip>,
      dataIndex: 'operation',
      disableSearch: true,
      render: (text, record) => (
        <Popconfirm title="Sure you want to delete the model?" onConfirm={() => handleDelete(record)}>
          <Button danger>
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      ),
    },
    {
      title: () => <Tooltip title="Evaluate this model on another leaf or datasplit. The metrics associated with the model will be calculated.">Evaluate</Tooltip>,
      dataIndex: 'evaluate_model',
      disableSearch: true,
      key: 'evaluate_model',
      render: (text, record) => (
        <Button type="primary" disabled={record.score !== '-'} onClick={() => showNewEvaluationJobModal(record)}>
          <ExperimentOutlined />
        </Button>
      ),

    },
  ];
  return (
    <Card>
      <Form layout="horizontal">
        <FormItem label={(
          <span>
            Datasplit&nbsp;
            <Tooltip title="Select a Datasplit to compare the models on it.">
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        )}
        >
          <Select
            // style={{ width: 255 }}
            showSearch
            value={selectedDatasequence}
            onChange={(dataSequenceId) => setSelectedDatasequence(dataSequenceId)}
          >
            {leafDataSplits.map((dataSplit) => (
              <Option value={dataSplit.data_sequence_id} key={dataSplit.data_sequence_id}>
                {dataSplit.name}
              </Option>
            ))}
          </Select>
        </FormItem>
        <FormItem label={(
          <span>
            Metric&nbsp;
            <Tooltip title="Select the metric that is displayed as score below.">
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        )}
        >
          <Select
            // style={{ width: 255 }}
            showSearch
            value={selectedMetric}
            onChange={(value) => setSelectedMetric(value)}
          >
            {metricsInformation.map((metric) => (
              <Option value={metric.type} key={metric.type}>
                {metric.description}
              </Option>
            ))}
          </Select>
        </FormItem>
      </Form>
      <DataTable
        bordered
        dataSource={evaluations}
        columns={columns}
      />
      <ModelDetailsModal
        visible={modelDetailsModalVisible}
        onOk={() => setModelDetailsModalVisible(false)}
        onCancel={() => setModelDetailsModalVisible(false)}
        modelInfo={selectedModelInfoDetails}
      />
      <NewEvaluationJobModal
        modelInfo={selectedModelInfoEvaluation}
        visible={newEvaluationJobModalVisible}
        hideModal={() => hideNewEvaluationJobModal()}
      />
    </Card>
  );
}

export default LeafModelTable;

LeafModelTable.propTypes = {
  leafId: PropTypes.string.isRequired,
};

import React from 'react';
import { Tag } from 'antd';
import PropTypes from 'prop-types';

export default function StatusTag(props) {
  const { status } = props;
  let colorCode = '';
  if (status.toLowerCase() === 'online'
  || status.toLowerCase() === 'started'
  || status.toLowerCase() === 'done') {
    colorCode = 'success';
  } else if (status.toLowerCase() === 'scheduled') {
    colorCode = 'default';
  } else {
    colorCode = 'warning';
  }
  return (
    <Tag
      color={colorCode}
    >
      {status}
    </Tag>
  );
}

StatusTag.propTypes = {
  status: PropTypes.string.isRequired,
};

import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import DataTable from '../TableTemplates/DataTable';
import { getRuns } from '../../util/ApiCalls';
import RunStatus from './RunStatus';
import { RunContext } from '../../pages/RunOverview/RunContext';
/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

function RunTable({ rowSelection }) {
  const [runs, setRuns] = useState([]);
  const { currentRun, currentIteration } = useContext(RunContext);
  useEffect(() => {
    async function updateInfo() {
      const getTimeDifference = (a, b) => {
        const diffInMS = Date.parse(a) - Date.parse(b);
        if (Number.isNaN(diffInMS)) {
          return null;
        }
        const diffInS = diffInMS / 1000;
        return `${Math.floor(diffInS / 60)}min ${Math.floor(diffInS % 60)}s`;
      };
      try {
        let newRuns = await getRuns(['fml', 'train', 'stl']);
        newRuns = Array.isArray(newRuns)
          ? newRuns.map((run) => (
            {
              key: run._id,
              run: run._id,
              run_type: run.type,
              // eslint-disable-next-line no-nested-ternary
              type: run.type === 'fml' ? 'federated' : (run.type === 'stl' ? 'transferred' : 'isolated'),
              counter: `run_${run.counter}`,
              name: run.name,
              date: new Date(run.createdAt).toGMTString(),
              amountOfLeaves: run.participants.length,
              iterations: run.iterations === 0 ? '-' : run.iterations,
              dp: run.params && run.params.optimizer ? run.params.optimizer.type === 'dpadam' : '',
              dp_optimizer: run.params && run.params.optimizer && run.params.optimizer.type === 'dpadam' ? <CheckOutlined /> : '',
              time: run.status === 'running' ? <Spin size="small" /> : getTimeDifference(run.finishedAt, run.startedAt),
              status: run.status,
              createdAt: new Date(run.createdAt).toLocaleString('de-DE'),
              statusName: <RunStatus
                run={run._id}
                status={run.status}
                time={run.finishedAt}
                iterations={run.iterations}
              />,
            }
          ))
          : [];
        setRuns(newRuns);
      } catch (e) {
        console.log(e);
      }
    }
    updateInfo();
  }, [rowSelection, currentRun, currentIteration]);

  const columns = [
    {
      title: 'Run',
      dataIndex: 'counter',
      fixed: 'left',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      fixed: 'left',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      fixed: 'left',
    },
    {
      title: 'Creation Date',
      dataIndex: 'createdAt',
      sorter: {
        compare: (a, b) => new Date(a.date) - new Date(b.date),
        multiple: 1,
      },
      defaultSortOrder: 'descend',
    },
    {
      title: 'Participants',
      dataIndex: 'amountOfLeaves',
    },
    {
      title: 'Iterations',
      dataIndex: 'iterations',
    },
    {
      title: 'DP Optimizer',
      dataIndex: 'dp_optimizer',
    },
    {
      title: 'Time',
      dataIndex: 'time',
    },
    {
      title: 'Status',
      dataIndex: 'statusName',
      sorter: {
        compare: (a, b) => {
          let statusA = 0;
          if (a.status === 'running') statusA = 3;
          if (a.status === 'scheduled') statusA = 2;
          if (a.status === 'done') statusA = 1;
          if (a.status === 'failed') statusA = 0;

          let statusB = 0;
          if (b.status === 'running') statusB = 3;
          if (b.status === 'scheduled') statusB = 2;
          if (b.status === 'done') statusB = 1;
          if (b.status === 'failed') statusB = 0;

          if (statusA < statusB) return -1;
          if (statusA > statusB) return 1;
          return 0;
        },
        multiple: 2,
      },
      defaultSortOrder: 'descend',
    },
  ];

  return (
    <DataTable
      rowSelection={rowSelection}
      columns={columns}
      dataSource={runs}
    />
  );
}
export default RunTable;

RunTable.propTypes = {
  rowSelection: PropTypes.shape({
    type: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { LineGraph } from '../../LineGraph/LineGraph';
import { schemeYellowRed } from '../../../util/ColorSchemes';
import { getRun } from '../../../util/ApiCalls';
import { lossDetails } from '../../../util/LossDetails';

function LossGraph(props) {
  const { performance, run } = props;
  const [lossType, setLossType] = useState('loss');
  useEffect(() => {
    const getRunDetails = async (runId) => {
      let newRunDetails = {};
      try {
        newRunDetails = await getRun(runId);
        const expandedLoss = lossDetails(newRunDetails.params.loss.type);
        setLossType(expandedLoss.description);
      } catch (e) {
        console.log(e);
      }
    };
    if (run) {
      getRunDetails(run);
    }
  }, [run]);

  let graphData = [];
  if (performance && 'iterations' in performance && performance.iterations.length !== 0) {
    const continuation = [];
    const epochs = performance.iterations[0].train_history.loss.length;
    performance.iterations.forEach((iteration, iterationIndex) => {
      const losses = iteration.train_history.loss
        .map((l, lossIndex) => ({ x: epochs * iterationIndex + lossIndex, y: l }));
      graphData.push({ id: `Iteration: ${iterationIndex}`, data: losses });
      continuation.push(losses[0]);
      if (losses.length > 1) {
        continuation.push({ x: losses[1].x, y: null });
      }
      continuation.push(losses[losses.length - 1]);
    });
    graphData = [...graphData, { id: 'none', data: continuation }];
  }

  return (
    <div style={{ height: '400px' }}>
      <LineGraph
        data={graphData}
        xAxisName="Epoch"
        yAxisName={lossType}
        colors={schemeYellowRed}
      />
    </div>
  );
}

export default LossGraph;

LossGraph.propTypes = {
  performance: PropTypes.shape(
    {
      iterations: PropTypes.arrayOf(PropTypes.shape),
    },
  ),
  run: PropTypes.string.isRequired,
};
LossGraph.defaultProps = {
  performance: null,
};

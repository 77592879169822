import React from 'react';
import PropTypes from 'prop-types';
import { green } from '@ant-design/colors';
import DataTable from '../TableTemplates/DataTable';
/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

function RunDetailsFederated({ rowSelection, performance, run }) {
  let performances = [];
  const round = (number) => Math.round((number + Number.EPSILON) * 10000) / 10000;
  if (run) {
    performances = performance
      .sort((a, b) => (a.leaf_id < b.leaf_id ? -1 : 1));
  }
  const adaptedPerformancesData = performances.map((data) => ({
    key: data.key,
    leaf_id: data.leaf_id ? data.leaf_id : '-',
    iteration: data.best_iteration && data.best_iteration.iteration ? data.best_iteration.iteration : '-',
    primary_metric_value: data.best_iteration && data.best_iteration.primary_metric_value ? data.best_iteration.primary_metric_value : '-',
    improvement: data.improvement ? round(data.improvement) : '-',
    participant: data.participant,
  }));
  const columns = [
    {
      title: 'Leaf',
      dataIndex: 'leaf_id',
      fixed: 'left',
    },
    {
      title: 'Best Iteration',
      dataIndex: 'iteration',
      sorter: (a, b) => a.iteration - b.iteration,
      render: (entry) => (!Number.isNaN(Number(entry))
        ? <div style={{ color: green.primary }}>{entry}</div>
        : entry),
    },
    {
      title: `Evaluation (${performances.length > 0 ? performances[0].primary_metric : '-'})`,
      dataIndex: 'primary_metric_value',
      sorter: (a, b) => a.primary_metric_value - b.primary_metric_value,
    },
    {
      title: 'Improvement',
      dataIndex: 'improvement',
      sorter: (a, b) => a.improvement - b.improvement,
      render: (improvement) => (!Number.isNaN(Number(improvement))
        ? <div style={{ color: green.primary }}>{improvement}</div>
        : improvement),
    },
  ];
  return (
    <DataTable
      rowSelection={rowSelection}
      columns={columns}
      dataSource={adaptedPerformancesData}
    />
  );
}

export default RunDetailsFederated;

RunDetailsFederated.propTypes = {
  rowSelection: PropTypes.shape({
    onChange: PropTypes.func,
    type: PropTypes.string,
    selectedRowKeys: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  performance: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  run: PropTypes.string,
};
RunDetailsFederated.defaultProps = {
  run: null,
};

import React, { useEffect, useState } from 'react';
import Highlight from 'react-highlight';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
import { Modal, Descriptions, List } from 'antd';
import {
  getRun,
  getTask,
  getModelDefinition,
  getProcessor,
  getDataSplit,
} from '../../../util/ApiCalls';

export default function RunDetailsModal(props) {
  const {
    visible, hideModal, selectedRun,
  } = props;
  const [runDetails, setRunDetails] = useState('');
  useEffect(() => {
    const getRunDetails = async (runId) => {
      let newRunDetails = {};
      try {
        newRunDetails = await getRun(runId);
        const newRunTask = await getTask(newRunDetails.task_id);
        const [newRunModel, newRunDataprocessor] = await Promise.all(
          [
            getModelDefinition(newRunTask.model_definition_id),
            getProcessor(newRunTask.processor_definition_id),
          ],
        );
        let dataSplitName;
        if (newRunDetails.data_split_id) {
          const dataSplit = await getDataSplit(newRunDetails.data_split_id);
          dataSplitName = dataSplit.name;
        } else {
          dataSplitName = '';
        }

        newRunDetails = {
          ...newRunDetails,
          ...newRunModel,
          ...newRunDataprocessor,
          dataSplitName,
          taskName: newRunTask.name,
          taskDescription: newRunTask.description,
          taskType: newRunTask.type,
        };
        setRunDetails(newRunDetails);
      } catch (e) {
        console.log(e);
      }
    };
    if (selectedRun) {
      getRunDetails(selectedRun.run);
    }
  }, [selectedRun]);
  return (
    <Modal
      title={`Run ${selectedRun ? selectedRun.name : ''} - Details`}
      visible={visible}
      onOk={() => hideModal()}
      onCancel={hideModal}
      width={1040}
    >
      <Descriptions bordered column={1}>
        <Descriptions.Item label="Participants">
          <List>
            { Array.isArray(runDetails.participants)
              ? runDetails.participants
                .map((participant) => <List.Item key={participant}>{participant}</List.Item>)
              : ''}
          </List>
        </Descriptions.Item>
        <Descriptions.Item label="Iterations">{runDetails.iterations}</Descriptions.Item>
        <Descriptions.Item label="Parameters">
          {runDetails.params ? <ReactJson src={runDetails.params} /> : ''}
        </Descriptions.Item>
        <Descriptions.Item label="Task Name">
          {runDetails.taskName || ''}
        </Descriptions.Item>
        <Descriptions.Item label="Task Description">
          {runDetails.taskDescription || ''}
        </Descriptions.Item>
        <Descriptions.Item label="Task Type">
          {runDetails.taskType || ''}
        </Descriptions.Item>
        <Descriptions.Item label="Model Definition">
          <Highlight className="python">
            {runDetails.definition || ''}
          </Highlight>
        </Descriptions.Item>
        <Descriptions.Item label="Dataprocessor Script">
          <Highlight className="python">
            {runDetails.script || ''}
          </Highlight>
        </Descriptions.Item>
        <Descriptions.Item label="Data Split">
          {runDetails.dataSplitName || ''}
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
}

RunDetailsModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  selectedRun: PropTypes.shape({
    run: PropTypes.string,
    name: PropTypes.string,
  }),
};

RunDetailsModal.defaultProps = {
  selectedRun: PropTypes.shape({
    run: null,
    name: null,
  }),
};

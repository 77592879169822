import React from 'react';
import { PageHeader, Typography } from 'antd';
import PropTypes from 'prop-types';

export default function OverviewPageHeader(props) {
  const { Title } = Typography;
  const { title } = props;
  return (
    <PageHeader
      className="site-page-header"
      ghost={false}
    >
      <Title level={4} padding={0}>
        {title}
      </Title>
    </PageHeader>
  );
}

OverviewPageHeader.propTypes = {
  title: PropTypes.node.isRequired,
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DataTable from '../TableTemplates/DataTable';
import { metricsInformation } from '../../util/MetricsDetails';
import { subscribeToFMLUpdates, requestFMLUpdate } from '../../util/SocketUpdates';

function RunDetailsIsolated({ rowSelection, run }) {
  const [performances, setPerformances] = useState([]);
  const [cols, setCols] = useState([{
    title: 'Leaf',
    dataIndex: 'leaf_id',
    fixed: 'left',
  }]);

  const round = (number) => Math.round(number * 10000) / 10000;

  function expandPerformances(performance) {
    try {
      Object.entries(performance.iterations[0].evaluation).forEach(([key, value]) => {
        performance[key] = round(value);
      });
      return {
        ...performance,
      };
    } catch (e) {
      console.log(e);
    }
    return performance;
  }

  useEffect(() => {
    const setColumns = (runDetails) => {
      try {
        const columns = [{
          title: 'Leaf',
          dataIndex: 'leaf_id',
          fixed: 'left',
        }];
        const appendColumns = Object.keys(runDetails[0].iterations[0].evaluation);
        appendColumns.forEach((col) => {
          const newColumn = {
            title: metricsInformation.find((m) => m.type === col).family,
            dataIndex: `${col}`,
            render: (entry) => (entry || '-'),
          };
          columns.push(newColumn);
          setCols(columns);
        });
      } catch (e) {
        console.log(e);
      }
    };
    subscribeToFMLUpdates(({ run: runFromData, data }) => {
      if (runFromData === run) {
        const newPerformances = data
          .sort((a, b) => (a.leaf_id < b.leaf_id ? -1 : 1));
        const newExpPerformances = newPerformances.map(expandPerformances);
        setColumns(newExpPerformances);
        setPerformances(newExpPerformances);
      }
    });
    if (run) {
      requestFMLUpdate(run);
    }
  }, [run]);

  return (
    <DataTable
      rowSelection={rowSelection}
      columns={cols}
      dataSource={performances}
    />
  );
}

export default RunDetailsIsolated;

RunDetailsIsolated.propTypes = {
  rowSelection: PropTypes.shape({
    onChange: PropTypes.func,
    type: PropTypes.string,
    selectedRowKeys: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  run: PropTypes.string,
};
RunDetailsIsolated.defaultProps = {
  run: null,
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';

export const MLTasksContext = React.createContext();

class MLTasksContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTask: null,
    };
  }

  render() {
    const { children } = this.props;
    const { selectedTask } = this.state;
    return (
      <MLTasksContext.Provider
        value={
          {
            state: { selectedTask },
            changeTask: (task) => {
              this.setState({ selectedTask: task });
            },
          }
        }
      >
        {children}
      </MLTasksContext.Provider>
    );
  }
}

export default MLTasksContextProvider;

MLTasksContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
